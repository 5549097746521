import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { useLayout } from "hooks";
import EditorDecs from "pages/admin/dashboard/component/EditorDecs";
import EditorUpdateDecs from "pages/admin/dashboard/component/EditorUpdateDecs";
import EditorUpdateDecsKo from "pages/admin/dashboard/component/EditorUpdateDecsKo";
import EditorUpdateDecsVi from "pages/admin/dashboard/component/EditorUpdateDecsVi";
import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormAddBlog, FormUpdateBlog } from "types";
import { BUTTON_TYPE } from "utils";

interface IModalBlog {
	item?: any;
	action?: string;
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading?: boolean;
}

const ModalUpdateBlog = ({
	onClose,
	onConfirm,
	isLoading,
	item,
	action,
}: IModalBlog) => {
	const [image, setImage] = React.useState("");
	const [errors, setErrors] = React.useState({});
	const { openModalUpdateBlog, setOpenModalUpdateBlog } = useLayout();
	const editorRef = React.useRef<any>(null);

	const [formData, setFormData] = React.useState<FormUpdateBlog>({
		id: "",
		title: "",
		decsEn: "",
		decsKo: "",
		decsVi: "",
		summary: ""
	});

	const handleSubmit = React.useCallback(async () => {
		console.log("formData -update", formData);
		onConfirm(formData);
	}, [formData.title, formData.thumbnail, formData.decsEn, formData.decsKo, formData.decsVi, formData.summary]);

	React.useEffect(() => {
		setFormData({
			id: "",
			title: "",
			decsEn: "",
			decsKo: "",
			decsVi: "",
			summary: ""
		});
	}, [openModalUpdateBlog]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const f = event.target.files?.[0];
		if (f) {
			setFormData({ ...formData, thumbnail: f });
			const url = URL.createObjectURL(f);
			setImage(url);
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	React.useEffect(() => {
		if (item) {
			setImage(item.thumbnail);
			setFormData({
				id: item?.id,
				title: item.title,
				decsEn: item.decsEn,
				decsKo: item.decsKo,
				decsVi: item.decsVi,
				summary: item.summary,
				thumbnail: item.thumbnail,
			});
		}
	}, [item, openModalUpdateBlog]);
	console.log("formData===edit", formData);
	return (
		<>
			{openModalUpdateBlog && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalUpdateBlog(false);
							setErrors({});
						}}>
						<div className="md:w-[1500px] sm:w-full  h-[900px] overflow-y-auto  text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Edit new blogs
							</h3>
							<form>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Title
									</label>
									<div className="w-full">
										<input
											disabled={isLoading}
											id="userName"
											value={formData.title}
											onChange={(e) =>
												setFormData({ ...formData, title: e.target.value })
											}
											type="text"
											placeholder="Title"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Thumbnail
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="credits"
											className="w-[50%] rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.thumbnail && (
											<LazyLoadImage
												src={image}
												className="h-[100px] w-[100px]"
											/>
										)}
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										English Description
									</label>
									<div className="w-[100%] h-[800px] flex items-center gap-2">
										<EditorUpdateDecs
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Korean Description
									</label>
									<div className="w-[100%] h-[800px] flex items-center gap-2">
										<EditorUpdateDecsKo
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Vietnamese Description
									</label>
									<div className="w-[100%] h-[800px] flex items-center gap-2">
										<EditorUpdateDecsVi
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default ModalUpdateBlog;
