import { Icons } from "assets";
import React from "react";

interface ISearch {
	value: string;
	onChange: (e: any) => void;
	placeholder: string;
	[key: string]: any;
}

const Search = ({ value, onChange, placeholder, ...props }: ISearch) => {
	return (
		<div className="relative max-w-md">
			<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
				{Icons.Search()}
			</div>
			<input
				title={placeholder}
				type="search"
				value={value}
				onChange={onChange}
				className="outline-none block w-full h-[42px] p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-primary truncate"
				placeholder={placeholder}
				{...props}
			/>
		</div>
	);
};

export default Search;
