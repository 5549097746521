import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { useLayout } from "hooks";
import * as React from "react";
import { FormUser } from "types";
import { BUTTON_TYPE, userSchema } from "utils";

interface IModalUser {
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading: boolean;
}

const modalUser = ({ onClose, onConfirm, isLoading }: IModalUser) => {
	const { openModalUser, setOpenModalUser } = useLayout();
	const [formData, setFormData] = React.useState<FormUser>({
		name: "",
		email: "",
		password: "",
	});
	const [errors, setErrors] = React.useState({});
	const [showPassword, setShowPassword] = React.useState<boolean>(true);

	const handleSubmit = React.useCallback(async () => {
		try {
			await userSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	}, [formData.email, formData.name, formData.password]);

	React.useEffect(() => {
		setFormData({
			name: "",
			email: "",
			password: "",
		});
	}, [openModalUser]);

	return (
		<>
			{openModalUser && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalUser(false);
							setErrors({});
						}}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Create new user
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="User name"
									type="text"
									formData={formData}
									placeholder="User name"
									name="name"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Email"
									type="text"
									formData={formData}
									placeholder="Enter email"
									name="email"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="relative">
									<Input
										disabled={isLoading}
										label="Password"
										type={showPassword ? "password" : "text"}
										formData={formData}
										placeholder="Enter password"
										name="password"
										setFormData={setFormData}
										errors={errors}
										setErrors={setErrors}
									/>
									<button
										onClick={(e: any) => {
											e.preventDefault();
											setShowPassword(!showPassword);
										}}
										type="button"
										className="absolute right-3 top-[71%] translate-y-[-50%]">
										{showPassword ? Icons.Eye() : Icons.EyeHide()}
									</button>
								</div>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalUser;
