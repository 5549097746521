import { Icons } from "assets";
import { Button } from "components";
import { useSetting } from "hooks/useSetting";
import React, { useEffect, useState } from "react";
import { ESettingKeys, ETypeSearch, FormSettings, TParamsSearch } from "types";
import { BUTTON_TYPE } from "utils";
import { formatDateTime } from "utils/helpers/time_helpers";

const SettingNoti = () => {
	const { onGetSetting, editSettingMutation } = useSetting();
	const params: TParamsSearch = {
		page: 1,
		limit: 10,
		search: ESettingKeys.NOTIFICATION,
		type: ETypeSearch.KEY,
	};
	const [formData, setFormData] = useState<FormSettings>({
		settingId: "",
		key: "",
		value: {
			maintan: {
				isMaintan: "",
				startTime: "",
				endTime: "",
			},
		},
	});

	const { settingData, refetch, isLoading } = onGetSetting({
		...params,
	});

	const isButtonDisabled = Object.values(formData.value).some(
		(field) => field === "",
	);

	const isLoadingEdit = editSettingMutation.isPending;

	useEffect(() => {
		settingData &&
			setFormData({
				settingId: settingData[0].id,
				key: settingData[0].key,
				value: settingData[0].value,
			});
	}, [settingData]);

	const handleSubmit = () => {
		editSettingMutation.mutate(formData);
	};

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Notification Setting</h1>
			{isLoading ? (
				<p>Loading...</p>
			) : (
				<>
					{settingData && settingData.length > 0 ? (
						<div className="w-full md:max-w-2xl">
							<div className="mb-4">
								<label className="mb-2 block font-bold text-black">
									Is maintain
								</label>
								<select
									disabled={editSettingMutation.isPending}
									className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none  focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
									value={formData.value.maintan.isMaintan}
									onChange={(e: any) => {
										setFormData({
											...formData,
											value: {
												...formData.value,
												maintan: {
													...formData.value.maintan,
													isMaintan: e.target.value,
												},
											},
										});
									}}>
									<option value="" disabled>
										Select
									</option>
									<option value="true">Yes</option>
									<option value="false">No</option>
								</select>
							</div>
							<div className="flex items-center gap-2">
								<div className="mb-4 w-full ">
									<label className="mb-2 block font-bold text-black">
										Start time
									</label>
									<input
										disabled={editSettingMutation.isPending}
										className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none  focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										type="datetime-local"
										value={
											formatDateTime(formData.value.maintan.startTime) || ""
										}
										onChange={(e: any) => {
											setFormData({
												...formData,
												value: {
													...formData.value,
													maintan: {
														...formData.value.maintan,
														startTime: e.target.value,
													},
												},
											});
										}}
									/>
								</div>
								<div className="mb-4 w-full">
									<label className="mb-2 block font-bold text-black">
										End time
									</label>
									<input
										disabled={editSettingMutation.isPending}
										className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none  focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										type="datetime-local"
										value={formatDateTime(formData.value.maintan.endTime) || ""}
										onChange={(e: any) => {
											setFormData({
												...formData,
												value: {
													...formData.value,
													maintan: {
														...formData.value.maintan,
														endTime: e.target.value,
													},
												},
											});
										}}
									/>
								</div>
							</div>
							{/* {settingData &&
								Object.entries(settingData[0].value).map(([key, value]) => (
									<div key={key}>
										<div className="md:w-1/3">
											<h3 className="font-bold text-xl mb-4">{key}</h3>
										</div>
										<div>
											{formData.value.maintan &&
												Object.entries(formData.value.maintan).map(([k, v]) => (
													<div key={k} className="md:flex items-center mb-6 ">
														<div className="md:w-1/3">
															<label className="block font-bold md:text-left mb-1 md:mb-0 pr-4">
																{k}
															</label>
														</div>
														<div className="group md:w-2/3 relative">
															<input
																className="border focus:outline-none rounded w-full py-2 px-4 focus:border-primary"
																value={formData.value.maintan[k]}
																onChange={(e: any) =>
																	setFormData({
																		...formData,
																		value: {
																			maintan: {
																				...formData.value.maintan,
																				[k]: e.target.value,
																			},
																		},
																	})
																}
															/>
														</div>
													</div>
												))}
										</div>
									</div>
								))} */}
							<div className="mb-4 flex justify-end gap-1">
								<div>
									<Button
										variant={BUTTON_TYPE.OUTLINED}
										disabled={isLoadingEdit}
										onClick={() => {
											refetch;
										}}
										className="w-[100px]">
										Cancel
									</Button>
								</div>
								<div>
									<Button
										disabled={isLoadingEdit || isButtonDisabled}
										onClick={handleSubmit}
										className="w-[100px] flex items-center gap-2">
										Confirm {isLoadingEdit && <span>{Icons.Spinner()}</span>}
									</Button>
								</div>
							</div>
						</div>
					) : (
						<p>No data found</p>
					)}
				</>
			)}
		</React.Fragment>
	);
};

export default SettingNoti;
