import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Swicher } from "components/Swicher";
import { useLayout } from "hooks";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormUpdateUser } from "types";
import { BUTTON_TYPE, FORM_ACTION } from "utils";

interface IModalPlan {
	item: any;
	onClose: () => void;
	action: string;
	onConfirm: (formData: FormUpdateUser) => void;
	isLoading: boolean;
}

const modalUpdateUser = ({
	item,
	onClose,
	action,
	onConfirm,
	isLoading,
}: IModalPlan) => {
	const { openModalUpdateUser, setOpenModalUpdateUser } = useLayout();
	const [image, setImage] = useState("");
	const [formUser, setFormUser] = useState<FormUpdateUser>({
		uid: "",
		userName: "",
		email: "",
		isActive: false,
		isAgree: false,
	});

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const f = event.target.files?.[0];
		if (f) {
			setFormUser({ ...formUser, avatar: f });
			const url = URL.createObjectURL(f);
			setImage(url);
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	useEffect(() => {
		if (item) {
			setImage(item.avatar);
			setFormUser({
				uid: item.id,
				userName: item.userName,
				email: item.email,
				avatar: item.avatar,
				isActive: item.isActive,
				isAgree: item.isAgree,
			});
		}
	}, [item, openModalUpdateUser]);

	return (
		<>
			{openModalUpdateUser && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalUpdateUser(false)}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Update User
							</h3>
							<form>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										User name
									</label>
									<div className="w-full">
										<input
											disabled={isLoading}
											id="userName"
											value={formUser.userName}
											onChange={(e) =>
												setFormUser({ ...formUser, userName: e.target.value })
											}
											type="text"
											placeholder="User name"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Email
									</label>
									<div className="w-full">
										<input
											disabled={true}
											id="email"
											value={formUser.email}
											onChange={(e) =>
												setFormUser({ ...formUser, email: e.target.value })
											}
											type="text"
											autoComplete="username"
											placeholder="Enter Email"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Avatar
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="credits"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formUser.avatar && (
											<LazyLoadImage
												src={image}
												className="h-[50px] w-[50px]"
											/>
										)}
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Active status
									</label>
									<div className="w-full flex items-center gap-2">
										<span className="font-thin text-[11px]">False </span>
										<Swicher
											active={formUser.isActive}
											onChange={() =>
												setFormUser({
													...formUser,
													isActive: !formUser.isActive,
												})
											}
										/>
										<span className="font-thin text-[11px]"> True</span>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-medium text-black dark:text-white">
										Agree status
									</label>
									<div className="w-full flex items-center gap-2">
										<span className="font-thin text-[11px]">False </span>
										<Swicher
											active={formUser.isAgree}
											onChange={() =>
												setFormUser({
													...formUser,
													isAgree: !formUser.isAgree,
												})
											}
										/>
										<span className="font-thin text-[11px]"> True</span>
									</div>
								</div>
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={onClose}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={action === FORM_ACTION.DETAIL || isLoading}
											onClick={() => onConfirm(formUser)}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalUpdateUser;
