/** @format */

import { Icons } from "assets";
import {
	EClotheSize,
	EPricingPlan,
	ETypeCategory,
	ETypeModel,
	ETypeNotiOption,
	ETypeOfUse,
	ETypeOutfit,
	ETypeSearch,
	ETypeServiceName,
	ETypePlan,
	EHistory,
} from "types";

export const drawerWidth = 240;

export const dimension = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export const KEY_CONTEXT = {
	AUTH_TOKEN: "authToken",
	REFRESH_TOKEN: "refreshToken",
	LANGUAGE: "language",
	THEME_MODE: "themeMode",
	USER: "user",
	UID: "uid",
	DEVICE_ID: "deviceId",
	SC_CONNECTION_TOKEN: "connectionToken",
	USER_ID: "userId",
	DEFAULT_URL: "defaultUrl",
};

export const EmpLangSeclect = [
	{
		key: "EN",
		title: "English",
		value: "EN",
		useI18n: true,
	},
	{
		key: "VN",
		title: "Vietnamese",
		value: "VN",
		useI18n: true,
	},
];

export const defaultPagination = {
	total: 0,
	page: 0,
	pageSize: 25,
};

export const ACTIVE_TAB = {
	LIVE_CHAT: "live_chat",
	INTERPRETER: "interpreter",
	PLAY_GAME: "play_game",
};

export const STEP_GAME = {
	INTRO: "intro",
	QUESTION: "question",
	SHOW_IMAGE: "show_image",
	ANSWERING: "answering",
	ANSWER_CORRECT: "answer_correct",
	ANSWER_INCORRECT: "answer_incorrect",
};

export const DEFAULT_LANG = {
	ko: "Korean",
	en: "English",
};

export enum EnumActionApproval {
	comment = "comment",
	approve = "approve",
	return = "return",
	reject = "reject",
	publish = "publish",
	archive = "archive",
	invite = "invite",
	delegate = "delegate",
	attach = "attach",
	print = "print",
	docversion = "docversion",
	history = "history",
	showmore = "showmore",
	lookup = "lookup",
}

export enum EnumBtnBottomModal {
	cancel = "cancel",
	approve = "approve",
	return = "return",
	reject = "reject",
	send = "send",
	save = "save",
	save_and_new = "save_and_new",
	ok = "ok",
	close = "close",
	/* Advance Search  */
	reset = "reset",
	save_as_profile = "save_as_profile",
	search = "search",
	downloadtemp = "downloadtemp",
	add = "add",
	update = "update",
	insert_update = "insert_update",
}

export enum EnumInputVariant {
	base = "base",
	browser = "browser",
	number = "number",
	select = "select",
}

export enum EnumValueDataType {
	String = "String",
	Number = "Number",
	Boolean = "Boolean",
	Object = "Object",
	Array = "Array",
	Null = "Null",
	Undefined = "Undefined",
	Function = "Function",
}

export enum EnumRangeApply {
	A = "A",
	S = "S",
}

export enum EnumYesNoBPMN {
	Y = "Yes",
	N = "No",
}

export enum EnumYesNo {
	Y = "Y",
	N = "N",
}

export enum EnumActionFilterBars {
	import = "import",
	export = "export",
	refresh = "refresh",
}

export const HEADER = {
	H_MOBILE: 64,
	H_DESKTOP: 80,
	H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
	WIDTH: 280,
};
export const EXPECT_LANG = ["ko", "en"];

export const QueryKeys = {
	UPDATE_BLOG: "update-blog",
	BLOG: "blog",
	LIST_SURVEY: "list-survey",
	STATIC_FEATURE_OF_USE: "static-feature-of-use",
	STATIC_ONLINE: "static-online",
	LIST_STATIC_FEATURE: "list-static-feature",
	LIST_STATIC_PLAN: "list-static-plan",
	LIST_STATIC_USER: "list-static-user",
	STATIC_GENERATION: "static-generation",
	STATIC_PLAN_USER: "static-plan-user",
	STATIC_REFERRAL_USER: "static-referral-user",
	STATIC_USER: "static-user",
	STATIC_REFERENCE: "static-reference",
	USER: "user",
	PLAN: "plan",
	GALLERY: "gallery",
	CONTACT_US: "contact-us",
	NOTIFICATION: "notification",
	TRANSACTION_PLAN: "transaction-plan",
	TRANSACTION_CREDIT: "transaction-credit",
	REFERRAL: "referral",
	REFERENCE: "reference",
	CATEGORY: "category",
	CLOTHES: "clothes",
	HAIR_STYLE: "hair-style",
	GEN_ME: "gen-me",
	MODEL_BODY: "model-body",
	ANIMATION_ANYONE: "animation-anyone",
	HISTORY: "history",
	SETTING: "setting",
	EXPORT_USER: "export-user",
	EXPORT_PLAN: "export-plan",
	EXPORT_GALLERY: "export-gallery",
	EXPORT_REFERRAL: "export-referral",
	EXPORT_REFERRENCE: "export-reference",
	EXPORT_HISTORY: "export-history",
	EXPORT_CONTACT_US: "export-contact-us",
	EXPORT_TRANSACTION_PLAN: "export-transaction-plan",
	EXPORT_TRANSACTION_CREDIT: "export-transaction-credit",
	EXPORT_CREDIT: "export-credit",
	EXPORT_GEN_ME: "export-gen-me",
	EXPORT_HAIR_STYLE: "export-hair-style",
	EXPORT_MODEL_BODY: "export-model-body",
	EXPORT_ANIMATION_ANYONE: "export-animation-anyone",
};

export const DATA_BUTTON = [
	{ value: 1, name: EPricingPlan.MONTHLY },
	{ value: 12, name: EPricingPlan.ANNUAL },
];

export enum BUTTON_TYPE {
	OUTLINED = "outlined",
	CONTAINER = "container",
}

export enum EAnimationVideoType {
	video = "video",
	videoShow = "videoShow",
}

export const initialOptionsPaypal = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};

// export const createTemplateSteps = ["Avatar", "Voice", "Info"];

export const stepTemplate = [
	{
		step: 0,
		label: "Avatar",
	},
	{
		step: 1,
		label: "Voice",
	},
	{
		step: 2,
		label: "Info",
	},
];

export enum EStepTemplate {
	avatar = 0,
	voice = 1,
	info = 2,
}

export enum ETemplateType {
	PREMADE = "PREMADE",
	CLONED = "CLONED",
}

export enum MODAL_PROPS {
	BACKDROP_CLICK = "backdropClick",
}

export enum FORM_ACTION {
	CREATE = "create",
	DETAIL = "detail",
	EDIT = "edit",
	DELETE = "delete",
}

export enum EUSER_TYPE {
	USER = "USER",
	ADMIN = "ADMIN",
	ALL = "ALL",
}

export const DATA_USER_TYPE = [
	{ value: EUSER_TYPE.USER, name: "USER" },
	{ value: EUSER_TYPE.ADMIN, name: "ADMIN" },
	{ value: EUSER_TYPE.ALL, name: "ALL" },
];

//TABLE
export const tableStaticFeatureHeader = [
	{ value: "email", name: "Email", sort: false },
	{ value: "userName", name: "Name", sort: true },
	{ value: "plan", name: "Plan", sort: false },
	{ value: "isVerify", name: "IsVerify Email", sort: false },
	{ value: "createdAt", name: "Created date", sort: false },
	{ value: "serviceName", name: "Service Name", sort: false },
	{ value: "status", name: "Status", sort: false },
];
export const tableStaticPlanHeader = [
	{ value: "email", name: "Email", sort: false },
	{ value: "userName", name: "Name", sort: true },
	{ value: "plan", name: "Plan", sort: false },
	{ value: "isVerify", name: "IsVerify Email", sort: false },
	{ value: "createdAt", name: "Created date", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "status", name: "Status", sort: false },
	{ value: "styleFit", name: "Style Fit", sort: false },
	{ value: "styleHair", name: "Style Hair", sort: false },
	{ value: "genMe", name: "Gen Me", sort: false },
	{ value: "animation", name: "Animation", sort: false },
	{ value: "bodyShape", name: "Body Shape", sort: false },
];

export const tableStaticUserHeader = [
	{ value: "email", name: "Email", sort: false },
	{ value: "userName", name: "Name", sort: true },
	{ value: "plan", name: "Plan", sort: false },
	{ value: "isVerify", name: "IsVerify Email", sort: false },
	{ value: "createdAt", name: "Created date", sort: false },
	{ value: "isActive", name: "IsActive", sort: false },
	{ value: "checkout", name: "Checkout", sort: false },
	{ value: "cancelPlan", name: "Cancel Plan", sort: false },
	{ value: "styleFit", name: "Style Fit", sort: false },
	{ value: "styleHair", name: "Style Hair", sort: false },
	{ value: "genMe", name: "Gen Me", sort: false },
	{ value: "animation", name: "Animation", sort: false },
	{ value: "bodyShape", name: "Body Shape", sort: false },
];

export const tableSurveyHeader = [
	{ value: "email", name: "Email", sort: false },
	{ value: "name", name: "Name", sort: true },
	{ value: "type", name: "Type", sort: false },
	{ value: "serviceName", name: "Feature", sort: false },
	{ value: "createdAt", name: "Created date", sort: false },
];

export const tableBlogsHeader = [
	{ value: "Action", name: "Action", sort: false },
	{ value: "title", name: "Title", sort: false },
	{ value: "thumbnail", name: "Thumbnail", sort: false },
	{ value: "createdAt", name: "CreatedAt", sort: false },
];

export const tableUserHeader = [
	// { value: "id", name: "Id", sort: true },
	{ value: "Action", name: "Action", sort: false },
	{ value: "email", name: "Email", sort: false },
	{ value: "userName", name: "Name", sort: true },
	{ value: "avatar", name: "Avatar", sort: false },
	// { value: "role", name: "Role", sort: false },
	{ value: "credits", name: "Credits", sort: false },
	{ value: "plan", name: "Plan", sort: false },
	// { value: "startDate", name: "Start date", sort: true },
	// { value: "expiredDate", name: "Expired date", sort: true },
	// { value: "totalBill", name: "Total bill", sort: false },
	{ value: "typeLogin", name: "Type login", sort: false },
	// { value: "isAgree", name: "Is agree?", sort: false },
	{ value: "isActive", name: "Is Verify Email", sort: false },
	// { value: "isActiveReferral", name: "Is active referral", sort: false },
	{ value: "daysRemaining", name: "Days remaining", sort: true },
	{ value: "styleFit", name: "Style Fit", sort: false },
	{ value: "styleHair", name: "Style Hair", sort: false },
	{ value: "genMe", name: "Gen Me", sort: false },
	{ value: "animation", name: "Animation", sort: false },
	{ value: "bodyShape", name: "Body Shape", sort: false },
];

export const tablePlanHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "packageName", name: "Package name", sort: true },
	{ value: "subTitle", name: "Sub title", sort: false },
	{ value: "description", name: "Description", sort: false },
	{ value: "price", name: "Price", sort: true },
	{ value: "priceSale", name: "Price sale", sort: false },
	{ value: "sortType", name: "Sort type", sort: true },
	{ value: "unit", name: "Unit", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableGalleryHeader = [
	{ value: "id", name: "ID", sort: true },
	{ value: "email", name: "Email", sort: true },
	{ value: "name", name: "Name", sort: true },
	{ value: "image", name: "Image", sort: false },
	{ value: "video", name: "Video", sort: false },
	{ value: "serviceName", name: "Service name", sort: false },
	{ value: "modelId", name: "Model ID", sort: false },
	{ value: "galleryModel", name: "Gallery model", sort: false },
	{ value: "serviceId", name: "Service ID", sort: false },
	{ value: "status", name: "Status", sort: false },
	{ value: "imageWaterMask", name: "Image water mask", sort: false },
	{ value: "categoryId", name: "Category ID", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableContactUsHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "firstName", name: "First name", sort: true },
	{ value: "lastName", name: "Last name", sort: true },
	{ value: "company", name: "Company", sort: true },
	{ value: "website", name: "Website", sort: false },
	{ value: "email", name: "Email", sort: false },
	{ value: "yourRole", name: "Role", sort: false },
	{ value: "message", name: "Message", sort: false },
	{ value: "phone", name: "Phone", sort: false },
	{ value: "describe", name: "Describe", sort: false },
	{ value: "options", name: "Options", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableTransactionPlanHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "email", name: "Email", sort: false },
	{ value: "payments", name: "Payments", sort: true },
	{ value: "plan", name: "Plan", sort: true },
	{ value: "totalBill", name: "Total bill", sort: true },
	{ value: "credit", name: "Credit", sort: true },
	{ value: "grossAmount", name: "Gross amount", sort: true },
	{ value: "paymentFee", name: "Payment fee", sort: true },
	{ value: "netAmount", name: "Net amount", sort: true },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableTransactionCreditHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "email", name: "Email", sort: false },
	{ value: "typeCharge", name: "Type charge", sort: true },
	{ value: "totalRedisUsed", name: "Total redis used", sort: true },
	{ value: "totalRedisCurrent", name: "Total redis current", sort: true },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableReffralHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "uid", name: "UID", sort: true },
	{ value: "email", name: "Email", sort: false },
	{ value: "code", name: "Code", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "active", name: "Active", sort: false },
	{ value: "checkRemainCode", name: "Check remain code", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableReferenceHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "email", name: "Email", sort: false },
	{ value: "code", name: "Code", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "active", name: "Active", sort: false },
	{ value: "refSrc", name: "Reference source", sort: false },
	{ value: "linkShare", name: "Link share", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableReferenceDashboardHeader = [
	{ value: "name", name: "Name", sort: true },
	{ value: "email", name: "Email", sort: true },
	{ value: "referrerEmail", name: "Reference Email", sort: false },
	{ value: "createdAt", name: "Start date", sort: true },
];

export const tableNotificationHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "title", name: "Title", sort: false },
	{ value: "content", name: "Content", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "image", name: "Image", sort: false },
	{ value: "hourToAdd", name: "Hours to add", sort: false },
	{ value: "isMaintain", name: "Is maintain?", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableCategoryHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "image", name: "Image", sort: false },
	{ value: "describe", name: "Describe", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableClothesHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: false },
	{ value: "typeModel", name: "Type Model", sort: false },
	{ value: "typeModel", name: "Type Model", sort: false },
	{ value: "typeOutfit", name: "Type Outfit", sort: false },
	{ value: "image", name: "Image", sort: false },
	// { value: "merchantId", name: "Merchant ID", sort: false },
	// { value: "categoryId", name: "Category ID", sort: false },
	// { value: "size", name: "Size", sort: false },
	{ value: "link", name: "Link", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableHairStyleHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: false },
	{ value: "image", name: "Image", sort: false },
	{ value: "uid", name: "UID", sort: false },
	{ value: "imageWaterMask", name: "Image water mask", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableGenMeHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: false },
	{ value: "image", name: "Image", sort: false },
	{ value: "uid", name: "UID", sort: false },
	{ value: "imageWaterMask", name: "Image water mask", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableModelBodyHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: true },
	{ value: "image", name: "Image", sort: false },
	{ value: "typeModel", name: "Type model", sort: false },
	{ value: "uid", name: "UID", sort: false },
	{ value: "bodyShapeId", name: "Body shape id", sort: false },
	{ value: "weight", name: "Weight", sort: false },
	{ value: "height", name: "Height", sort: false },
	{ value: "bmi", name: "BMI", sort: false },
	{ value: "imageWaterMask", name: "Image water mask", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "imgProducts", name: "Image products", sort: false },
	{ value: "chestSize", name: "Chest size", sort: false },
	{ value: "hipSize", name: "Hip size", sort: false },
	{ value: "waistSize", name: "Waist size", sort: false },
	{ value: "size", name: "Size", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableAnimationAnyoneHeader = [
	{ value: "id", name: "Id", sort: true },
	{ value: "name", name: "Name", sort: false },
	{ value: "video", name: "Video", sort: false },
	{ value: "videoShow", name: "Video result", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableHistory = [
	{ value: "email", name: "Email", sort: false },
	{ value: "type", name: "Type", sort: false },
	{ value: "credit", name: "Credit Number", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];

export const tableSettingHeader = [
	{ value: "key", name: "Name", sort: false },
	{ value: "value", name: "Value", sort: false },
	{ value: "createdAt", name: "Created date", sort: true },
	{ value: "updatedAt", name: "Updated date", sort: true },
];
//////////////// END TABLE //////////////////

//////////////// SEARCH OPTIONS //////////////////
export const searchUserOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.EMAIL, name: "Email" },
	{ value: ETypeSearch.NAME, name: "User name" },
	{ value: ETypeSearch.PLAN, name: "Plan" },
	{ value: ETypeSearch.DURATION, name: "Duration" },
];

export const searchPlanOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.PACKAGE_NAME, name: "Package name" },
];

export const searchGalleryOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
	{ value: ETypeSearch.STATUS, name: "Status" },
	{ value: ETypeSearch.SERVICE_NAME, name: "Service name" },
];

export const searchReffralOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.TYPE, name: "Type" },
];

export const searchTransactionPlanOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.EMAIL, name: "Email" },
	{ value: ETypeSearch.TYPE_OF_USE, name: "Type of use" },
];

export const searchTransactionCreditOption = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.EMAIL, name: "Email" },
	{ value: ETypeSearch.TYPE_CHARGE, name: "Type charge" },
];

export const searchClotheOptions = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
	{ value: ETypeSearch.TYPE_MODEL, name: "Type Model" },
	{ value: ETypeSearch.TYPE_OUTFIT, name: "Type Outfit" },
];

export const searchHairStyleOptions = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
];

export const searchGenMeOptions = [
	{ value: ETypeSearch._id, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
	{ value: ETypeSearch.UID, name: "UID" },
];

export const searchModelBodyOptions = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
	{ value: ETypeSearch.TYPE_MODEL, name: "Type Model" },
];

export const searchAnimationAnyoneOptions = [
	{ value: ETypeSearch.ID, name: "ID" },
	{ value: ETypeSearch.NAME, name: "Name" },
];
//////////////// END SEARCH OPTIONS //////////////////

//////////////// SELECT OPTIONS //////////////////
export const historyTypeOption = [
	{ value: "", name: "Select a history type", disabled: true },
	{ value: EHistory.ADD_CREDIT, name: "Add credit" },
];

export const typePlanOptions = [
	{ value: "", name: "Select a plan type", disabled: true },
	{ value: "", name: "All" },
	{ value: ETypePlan.FREE, name: "Free" },
	{ value: ETypePlan.PRO, name: "Pro" },
	{ value: ETypePlan.PREMIUM, name: "Premium" },
	{ value: ETypePlan.ENTERPRISE, name: "Enterprise" },
];

export const durationOptions = [
	// { value: "", name: "Select date", disabled: true },
	{ value: 0, name: "Today" },
	{ value: 1, name: "Yesterday" },
	{ value: 7, name: "7 days ago" },
	{ value: 15, name: "15 days ago" },
	{ value: 30, name: "30 days ago" },
	{ value: 60, name: "60 days ago" },
	{ value: 90, name: "3 months ago" },
	{ value: 180, name: "6 months ago" },
	{ value: 365, name: "One Year ago" },
	{ value: "custom", name: "Custom date", icon: Icons.ArrowRight() },
];

export const TypeSurveyOptions = [
	{ value: "", name: "All" },
	{ value: "FEATURE", name: "Feature" },
	{ value: "OVERVIEW", name: "Overview" },
];

export const FeatureSurveyOptions = [
	{ value: "", name: "All" },
	{ value: ETypeServiceName.OUTFIT, name: "StyleFit" },
	{ value: ETypeServiceName.GEN_ME, name: "Gen me" },
	{ value: ETypeServiceName.BODY_SHAPE, name: "Body shape" },
	{ value: ETypeServiceName.ANIMATION_ANYONE, name: "Animation anyone" },
	{ value: ETypeServiceName.HAIR_SWAP, name: "Style Hair" },
];

export const planOptions = [
	{ value: "FREE", name: "Free" },
	{ value: "ENTRY", name: "Entry" },
	{ value: "PRO", name: "Pro" },
	{ value: "PREMIUM", name: "Premium" },
	{ value: "ENTERPRISE", name: "Enterprise" },
];

export const typeOfUseOption = [
	// { value: "", name: "Select Type of use", disabled: true },
	// { value: ETypeOfUse.FREE, name: "Free" },
	{ value: "MONTHLY", name: "Monthly" },
	{ value: "YEARLY", name: "Yearly" },
];

export const statusPlanOption = [
	{ value: "ACTIVE", name: "Active" },
	{ value: "INACTIVE", name: "Inactive" },
	{ value: "CANCEL_PLAN", name: "Cancel Plan" },
	// { value: "PENDING", name: "Pending" },
];

export const typeChargeOption = [
	{ value: "", name: "Select Type charge", disabled: true },
	{ value: ETypeServiceName.GEN_ME, name: "Gen me" },
	{ value: ETypeServiceName.OUTFIT, name: "Out fit" },
	{ value: ETypeServiceName.HAIR_SWAP, name: "Hair swap" },
	{ value: ETypeServiceName.BODY_SHAPE, name: "Body shape" },
	{ value: ETypeServiceName.ANIMATION_ANYONE, name: "Animation anyone" },
];

export const serviceNameOption = [
	{ value: "", name: "Select Service name", disabled: true },
	{ value: ETypeServiceName.GEN_ME, name: "Gen me" },
	{ value: ETypeServiceName.OUTFIT, name: "Out fit" },
	{ value: ETypeServiceName.HAIR_SWAP, name: "Hair swap" },
	{ value: ETypeServiceName.BODY_SHAPE, name: "Body shape" },
	{ value: ETypeServiceName.ANIMATION_ANYONE, name: "Animation anyone" },
];

//////////////// END SEARCH OPTIONS //////////////////

//////////////// SELECT OPTIONS //////////////////
export const notiTypeOption = [
	{ value: ETypeNotiOption.MAINTAIN, name: ETypeNotiOption.MAINTAIN },
	{ value: "UPDATE", name: "UPDATE" },
];

export const categoryTypeOption = [
	{ value: ETypeCategory.HAIR_COLOR, name: "Hair color" },
];

export const clothesTypeModelOption = [
	{ value: "", name: "Select gender", disabled: true },
	{ value: "", name: "Select gender", disabled: true },
	{ value: ETypeModel.FEMALE, name: "Female" },
	{ value: ETypeModel.MALE, name: "Male" },
];

export const clothesTypeOutfitOption = [
	{ value: "", name: "Select type of outfit", disabled: true },
	{ value: "", name: "Select type of outfit", disabled: true },
	{ value: ETypeOutfit.UPPER, name: "Upper" },
	{ value: ETypeOutfit.LOWER, name: "Lower" },
	{ value: ETypeOutfit.DRESSES, name: "Dresses" },
	{ value: ETypeOutfit.DRESSES, name: "Dresses" },
];

export const modelBodyTypeModelOption = [
	{ value: "", name: "Select type model", disabled: true },
	{ value: ETypeModel.FEMALE, name: "Female" },
	{ value: ETypeModel.MALE, name: "Male" },
	{ value: ETypeModel.OUTFIT, name: "Outfit" },
];

//////////////// END SELECT OPTIONS //////////////////

//////////////// CLOTHES //////////////////
export const sizeField = [
	{ value: EClotheSize.WIDTH, name: "Width" },
	{ value: EClotheSize.HEIGHT, name: "Height" },
	{ value: EClotheSize.WEIGHT, name: "Weight" },
	{ value: EClotheSize.CHEST_SIZE, name: "Chest size" },
	{ value: EClotheSize.WAIST_SIZE, name: "Waist size" },
	{ value: EClotheSize.HIP_SIZE, name: "Hip size" },
];

export const clotheSize = [
	{
		value: "Field",
		name: "",
		feild: [
			"Width(cm)",
			"Height(cm)",
			"Weight(kg)",
			"Chest Size(cm)",
			"Waist Size(cm)",
			"Hip Size(cm)",
		],
	},
	{ value: "XS", name: "XS", feild: sizeField },
	{ value: "S", name: "S", feild: sizeField },
	{ value: "M", name: "M", feild: sizeField },
	{ value: "L", name: "L", feild: sizeField },
	{ value: "XL", name: "XL", feild: sizeField },
	{ value: "XXL", name: "XXL", feild: sizeField },
];

export const initialClotheSize = [
	{
		size: "XS",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
	{
		size: "S",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
	{
		size: "M",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
	{
		size: "L",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
	{
		size: "XL",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
	{
		size: "XXL",
		width: 0,
		height: 0,
		weight: 0,
		chestSize: 0,
		waistSize: 0,
		hipSize: 0,
	},
];

export type TClotheSize = (typeof initialClotheSize)[number];

export const GET_URL_STORAGE =
	"https://storage.googleapis.com/illuminus-public/";

/////////////// END CLOTHES /////////////////
////////////////// TABS //////////////////
