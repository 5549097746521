import React from "react";

interface ProcessBarProps {
	data?: any;
	title?: string;
	unit?: string;
}

const ProcessBarObject: React.FC<ProcessBarProps> = ({ data, title, unit }) => {
	console.log("🚀 ~ data:", data);
	const item: any = data;
	return (
		<div className="w-[29%] h-auto rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 ">
			{/* <div className=" flex justify-between items-center mb-5">
				<p className="font-semibold text-[#FFFFFF] py-3">
					{" "}
					{title ?? "Total users"}{" "}
				</p>
				<p className="font-semibold text-[#FFFFFF] py-3">
					{item?.totalItems} {unit ?? "users"}
				</p>
			</div> */}

			{/* {data?.map((item: any, index: number) => {
				if (item?.title !== EChartUser.TOTAL_USER) */}

			<div className="my-7">
				<div className=" flex justify-between items-center">
					<div className="mb-1 text-base font-medium dark:text-white">
						{item?.title}
					</div>
					<p className="font-semibold text-[#FFFFFF] py-3">
						{item?.totalItems} {unit ?? "users"}
					</p>
				</div>
				<div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
					<div
						className="bg-gradient-to-r from-[#6DDBFE] to-[#FE55F4] h-2.5 rounded-full "
						style={{
							width: `${(item?.totalItems / item?.totalItems) * 100}%`,
						}}></div>
				</div>
			</div>

			{/* })} */}
		</div>
	);
};

export default ProcessBarObject;
