import React, { useState } from "react";
import FeatureOfUse from "./FeatureOfUse";
import Online from "./Online";
import Survey from "./Survey";

const Dashboard: React.FC = () => {
	const dataMenu = [
		{ label: "User Online", component: <Online /> },
		{ label: "Feature of use", component: <FeatureOfUse /> },
		{ label: "Survey", component: <Survey /> },
	];
	const [page, setPage] = useState(dataMenu[0]);

	const handleSelect = (index: number) => {
		setPage(dataMenu[index]);
	};

	return (
		<React.Fragment>
			<div className="flex w-full justify-between items-center mb-4">
				<div className=" flex ">
					{dataMenu?.map((item, index) => (
						<div
							key={index}
							className="text-[16px] flex flex-col cursor-pointer items-center"
							onClick={() => {
								handleSelect(index);
							}}>
							<p className="px-4 mb-2">{item?.label}</p>
							{page?.label === item?.label ? (
								<div className=" w-[75%] h-[2px] bg-gradient-to-r from-[#6DDBFE] to-[#FE55F4]"></div>
							) : (
								""
							)}
						</div>
					))}
				</div>
			</div>

			<div>{page.component}</div>
		</React.Fragment>
	);
};

export default Dashboard;
