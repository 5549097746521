import React, { useEffect, useState } from "react";
import { Icons } from "assets";
import { Button, Input } from "components";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import {
	BUTTON_TYPE,
	EAnimationVideoType,
	FORM_ACTION,
	animationAnyoneSchema,
} from "utils";
import { FormAnimationAnyone } from "types";

interface IModalAnimationAnyone {
	item: any;
	action: string;
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading?: boolean;
}

const storeUrl = "https://storage.googleapis.com/illuminus-public/";

const ModalAnimationAnyone = ({
	onClose,
	onConfirm,
	item,
	action,
	isLoading,
}: IModalAnimationAnyone) => {
	const { openModalAnimationAnyone, setOpenModalAnimationAnyone } = useLayout();
	const [video, setVideo] = useState("");
	const [videoShow, setVideoShow] = useState("");
	const [formData, setFormData] = useState<FormAnimationAnyone>({
		videoId: "",
		name: "",
		videoShow: null,
		video: null,
	});

	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (action === FORM_ACTION.EDIT && item) {
			setVideo(`${storeUrl}${item.video}`);
			setVideoShow(`${storeUrl}${item.videoShow}`);

			setFormData({
				videoId: item.id,
				name: item.name,
				videoShow: item.videoShow,
				video: item.video,
			});
		} else {
			setFormData({
				videoId: "",
				name: "",
				videoShow: null,
				video: null,
			});
		}
	}, [item, openModalAnimationAnyone]);

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: string,
	) => {
		const f = event.target.files?.[0];
		if (f) {
			const url = URL.createObjectURL(f);
			if (type === "video") {
				setFormData({ ...formData, video: f });
				setVideo(url);
			}
			if (type === "videoShow") {
				setFormData({ ...formData, videoShow: f });
				setVideoShow(url);
			}
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	const handleSubmit = async () => {
		try {
			await animationAnyoneSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<React.Fragment>
			{openModalAnimationAnyone && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							if (isLoading) return;
							setOpenModalAnimationAnyone(false);
							setErrors({});
						}}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Animation anyone generation"
									: "Edit Animation anyone generation"}
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Name"
									type="text"
									formData={formData}
									placeholder="Enter name"
									name="name"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black">
										Video
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="video/*"
											onChange={(e) =>
												handleFileChange(e, EAnimationVideoType.video)
											}
											id="credits"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.video && (
											<video src={video} className="h-[52px]" />
										)}
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black">
										Video Show
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="video/*"
											onChange={(e) =>
												handleFileChange(e, EAnimationVideoType.videoShow)
											}
											id="credits"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.videoShow && (
											<video src={videoShow} className="h-[52px]" />
										)}
									</div>
								</div>
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</React.Fragment>
	);
};

export default ModalAnimationAnyone;
