import React, { FC, useEffect, useState } from "react";

interface IActiveSwitcherProps {
	active: boolean;
	handleClick: () => void;
}

const ActiveSwitcher: FC<IActiveSwitcherProps> = ({ active, handleClick }) => {
	const [enabled, setEnabled] = useState<boolean>(active);

	useEffect(() => {
		setEnabled(active);
	}, [active]);

	return (
		<div onClick={handleClick}>
			<label
				htmlFor="active-switcher"
				className="flex cursor-pointer select-none items-center">
				<div className="relative">
					<input
						type="checkbox"
						id="toggle4"
						className="sr-only"
						checked={enabled}
						readOnly
					/>
					<div
						className={`block h-8 w-14 rounded-full ${
							enabled ? "bg-success" : "bg-danger"
						}`}></div>
					<div
						className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-white transition ${
							enabled && "!right-1 !translate-x-full"
						}`}></div>
				</div>
			</label>
		</div>
	);
};

export default ActiveSwitcher;
