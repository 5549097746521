// /** @format */

import { useQuery } from "@tanstack/react-query";
import { transactionService } from "services";
import { TParamsSearch } from "types";
import { QueryKeys } from "utils";

export const useTransaction = () => {
	//PLAN
	const onGetTransactionPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.TRANSACTION_PLAN, params.page, params.search],
			queryFn: () => transactionService.getPlan(params),
		});
		return {
			dataTransactionPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportTransactionPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_TRANSACTION_PLAN, params.page, params.limit],
			queryFn: () => transactionService.getPlan(params),
		});
		return {
			dataExportTransactionPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	//CREDIT
	const onGetTransactionCredit = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.TRANSACTION_CREDIT, params.page, params.search],
			queryFn: () => transactionService.getCredit(params),
		});
		return {
			dataTransactionCredit: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportTransactionCredit = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.EXPORT_TRANSACTION_CREDIT,
				params.page,
				params.limit,
			],
			queryFn: () => transactionService.getCredit(params),
		});
		return {
			dataExportTransactionCredit: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	return {
		onGetTransactionPlan,
		onGetTransactionCredit,
		onExportTransactionPlan,
		onExportTransactionCredit,
	};
};
