/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
import { FormClothes, TParamsSearch } from "types";

const getRequest = (url: string, params?: object) =>
	axiosClient.get(url, { params });

const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);

export const merchantService = {
	//CATEGORY
	getCategory: async (merchantId: string) => {
		return await getRequest(END_POINT.MERCHANT.GET_CATEGORY, {
			merchantId,
		});
	},
	createCategory: async (formData: any) => {
		return await postRequest(END_POINT.MERCHANT.CREATE_CATEGORY, formData);
	},
	editCategory: async (formData: any) => {
		return await postRequest(END_POINT.MERCHANT.EDIT_CATEGORY, formData);
	},
	deleteCategory: async (categoryId: string) => {
		return await postRequest(END_POINT.MERCHANT.DELETE_CATEGORY, {
			categoryId,
		});
	},
	//CLOTHES
	getClothes: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.MERCHANT.GET_CLOTHES, {
			...params,
		});
	},
	createClothes: async (formData: FormClothes) => {
		const fData = new FormData();
		fData.append("name", formData.name);
		fData.append("typeOutfit", formData.typeOutfit);
		fData.append("typeModel", formData.typeModel);
		fData.append("categoryId", formData.categoryId);
		fData.append("link", formData.link as string);
		fData.append("image", formData.image as File);
		// fData.append("size", JSON.stringify(formData.size));

		return await postRequest(END_POINT.MERCHANT.CREATE_CLOTHES, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	editClothes: async (formData: FormClothes) => {
		const fData = new FormData();
		fData.append("clothesId", formData.id);
		fData.append("name", formData.name);
		fData.append("typeOutfit", formData.typeOutfit);
		fData.append("typeModel", formData.typeModel);
		fData.append("categoryId", formData.categoryId);
		fData.append("link", formData.link as string);
		fData.append("image", formData.image as File);
		// fData.append("size", JSON.stringify(formData.size));

		return await postRequest(END_POINT.MERCHANT.EDIT_CLOTHES, fData);
	},
	deleteClothes: async (clothesId: string) => {
		return await postRequest(END_POINT.MERCHANT.DELETE_CLOTHES, { clothesId });
	},
};
