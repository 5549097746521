/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
import {
	FormAddBlog,
	FormAddCredit,
	FormAnimationAnyone,
	FormEditRefferal,
	FormGenMe,
	FormHairStyle,
	FormModelBody,
	FormStaticUser,
	FormUpdateBlog,
	FormUpdateUser,
	FormUser,
	TParamsBlogsGet,
	TParamsGetSaticPlan,
	TParamsGetSaticUser,
	TParamsGetSurvey,
	TParamsSatic,
	TParamsSearch,
} from "types";

const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);
const putRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.put(url, param, conf && conf);
const getRequest = (url: string, params?: object) =>
	axiosClient.get(url, { params });

export const dashboardService = {
	//STATIC
	getStaticFeatureOfUse: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_FEATURE_OF_USE, {
			...params,
		});
	},
	getStaticOneline: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_ONLINE, {
			...params,
		});
	},
	getListStaticFeature: async (params: TParamsGetSaticPlan) => {
		return await getRequest(END_POINT.DASHBOARD.GET_LIST_STATIC_FEATURE, {
			...params,
		});
	},
	getListStaticPlan: async (params: TParamsGetSaticPlan) => {
		return await getRequest(END_POINT.DASHBOARD.GET_LIST_STATIC_PLAN, {
			...params,
		});
	},
	getListStaticUser: async (params: TParamsGetSaticUser) => {
		return await getRequest(END_POINT.DASHBOARD.GET_LIST_STATIC_USER, {
			...params,
		});
	},
	getStaticUser: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_USER, { ...params });
	},
	getStaticReferralUser: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_REFERRAL_USER, {
			...params,
		});
	},
	getStaticPlanUser: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_PLAN, {
			...params,
		});
	},
	getStaticGeneration: async (params: TParamsSatic) => {
		return await getRequest(END_POINT.DASHBOARD.GET_STATIC_GENERATION, {
			...params,
		});
	},

	//SURVEY
	getSurver: async (params: TParamsGetSurvey) => {
		return await getRequest(END_POINT.DASHBOARD.GET_SURVEY, { ...params });
	},

	//BLOGS
	getBlogs: async (params: TParamsBlogsGet) => {
		return await getRequest(END_POINT.DASHBOARD.GET_BLOGS, { ...params });
	},
	createBlog: async (formData: FormAddBlog) => {
		const fData = new FormData();
		fData.append("title", formData.title);
		fData.append("thumbnail", formData.thumbnail as File);
		fData.append("decsEn", formData.decsEn);
		fData.append("decsKo", formData.decsKo);
		fData.append("decsVi", formData.decsVi);
		fData.append("summary", formData.summary);
		return await postRequest(END_POINT.DASHBOARD.CREATE_BLOGS, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	updateBlog: async (formData: FormUpdateBlog) => {
		const fData = new FormData();
		fData.append("id", formData.id);
		fData.append("title", formData.title);
		fData.append("thumbnail", formData.thumbnail as File);
		fData.append("decsEn", formData.decsEn);
		fData.append("decsKo", formData.decsKo);
		fData.append("decsVi", formData.decsVi);
		fData.append("summary", formData.summary);
		return await postRequest(END_POINT.DASHBOARD.UPDATE_BLOGS, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteBlog: async (id: string) => {
		return await postRequest(END_POINT.DASHBOARD.DELETE_UPDATE, { id });
	},

	//USER
	getUser: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_USERS, { ...params });
	},
	addUser: async (formData: FormUser) => {
		return await postRequest(END_POINT.DASHBOARD.ADD_USER, formData);
	},
	updateUser: async (formData: FormUpdateUser) => {
		const fData = new FormData();
		fData.append("uid", formData.uid);
		fData.append("userName", formData.userName as string);
		fData.append("email", formData.email as string);
		fData.append("avatar", formData.avatar as File);
		fData.append("isActive", JSON.stringify(formData.isActive));
		fData.append("isAgree", JSON.stringify(formData.isAgree));
		return await postRequest(END_POINT.DASHBOARD.EDIT_USER, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteUser: async (uid: string) => {
		return await postRequest(END_POINT.DASHBOARD.DELETE_USER, { uid });
	},
	addCredit: async (formData: FormAddCredit) => {
		return await postRequest(END_POINT.DASHBOARD.ADD_CREDIT, formData);
	},
	//HISTORY
	getHistory: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_HISTORY, { ...params });
	},
	//PLAN
	getPlan: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_PLAN, { ...params });
	},
	createPlan: async (formData: any) => {
		return await postRequest(END_POINT.DASHBOARD.GET_PLAN, formData);
	},
	editPlan: async (formData: any) => {
		return await putRequest(END_POINT.DASHBOARD.EDIT_PLAN, formData);
	},
	deletePlan: async (planId: string) => {
		return await postRequest(END_POINT.DASHBOARD.DELETE_PLAN, { planId });
	},
	//GALLERY
	getGallery: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_GALLERY, { ...params });
	},
	deleteGallery: async (id: string) => {
		return await postRequest(END_POINT.DASHBOARD.DELETE_GALLERY, { id });
	},
	//CONTACT-US
	getContactUs: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_CONTACT_US, { ...params });
	},
	//REFERRAL
	getReferral: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_REFERRAL, { ...params });
	},
	getReference: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.DASHBOARD.GET_REFERENCE, { ...params });
	},
	createReferral: async (formData: any) => {
		return await postRequest(END_POINT.DASHBOARD.CREATE_REFERRAL, formData);
	},
	editReferral: async (formData: FormEditRefferal) => {
		const fData = new FormData();
		fData.append("refferralId", formData.refferralId);
		fData.append("code", formData.code);
		fData.append("type", formData.type);
		fData.append("active", JSON.stringify(formData.active));
		return await postRequest(END_POINT.DASHBOARD.EDIT_REFERRAL, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteReferral: async (refferralId: string) => {
		return await postRequest(END_POINT.DASHBOARD.DELETE_REFERRAL, {
			refferralId,
		});
	},
	//HAIR-STYLE
	getHairStyle: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.GENERATION.GET_HAIR_STYLE, { ...params });
	},
	createHairStyle: async (formData: FormHairStyle) => {
		const fData = new FormData();
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		return await postRequest(END_POINT.GENERATION.CREATE_HAIR_STYLE, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	editHairStyle: async (formData: FormHairStyle) => {
		const fData = new FormData();
		fData.append("hairStyleId", formData.hairStyleId);
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		return await postRequest(END_POINT.GENERATION.EDIT_HAIR_STYLE, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteHairStyle: async (hairStyleId: string) => {
		return await postRequest(END_POINT.GENERATION.DELETE_HAIR_STYLE, {
			hairStyleId,
		});
	},
	//GENME
	getGenMe: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.GENERATION.GET_GEN_ME, { ...params });
	},
	createGenMe: async (formData: FormGenMe) => {
		const fData = new FormData();
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		return await postRequest(END_POINT.GENERATION.CREATE_GEN_ME, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	editGenMe: async (formData: FormGenMe) => {
		const fData = new FormData();
		fData.append("genMeId", formData.genMeId);
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		return await postRequest(END_POINT.GENERATION.EDIT_GEN_ME, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteGenMe: async (genMeId: string) => {
		return await postRequest(END_POINT.GENERATION.DELETE_GEN_ME, { genMeId });
	},
	//MODEL-BODY
	getModelBody: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.GENERATION.GET_MODEL_BODY, { ...params });
	},
	createModelBody: async (formData: FormModelBody) => {
		const fData = new FormData();
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		fData.append("typeModel", formData.typeModel);
		fData.append("type", formData.type);
		return await postRequest(END_POINT.GENERATION.CREATE_MODEL_BODY, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	editModelBody: async (formData: FormModelBody) => {
		const fData = new FormData();
		fData.append("modelBodyId", formData.modelBodyId);
		fData.append("name", formData.name);
		fData.append("image", formData.image as File);
		fData.append("typeModel", formData.typeModel);
		fData.append("type", formData.type);
		fData.append("uid", formData.uid);
		fData.append("bodyShapeId", formData.bodyShapeId);
		fData.append("weight", JSON.stringify(formData.weight));
		fData.append("height", JSON.stringify(formData.height));
		fData.append("bmi", JSON.stringify(formData.bmi));
		fData.append("imageWaterMask", formData.imageWaterMask);
		return await postRequest(END_POINT.GENERATION.EDIT_MODEL_BODY, fData, {
			headers: { "Content-Type": "multipart/form-data" },
		});
	},
	deleteModelBody: async (modelBodyId: string) => {
		return await postRequest(END_POINT.GENERATION.DELETE_MODEL_BODY, {
			modelBodyId,
		});
	},
	//ANIMATION ANIONE
	getAnimationAnyone: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.GENERATION.GET_ANIMATION_ANYONE, {
			...params,
		});
	},
	createAnimationAnyone: async (formData: FormAnimationAnyone) => {
		const fData = new FormData();
		fData.append("name", formData.name);
		fData.append("video", formData.video as File);
		fData.append("videoShow", formData.videoShow as File);
		return await postRequest(
			END_POINT.GENERATION.CREATE_ANIMATION_ANYONE,
			fData,
			{
				headers: { "Content-Type": "multipart/form-data" },
			},
		);
	},
	editAnimationAnyone: async (formData: FormAnimationAnyone) => {
		const fData = new FormData();
		fData.append("videoId", formData.videoId);
		fData.append("name", formData.name);
		fData.append("video", formData.video as File);
		fData.append("videoShow", formData.videoShow as File);
		return await postRequest(
			END_POINT.GENERATION.EDIT_ANIMATION_ANYONE,
			fData,
			{
				headers: { "Content-Type": "multipart/form-data" },
			},
		);
	},
	deleteAnimationAnyone: async (videoId: string) => {
		return await postRequest(END_POINT.GENERATION.DELETE_ANIMATION_ANYONE, {
			videoId,
		});
	},
};
