import React, { ReactNode } from "react";

interface TableRowProps {
	children: ReactNode;
	active?: boolean;
}

const TableRow: React.FC<TableRowProps> = ({ children, active = true }) => {
	return (
		<td
			className={`border-b border-[#eee] py-5 px-4 ${
				active ? "" : "opacity-[0.4] pointer-events-none"
			}`}>
			{children}
		</td>
	);
};

export default TableRow;
