/** @format */

export const PUBLIC_URL = {
	HOME: "/",
	LOGIN: "/login",
	SIGNUP: "/register",
	FORGOT_PASSWORD: "/forgot-password",
	VERIFY_OTP: "/verify-otp",
	RESET_PASSWORD: "/reset-password",
	NOT_FOUND: "/*",
	MAINTAIN: "/maintain",
};

export const APP_ROOT = {
	ROOT: "/",
	SATATIC: "/admin/static",
	FEATURE: "/admin/feature",
	USER: "/admin/user",
	SETTING: "/admin/setting",

	DASHBOARD: {
		USER: "/admin/dashboard/users",
		PLAN: "/admin/dashboard/pricing-plan",
		CONTACT_US: "/admin/dashboard/contact-us",
		GALLERY: "/admin/dashboard/gallery",
		NOTIFICATION: "/admin/dashboard/notifications",
		REFERRAL: "/admin/dashboard/referral",
		REFERENCE: "/admin/dashboard/reference",
		HISTORY: "/admin/dashboard/history",
	},
	TRANSACTION: {
		TRANSACTION_CREDIT: "/admin/transactions/credit",
		TRANSACTION_PLAN: "/admin/transactions/plan",
	},
	GENERATION: {
		HAIR_STYLE: "/admin/generations/hair-style",
		GEM_ME: "/admin/generations/gen-me",
		MODEL_BODY: "/admin/generations/model-body",
		ANIMATION_ANYONE: "/admin/generations/animation-anyone",
	},
	SETTINGS: {
		PLANS_CREDIT: "/admin/settings/plans-credit",
		PLANS_UNIT: "/admin/settings/plans-unit",
		CHARGE_SERVICE: "/admin/settings/charge-services",
		NOTIFICATION: "/admin/settings/setting-notification",
		TERMS_AND_CONDITIONS: "/admin/settings/terms-and-conditions",
	},
	NOTIFICATIONS: "/admin/notifications",
	HISTORIES: {
		ADD_CREDIT: "/admin/histories/add-credit",
	},
};

export const APP_MERCHANT = {
	ROOT: "/",
	// CLOTHES: "/merchant/clothes",
};
