// /** @format */

import { useMutation } from "@tanstack/react-query";
import { authService, userService } from "services";
import { FormLogin } from "types";
import { useUser } from "./useUser";
import { useNavigate } from "react-router-dom";
import SnackbarUtils from "utils/SnackbarUtils";
import { APP_MERCHANT, APP_ROOT, PUBLIC_URL } from "utils/routes";
import { useKey } from "./useKey";
import { KEY_CONTEXT } from "utils";

// import { EModelAuth } from 'types';
// import { useKey } from './useKey';
// import { useUser } from './useUser';
// import { useLayout } from './useLayout';
// import { APP_ROOT } from 'utils/routes';
// import { useNavigate } from 'react-router-dom';

export const useAuthentication = () => {
	const { removeKeySite, setKeySite, removeKey } = useKey();
	const { setUser, setIsLogged, setUID, setIsMerchant } = useUser();
	// const { setOpenModalAuth } = useLayout();
	const navigate = useNavigate();

	const loginMutation = useMutation({
		mutationFn: (formData: FormLogin) => authService.login(formData),
		onSuccess: async ({ data }) => {
			if (data?.statusCode === 200) {
				const { accessToken, refreshToken } = data.data;
				setKeySite({ authToken: accessToken, refreshToken: refreshToken });
				const rs = await userService.getProfile();
				console.log("rs get profile", rs);
				if (rs?.data) {
					updateLoginSucess(rs.data?.data);
				}
				return;
			}
			SnackbarUtils.warning(data?.message || "Login failed");
		},
		onError: (error) => {
			console.log("🚀 ~ SignIn ~ error:", error);
			SnackbarUtils.warning("Login failed");
		},
	});

	const updateLoginSucess = (usr: any) => {
		// setOpenModalAuth(EModelAuth.DEFAULT);
		setUser(usr);
		setUID(usr.id);
		setIsLogged(true);

		console.log(usr);

		if (usr.merchantId) {
			setIsMerchant(true);
			navigate(APP_MERCHANT.ROOT);
		} else {
			setIsMerchant(false);
			navigate(APP_ROOT.ROOT);
		}
	};

	const resetStore = () => {
		setIsLogged(false);
		localStorage.clear();
		removeKeySite();
		return;
	};

	const logoutMutation = useMutation({
		mutationFn: () => userService.logout(),
		onSuccess: async (data: any) => {
			console.log("Data logout", data);
			if (data.statusCode === 200) {
				resetStore();
				removeKey(KEY_CONTEXT.UID);
				window.location.href = PUBLIC_URL.LOGIN;
			}
		},
		onError: (error) => {
			console.log("🚀 ~ SignOut ~ error:", error);
			SnackbarUtils.success("Logout successfully!");
			resetStore();
		},
	});

	return {
		loginMutation,
		logoutMutation,
	};
};
