import React, { FC, useState } from "react";
import { durationOptions } from "utils";
import DateRangeSelector from "./DateRange";
import DropdownWithCustomDate from "./DropDownWithCustomDate";

interface IDurationProps {
	value: string;
	onChange: (value: string) => void;
	onSave: any;
	params: any;
}

const Duration: FC<IDurationProps> = ({ value, onChange, onSave, params }) => {
	const [openDateRange, setOpenDateRange] = useState(false);

	return (
		<div className="relative flex flex-row px-2 mb-4 gap-2">
			<DropdownWithCustomDate
				params={params}
				options={durationOptions}
				value={value}
				onChange={(v: any) => {
					if (v !== "custom") {
						onChange(v);
					} else {
						setOpenDateRange(true);
					}
				}}
			/>
			{openDateRange && (
				<div className="absolute top-full left-0 z-99">
					<DateRangeSelector
						onClose={() => setOpenDateRange(false)}
						onSave={onSave}
					/>
				</div>
			)}
		</div>
	);
};

export default Duration;
