import React, { useCallback, useEffect, useState } from "react";
import { useDashboard, useLayout } from "hooks";
import { avtDf, Icons } from "assets";
import {
	EIllunimusEmail,
	ETypeSearch,
	FormAddCredit,
	FormUpdateUser,
	FormUser,
	IUserTable,
	TParams,
	TParamsSearch,
	TypeLogin,
} from "types";
import {
	ModalDelete,
	ModalAddCredit,
	ModalUpdateUser,
	ModalUser,
	ModalActive,
} from "components/Modal";
import { FORM_ACTION, GET_URL_STORAGE, tableUserHeader } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Button } from "components/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Table, TableRow } from "components";
import { mainColor } from "theme";
import { ExcelExport } from "components/ExcelExport";
import { ActiveSwitcher, FormatPlan, SearchUser } from "./component";

const Users = () => {
	const {
		onGetUser,
		updateUserMutation,
		deleteUserMutation,
		addUserMutation,
		addCreditMutation,
		onExportUser,
	} = useDashboard();
	const {
		setOpenModalUser,
		setOpenModalUpdateUser,
		setOpenModalDelete,
		setOpenModalAddCredit,
		setOpenModalActive,
	} = useLayout();
	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
		plan: "",
		duration: 30,
		startDate: "",
		endDate: "",
	});

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.NAME);

	const { dataUser, isLoading, refetch, totalPage } = onGetUser({
		...params,
	});

	//Export User
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10000,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportUser({
		...paramsExport,
	});

	const [filteredData, setFilteredData] = useState(dataUser);

	const handleSearch = useCallback(() => {
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataUser);
	}, [searchValue, searchOption, params]);

	const handleResetSearch = () => {
		setParams({
			page: 1,
			limit: 10,
			search: undefined,
			type: undefined,
			plan: "",
			duration: 30,
			startDate: "",
			endDate: "",
		});
		setSearchValue("");
	};

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setParams({
			...params,
			page: 1,
		});
	}, [params.plan, params.duration]);

	useEffect(() => {
		setFilteredData(dataUser);
	}, [dataUser]);

	const onCreateUser = useCallback((data: FormUser) => {
		//call API update user here
		addUserMutation.mutate(data, {
			onSettled: () => setOpenModalUser(false),
		});
	}, []);

	const onUpdateUser = useCallback(
		(data: FormUpdateUser) => {
			updateUserMutation.mutate(data, {
				onSuccess: () => setOpenModalUpdateUser(false),
			});
		},
		[item],
	);

	const onDeleteUser = useCallback(
		(uid: string) => {
			deleteUserMutation.mutate(uid, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	const onEditCredit = useCallback(
		(formData: FormAddCredit) => {
			addCreditMutation.mutate(formData, {
				onSuccess: () => setOpenModalAddCredit(false),
			});
		},
		[item],
	);

	const onActiveUser = useCallback(
		(data: FormUpdateUser) => {
			updateUserMutation.mutate(data, {
				onSettled: () => setOpenModalActive(false),
			});
		},
		[item],
	);

	// const onExportToCSV = () => {
	// 	return;
	// };
	console.log("filteredData", filteredData);

	return (
		<React.Fragment>
			<div className="w-full flex justify-between">
				<h1 className="font-bold text-xl mb-4 ">User Dashboard</h1>
				<div className="flex items-center justify-center mb-6 gap-5">
					<div className="cursor-pointer">
						<ExcelExport
							fileName="users"
							getData={() => refetchExport()}
							params={paramsExport}
							setParams={setParamsExport}
							isLoading={isLoadingExport}
							totalPage={totalPage}
						/>
					</div>
					<Button
						className="inline-flex items-center justify-center gap-2"
						onClick={() => {
							setOpenModalAddCredit(true);
						}}>
						<span>{Icons.CreditPlus(mainColor.primary)}</span>
						Add credits
					</Button>
					<Button
						className="inline-flex items-center justify-center gap-2"
						onClick={() => setOpenModalUser(true)}>
						<span>{Icons.PlusWhite()}</span>
						Add user
					</Button>
				</div>
			</div>

			<SearchUser
				params={params}
				setParams={setParams}
				searchValue={searchValue}
				setSearchValue={setSearchValue}
				searchOption={searchOption}
				setSearchOption={setSearchOption}
				handleSearch={handleSearch}
				handleResetSearch={handleResetSearch}
			/>

			<Table
				isLoading={isLoading}
				isAction={false}
				dataHeader={tableUserHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => {
					setParams({ ...params, page: params.page + 1 });
					refetch();
				}}
				setFilteredData={setFilteredData}>
				{filteredData?.map((user: IUserTable, key: number) => (
					<tr key={key} className="bg-[#131313]">
						<TableRow active={user.isActive}>
							<div className="flex items-center space-x-3.5">
								<button
									title="Edit User"
									onClick={() => {
										setOpenModalUpdateUser(true);
										setItem(user);
										setAction(FORM_ACTION.EDIT);
									}}
									className="hover:text-primary">
									{Icons.Edit()}
								</button>
								<button
									title="Delete User"
									onClick={() => {
										setOpenModalDelete(true);
										setItem(user);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</TableRow>
						<TableRow active={user.isActive}>
							<h5
								className={`font-medium text-[#ffffff] ${
									user?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{user.email}
							</h5>
						</TableRow>
						<TableRow active={user.isActive}>
							<h5 className="font-medium text-[#ffffff]">{user.userName}</h5>
						</TableRow>
						<TableRow active={user.isActive}>
							<LazyLoadImage
								src={user.avatar ? GET_URL_STORAGE + user.avatar : avtDf}
								className="w-[40px] h-[40px]"
							/>
						</TableRow>

						<TableRow active={user.isActive}>
							<p className="text-[#ffffff]">{user.credits}</p>
						</TableRow>
						<TableRow active={user.isActive}>
							<FormatPlan data={user.plan} />
						</TableRow>

						<TableRow active={user.isActive}>
							<p
								className={`text-[#ffffff] ${
									user.typeLogin === TypeLogin.GOOGLE
										? "text-primary"
										: "text-warning"
								}`}>
								{user.typeLogin}
							</p>
						</TableRow>

						<TableRow active={true}>
							<p className="text-[#ffffff]">
								<ActiveSwitcher
									active={user.isActive}
									handleClick={() => {
										setOpenModalActive(true);
										setItem(user);
									}}
								/>
							</p>
						</TableRow>
						<TableRow active={user.isActive}>
							<p className="text-[#ffffff]">{user.daysRemaining}</p>
						</TableRow>
						{/*-------------------------------Feature-----------------------------*/}
						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalUser
				onClose={() => setOpenModalUser(false)}
				onConfirm={onCreateUser}
				isLoading={addUserMutation.isPending}
			/>
			<ModalUpdateUser
				item={item}
				onClose={() => setOpenModalUpdateUser(false)}
				action={action}
				onConfirm={onUpdateUser}
				isLoading={updateUserMutation.isPending}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteUser}
			/>
			<ModalAddCredit
				item={item}
				onClose={() => setOpenModalAddCredit(false)}
				onConfirm={onEditCredit}
				isLoading={addCreditMutation.isPending}
			/>
			<ModalActive
				item={item}
				onClose={() => setOpenModalActive(false)}
				onConfirm={onActiveUser}
				isLoading={updateUserMutation.isPending}
			/>
		</React.Fragment>
	);
};

export default Users;
