import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import React from "react";
import { BUTTON_TYPE } from "utils";

interface IModalActive {
	item: any;
	onClose: () => void;
	onConfirm: (item: any) => void;
	isLoading?: boolean;
}

const modalActive = ({ item, onClose, onConfirm, isLoading }: IModalActive) => {
	const { openModalActive, setOpenModalActive } = useLayout();

	return (
		<>
			{openModalActive && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => !isLoading && setOpenModalActive(false)}>
						<div className="mb-4 flex gap-2 flex-col bg-white px-8 py-6 rounded-lg">
							<div className="mb-4 flex text-black gap-1 bg-white">
								Do you want to update selected user?
							</div>
							<div className="flex flex-row gap-1 w-full ">
								<Button
									className="w-full"
									variant={BUTTON_TYPE.OUTLINED}
									disabled={isLoading}
									onClick={onClose}>
									Cancel
								</Button>
								<Button
									disabled={isLoading}
									onClick={() =>
										onConfirm({
											...item,
											uid: item.id,
											isActive: !item.isActive,
										})
									}
									className="w-full">
									Confirm {isLoading && <span>{Icons.Spinner()}</span>}
								</Button>
							</div>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalActive;
