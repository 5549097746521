import React, { useState } from "react";
import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { useLayout } from "hooks";
import { BUTTON_TYPE, categorySchema, FORM_ACTION } from "utils";
import { FormCategory } from "types";

interface IModalCategory {
	item?: any;
	action: string;
	onClose: () => void;
	onConfirm: (data: any) => void;
}

const modalCategory = ({
	action,
	onClose,
}: // onConfirm,
IModalCategory) => {
	const { openModalCategory, setOpenModalCategory } = useLayout();
	const [formData, setFormData] = useState<FormCategory>({
		id: "",
		name: "",
		describe: "",
	});
	const [errors, setErrors] = useState({});

	const isLoading = false;

	// useEffect(() => {
	// 	if (action === FORM_ACTION.EDIT && item) {
	// 		setImage(item.image);
	// 		setFormData({
	// 			id: item.id,
	// 			name: item.name,
	// 			type: item.type,
	// 			image: item.image,
	// 			describe: item.describe,
	// 		});
	// 	} else {
	// 		setFormData({
	// 			id: "",
	// 			name: "",
	// 			type: ETypeCategory.HAIR_COLOR,
	// 			image: undefined,
	// 			describe: "",
	// 		});
	// 	}
	// }, [item, openModalCategory]);

	const handleSubmit = async () => {
		try {
			await categorySchema.validate(formData, { abortEarly: false });
			// onConfirm(formData);
			// console.log("data", formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<>
			{openModalCategory && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalCategory(false);
							setErrors({});
						}}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Category"
									: "Edit Category"}
							</h3>
							<div>
								<Input
									disabled={isLoading}
									label="Category Name"
									type="text"
									formData={formData}
									placeholder="Enter category name"
									name="name"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Describe"
									type="textarea"
									rows={4}
									formData={formData}
									placeholder="Enter describe"
									name="describe"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</div>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalCategory;
