import { Editor } from "@tinymce/tinymce-react";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import * as React from "react";

interface IModalUser {
	onClose: () => void;
}

const modalPolicy = ({ onClose }: IModalUser) => {
	const { openModalPolicy, setOpenModalPolicy } = useLayout();

	const [value, setValue] = React.useState("test");
	const editorRef = React.useRef<any>(null);
	const log = () => {
		if (editorRef.current) {
			const content = editorRef.current.getContent();
			editorRef.current.setDirty(false);
			// an application would save the editor content to the server here
			console.log(content);
		}
	};

	return (
		<>
			{openModalPolicy && (
				<div className="fixed left-0 top-0 z-999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalPolicy(true)}>
						<div className="xl:w-[1200px] md:w-[800px] h-[80vh] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded flex flex-col">
							<h3 className="text-primary text-center mb-4 text-xl font-bold">
								Create new policy
							</h3>
							<div className="w-full mb-4 flex-1">
								<Editor
									ref={editorRef}
									onInit={(_evt, editor: any) => (editorRef.current = editor)}
									apiKey={process.env.REACT_APP_TINYMCE_KEY}
									init={{
										plugins: [
											"anchor",
											"autolink",
											"charmap",
											"codesample",
											"emoticons",
											"image",
											"link",
											"lists",
											"media",
											"searchreplace",
											"table",
											"visualblocks",
											"wordcount",
											"checklist",
											"casechange",
											"export",
											"powerpaste",
											"advtable",
											"tableofcontents",
											"autocorrect",
											"typography",
											"inlinecss",
											"importword",
											"exportword",
											"exportpdf",
											"insertdatetime",
										],
										toolbar:
											"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
										content_style:
											"body { font-family:Helvetica,Arial,sans-serif; font-size:14px}",
										height: "100%",
										width: "100%",
									}}
									value={value}
									onEditorChange={(newValue, editor) => setValue(newValue)}
									initialValue="Welcome to TinyMCE!"
								/>
							</div>
							<div className="mb-4 flex justify-end gap-1">
								<div>
									<button
										onClick={onClose}
										className="inline-flex items-center justify-center rounded-full border border-primary py-3 px-10 text-center font-medium hover:bg-opacity-90 lg:px-8 xl:px-10 text-primary">
										Cancel
									</button>
								</div>
								<div>
									<button
										onClick={log}
										type="button"
										className="inline-flex items-center justify-center rounded-full bg-primary border border-primary py-3 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 disabled:bg-gray-400 disabled:border-none">
										Confirm
									</button>
								</div>
							</div>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default modalPolicy;
