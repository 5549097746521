import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { EChartUser } from "types/chart";

const categories = [
	"2024-09-19T00:00:00.000Z",
	"2024-09-19T01:30:00.000Z",
	"2024-09-19T02:30:00.000Z",
	"2024-09-19T03:30:00.000Z",
	"2024-09-19T04:30:00.000Z",
	"2024-09-19T05:30:00.000Z",
	"2024-09-19T06:30:00.000Z",
];

interface LineChartState {
	series: {
		name: string;
		data: number[];
	}[];
}

interface ElineChart {
	data?: any;
}

const LineChart: React.FC<ElineChart> = ({ data }) => {
	//////////////////////////////////////////////////////////////////
	const arrayCate: any = data?.[0];
	const dataCategories: any = [];

	if (arrayCate) {
		const items: any = arrayCate?.groupItems;
		for (const item of items) {
			dataCategories.push(item?.key);
		}
	}
	///////////////////////////////////////////////////////////////

	const totalUser: any = [];
	const verifiedUser: any = [];
	const freeUser: any = [];
	const paidUser: any = [];

	const [state, setState] = useState<LineChartState>({
		series: [
			{
				name: "Total User",
				data: totalUser,
			},

			{
				name: "Verified User",
				data: verifiedUser,
			},

			{
				name: "Free User",
				data: freeUser,
			},
			{
				name: "Paid User",
				data: paidUser,
			},
		],
	});
	const [totalY, setTotalY] = useState(0);
	let maxTotalItems = 0;
	useEffect(() => {
		if (data?.length > 0) {
			for (const item of data) {
				const items: any = item?.groupItems;
				for (const e of items) {
					if (item?.title === EChartUser.TOTAL_USER) {
						totalUser.push(e?.value);
					}
					if (item?.title === EChartUser.VERIFIED_USER) {
						verifiedUser.push(e?.value);
					}
					if (item?.title === EChartUser.FREE_USER) {
						freeUser.push(e?.value);
					}
					if (item?.title === EChartUser.PAID_USER) {
						paidUser.push(e?.value);
					}
				}
				const currenTotalItem = item?.totalItems || 0;
				if (currenTotalItem > maxTotalItems) {
					maxTotalItems = currenTotalItem;
					setTotalY(maxTotalItems / 2);
				}
			}

			setState({
				series: [
					{
						name: "Total User",
						data: totalUser,
					},

					{
						name: "Verified User",
						data: verifiedUser,
					},

					{
						name: "Free User",
						data: freeUser,
					},
					{
						name: "Paid User",
						data: paidUser,
					},
				],
			});
		}
	}, [data]);

	const options: ApexOptions = {
		legend: {
			show: false,
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#98DD0D", "#FF52F0", "#FF9D00", "#F45772"],

		responsive: [
			{
				breakpoint: 1024,
				options: {
					chart: {
						height: 300,
					},
				},
			},
			{
				breakpoint: 1366,
				options: {
					chart: {
						height: 300,
					},
				},
			},
		],
		stroke: {
			curve: "smooth",
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			colors: "#fff",
			strokeColors: ["#98DD0D", "#FF52F0", "#FF9D00", "#F45772"],
			// strokeWidth: 3,
			// strokeOpacity: 0.9,
			// strokeDashArray: 0,
			// fillOpacity: 1,
			discrete: [],
			hover: {
				size: undefined,
				sizeOffset: 5,
			},
		},
		xaxis: {
			// type: 'datetime',
			type: "datetime",
			categories: dataCategories ?? categories,
		},
		yaxis: {
			min: 0,
			max: totalY ?? 500,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	};

	return (
		<div className="w-[70%] col-span-12 rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<div>
				<div id="lineChart" className="-ml-5">
					<ReactApexChart
						options={options}
						series={state.series}
						type="area"
						height={350}
					/>
				</div>
			</div>

			<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
				<div className="flex w-full flex-wrap gap-3 sm:gap-5">
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#98DD0D]" />
						<div className="w-full">
							<p className="font-semibold text-[#98DD0D]">Total User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF52F0]" />
						<div className="w-full">
							<p className="font-semibold text-[#FF52F0]">Verified User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF9D00]" />
						<div className="w-full">
							<p className="font-semibold text-[#FF9D00]">Free User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#F45772]" />
						<div className="w-full">
							<p className="font-semibold text-[#F45772]">Paid User</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
// #FF9D00
export default LineChart;
