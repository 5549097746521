import { Table, TableRow } from "components";
import { ExcelExport } from "components/ExcelExport";
import ExcelExportPlan from "components/ExcelExport/ExcelExportPlan";
import { useDashboard, useLayout } from "hooks";
import { ActiveSwitcher, FormatPlan } from "pages/admin/user/component";
import React, { useEffect, useState } from "react";
import {
	EIllunimusEmail,
	IUserTable,
	TParams,
	TParamsGetSaticUser,
} from "types";
import { formatDMY, tableStaticUserHeader } from "utils";

interface ITableResult {
	duration?: number;
	startDate?: string;
	endDate?: string;
	type?: string;
	rate?: any;
}

const TableResult: React.FC<ITableResult> = ({
	duration = 30,
	startDate,
	endDate,
	type = "Total User",
	rate,
}) => {
	//////////// USESTATE //////////////
	const [params, setParams] = useState<TParamsGetSaticUser>({
		page: 1,
		limit: 10,
		duration,
		startDate,
		endDate,
		type,
	});

	const { onGetListStaticUser, onExportUser } = useDashboard();

	const { setOpenModalActive } = useLayout();

	const { dataUser, isLoading, refetch, totalPage } =
		onGetListStaticUser(params);

	useEffect(() => {
		setParams({ ...params, type, duration, startDate, endDate });
		refetch;
		setFilteredData(dataUser);
	}, [duration, startDate, endDate, type, dataUser]);

	const [filteredData, setFilteredData] = useState(dataUser);

	return (
		<React.Fragment>
			<div className="w-full flex justify-between mt-5">
				<h1 className="font-bold text-xl">{type}</h1>
				<div className="flex items-center justify-center gap-5">
					<div className="cursor-pointer">
						<ExcelExportPlan
							fileName={`List_User_${rate}`}
							getData={dataUser}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>

			<Table
				isLoading={isLoading}
				isAction={false}
				dataHeader={tableStaticUserHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params?.page - 1 });
					refetch();
				}}
				onNextClick={() => {
					setParams({ ...params, page: params?.page + 1 });
					refetch();
				}}
				setFilteredData={setFilteredData}>
				{filteredData?.map((user: IUserTable, key: number) => (
					<tr key={key} className="bg-[#131313]">
						<TableRow>
							<h5
								className={`font-medium text-[#ffffff] ${
									user?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{user?.email}
							</h5>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">{user?.userName}</h5>
						</TableRow>

						<TableRow>
							<FormatPlan data={user?.plan} />
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">
								<ActiveSwitcher
									active={user?.isVerify}
									handleClick={() => {
										setOpenModalActive(true);
									}}
								/>
							</p>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">{formatDMY(user?.createdAt)}</p>
						</TableRow>

						<TableRow>
							{user.isActive === null ? (
								<p className="text-[#ffffff]">----</p>
							) : (
								<p className="text-[#ffffff]">
									<ActiveSwitcher
										active={user.isActive}
										handleClick={() => {
											setOpenModalActive(true);
										}}
									/>
								</p>
							)}
						</TableRow>

						<TableRow>
							{user?.checkout === null ? (
								<p className="text-[#ffffff]">----</p>
							) : (
								<p className="text-[#ffffff]">{user?.checkout}</p>
							)}
						</TableRow>

						<TableRow>
							{user?.cancelPlan === null ? (
								<p className="text-[#ffffff]">----</p>
							) : (
								<p className="text-[#ffffff]">{user?.cancelPlan}</p>
							)}
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleFit?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.styleHair?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.genMe?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.animationAnyone?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>

						<TableRow>
							<tr>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Generated</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.generated}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Succeed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.succeed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Failed</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.failed}
									</h5>
								</td>
								<td className="px-2 ">
									<h5 className="font-medium text-[#ffffff]">Download</h5>
									<h5 className="font-medium text-[#ffffff]">
										{user?.bodyShape?.downloaded}
									</h5>
								</td>
								<td className="h-5 bg-white"></td>
							</tr>
						</TableRow>
					</tr>
				))}
			</Table>
		</React.Fragment>
	);
};

export default TableResult;
