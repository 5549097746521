import ExportChart from "components/ExcelExport/ExportChart";
import { useDashboard } from "hooks";
import React, { useState } from "react";
import { TParams, TParamsSatic } from "types";
import {
	CardDataStats,
	LineChart,
	ProcessBar,
	SearchUser,
	TableResult,
} from "./component";
import { LoadingBase } from "components";

const User = () => {
	const { onExportUser, onGetStaticUser } = useDashboard();
	const [params, setParams] = useState<TParamsSatic>({
		duration: 30,
		startDate: "",
		endDate: "",
	});

	const { dataStatic, isLoading } = onGetStaticUser({
		...params,
	});

	const [type, setType] = useState("");

	const handleClick = (title: string) => {
		setType(title);
	};

	//////////////////////////////////////////////////////////////////////////
	//Export User
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportUser({
		...paramsExport,
	});
	return (
		<React.Fragment>
			{/* <div className="flex justify-end">
				<ExportChart
					fileName="users"
					getData={dataStatic}
					params={paramsExport}
					setParams={setParamsExport}
					isLoading={isLoadingExport}
				// totalPage={totalPage}
				/>
			</div> */}
			<SearchUser params={params} setParams={setParams} />
			{isLoadingExport || isLoading ? (
				<LoadingBase />
			) : (
				<>
					<div className="pt-5 flex flex-wrap">
						{dataStatic?.map((item: any, index: number) => (
							<div
								key={index}
								onClick={() => {
									handleClick(item?.title);
								}}>
								<CardDataStats
									title={item?.title}
									total={item?.totalItems}
									type={type}
									rate={
										params.duration !== 0
											? `${params.duration?.toString()} days ago`
											: params.startDate
											? "Range day"
											: "Today"
									}
								/>
							</div>
						))}
					</div>
					{type && (
						<TableResult
							duration={params?.duration}
							startDate={params?.startDate}
							endDate={params?.endDate}
							type={type}
							rate={
								params.duration !== 0
									? `${params.duration?.toString()} days ago`
									: params.startDate
									? "Range day"
									: "Today"
							}
						/>
					)}
					<div className="mt-10 flex justify-between ">
						<LineChart data={dataStatic} />
						<ProcessBar data={dataStatic} />
					</div>
				</>
			)}
		</React.Fragment>
	);
};

export default User;
