import { Icons } from "assets";
import { saveAs } from "file-saver";
import { useDashboard } from "hooks";
import React, { FC, memo, useState } from "react";
import { flattenArray, flattenObject } from "utils";
import * as XLSX from "xlsx";

interface IExportExcelProps {
	type?: any;
	feature?: any;
}

const ExcelExportSurvey: FC<IExportExcelProps> = ({ type, feature }) => {
	const [paramsExport, setParamsExport] = useState({});
	const { onGetSurvey } = useDashboard();
	const { dataUser, isLoading } = onGetSurvey({
		page: 1,
		limit: 1000,
		feature,
		type,
	});
	console.log("🚀 ~ export dataUser:", dataUser);

	const exportToExcel = async () => {
		try {
			const exportData = await dataUser;
			const flattenedData = flattenArray(exportData);
			const worksheet = XLSX.utils.json_to_sheet(flattenedData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			const blob = new Blob([excelBuffer], {
				type: "application/octet-stream",
			});
			saveAs(blob, `List_Survey_User.xlsx`);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="relative cursor-pointer mr-6">
			<button
				type="button"
				disabled={isLoading}
				className=" flex items-center justify-center rounded-[12px] bg-[#303030] px-[16px] py-[12px] text-center font-medium text-sm text-white hover:bg-opacity-90 lg:px-8 xl:px-5 disabled:bg-gray-400 "
				onClick={(e: any) => {
					e.preventDefault();
					exportToExcel();
				}}>
				<span className="px-2">{Icons.Download()}</span> Export
			</button>
		</div>
	);
};

export default memo(ExcelExportSurvey);
