/** @format */

import { Header, Sidebar } from "components";
import React, { useState } from "react";

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<div className="bg-[#131313] text-[#ffffff]">
			<div className=" flex h-screen overflow-hidden text-[#ffffff]">
				<Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
				<div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
					<Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
					<main className="h-screen">
						<div className="mx-auto p-4 md:p-6 2xl:p-10 h-full">{children}</div>
					</main>
				</div>
			</div>
		</div>
	);
};
