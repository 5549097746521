import React, { FC } from "react";
import Duration from "pages/admin/user/component/Duration";
import { ETypeSearch } from "types";
import { Dropdown } from "components";
import {
	FeatureSurveyOptions,
	TypeSurveyOptions,
	planOptions,
	statusPlanOption,
	typeOfUseOption,
} from "utils";

interface ISelectDurationProps {
	typeSurvey?: any;
	setTypeSurvey?: any;
	typeFeature?: any;
	setTypeFeature?: any;
}

const SelectSurvey: FC<ISelectDurationProps> = ({
	typeSurvey,
	setTypeSurvey,
	typeFeature,
	setTypeFeature,
}) => {
	return (
		<div className="flex flex-row px-2 mb-4 gap-2">
			{/* <div className="w-fit h-[42px]">
				<p className="pl-[6px] text-[12px]">Date</p>
				<Duration
					params={params}
					value={params.duration}
					onChange={(v: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: v,
							[ETypeSearch.START_DATE]: "",
							[ETypeSearch.END_DATE]: "",
						})
					}
					onSave={(startDate: any, endDate: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: 0,
							[ETypeSearch.START_DATE]: startDate,
							[ETypeSearch.END_DATE]: endDate,
						})
					}
				/>
			</div> */}
			<div className="w-fit h-[42px]">
				<div>
					<p className="pl-[6px] text-[12px]">Type Survey</p>
					<Dropdown
						options={TypeSurveyOptions}
						value={typeSurvey}
						onChange={(v: any) => {
							setTypeSurvey(v);
						}}
					/>
				</div>
			</div>
			{typeSurvey == "FEATURE" && (
				<div className="w-fit h-[42px]">
					<div>
						<p className="pl-[6px] text-[12px]">Type Feature</p>
						<Dropdown
							options={FeatureSurveyOptions}
							value={typeFeature}
							onChange={(v: any) => {
								setTypeFeature(v);
							}}
						/>
					</div>
				</div>
			)}

			<div></div>
		</div>
	);
};

export default SelectSurvey;
