import React, { useCallback, useEffect, useState } from "react";
import { useDashboard } from "hooks";
import { Icons } from "assets";
import { ETypeSearch, IReference, TParams, TParamsSearch } from "types";
import { formatDMY, searchReffralOption, tableReferenceHeader } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Search } from "components/Search";
import { Button } from "components/Button";
import { Dropdown, Table, TableRow } from "components";
import { ExcelExport } from "components/ExcelExport";

const Reference = () => {
	const { onGetReference, onExportReference } = useDashboard();

	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const [expandedRows, setExpandedRows] = useState<any>(null);

	const { dataReference, isLoading, refetch, totalPage } = onGetReference({
		...params,
	});
	//Export References
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportReference({
			...paramsExport,
		});
	const [filteredData, setFilteredData] = useState(dataReference);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataReference);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataReference);
	}, [dataReference]);

	const handleExpandRow = (index: number) => {
		let currentExpandedRows = null;
		const isRowExpanded = currentExpandedRows === index ? index : null;
		const newExpandedRows = isRowExpanded
			? null
			: (currentExpandedRows = index);
		if (expandedRows !== index) {
			setExpandedRows(newExpandedRows);
		} else {
			setExpandedRows(null);
		}
	};

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Reference management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 ">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchReffralOption}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Name, Email"
				/>
				<Button onClick={handleSearch}>Search</Button>
			</div>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="references"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				// isAction={true}
				dataHeader={tableReferenceHeader}
				params={params}
				filteredData={filteredData}
				totalPage={totalPage}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((data: IReference, key: number) => (
					<React.Fragment key={key}>
						<tr
							className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
							<TableRow>
								<h5 className="font-medium text-black dark:text-white">
									{data.id}
								</h5>
							</TableRow>
							<TableRow>
								<p className="text-black truncate dark:text-white">
									{data.email}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">{data.code}</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">{data.type}</p>
							</TableRow>
							<TableRow>
								{data.active.length > 0 && (
									<>
										<button
											className="text-black dark:text-white hover:text-primary ml-2 underline flex items-center"
											onClick={() => handleExpandRow(key)}>
											<span className="mr-2">List active</span>
											<span
												className={`rotate-0 ${
													expandedRows === key && "rotate-180"
												} duration-200`}>
												{Icons.ArrowDown()}
											</span>
										</button>
										{expandedRows === key ? (
											<div className="absolute shadow-lg bg-white z-99">
												<table className="w-full table-auto block max-h-[400px] overflow-auto">
													<thead>
														<tr className="bg-header text-left dark:bg-meta-4 lg:ml-8">
															<th className="font-medium text-white px-4">
																Id
															</th>
															<th className="font-medium text-white px-4">
																Email
															</th>
															<th className="font-medium text-white px-4">
																Uid
															</th>
														</tr>
													</thead>
													<tbody>
														{data.active.map((activeItem: any) => (
															<>
																<tr>
																	<TableRow>
																		<p>{activeItem.id}</p>
																	</TableRow>
																	<TableRow>
																		<p>{activeItem.email}</p>
																	</TableRow>
																	<TableRow>
																		<p>{activeItem.uid}</p>
																	</TableRow>
																</tr>
															</>
														))}
													</tbody>
												</table>
											</div>
										) : null}
									</>
								)}
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">{data.refSrc}</p>
							</TableRow>
							<TableRow>
								<p
									className="text-black truncate dark:text-white max-w-[400px]"
									title={data.linkShare}>
									{data.linkShare}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.createdAt)}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.updatedAt)}
								</p>
							</TableRow>
						</tr>
					</React.Fragment>
				))}
			</Table>
		</React.Fragment>
	);
};

export default Reference;
