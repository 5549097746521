import React from "react";

interface IInputProps {
	name: string;
	type: string;
	placeholder?: string;
	setFormData: (data: any) => void;
	formData: any;
	setErrors: (data: any) => void;
	errors: any;
	label?: string;
	disabled?: boolean;
	onChange?: (data: any) => void;
	rows?: number; // for textarea input only
	helperText?: string;
	options?: any;
}

const Input = ({
	name,
	type,
	formData,
	setFormData,
	errors,
	setErrors,
	placeholder,
	label,
	disabled,
	onChange,
	rows,
	helperText,
	options,
}: IInputProps) => {
	const handleInputChange = (e: any) => {
		e.preventDefault();
		const { value } = e.target;
		const field = e.target.type === "number" ? Number(value) : value;
		setFormData({ ...formData, [name]: field });
		setErrors({ ...errors, [name]: "" });
	};

	if (type === "textarea") {
		return (
			<div className="mb-4 ">
				<label className="mb-2 block font-bold text-black">{label}</label>
				<textarea
					disabled={disabled}
					name={name}
					placeholder={placeholder}
					value={formData[name] || ""}
					onChange={handleInputChange}
					rows={rows}
					className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
				/>
				{helperText && <p className="italic text-xs">{helperText}</p>}
				{errors[name] && (
					<p className="text-danger text-sm">{`${errors[name]} *`}</p>
				)}
			</div>
		);
	}

	if (type === "select") {
		return (
			<div className="w-full mb-4">
				<label className="mb-2 block font-bold text-black">Type model</label>
				<select
					disabled={disabled}
					className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
					value={formData[name]}
					onChange={(e: any) =>
						setFormData({ ...formData, typeModel: e.target.value })
					}>
					{options.map((option: any) => (
						<option
							disabled={option.disabled}
							key={option.value}
							value={option.value}>
							{option.name}
						</option>
					))}
				</select>
				{errors.typeModel && (
					<p className="text-danger text-sm">{errors.typeModel}</p>
				)}
			</div>
		);
	}

	return (
		<div className="mb-4">
			<label className="mb-2 block font-bold text-black">{label}</label>
			<input
				disabled={disabled}
				onChange={onChange || handleInputChange}
				autoComplete="off"
				type={type}
				min={0}
				value={formData[name]}
				placeholder={placeholder}
				className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 pr-10 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
			/>
			{helperText && <p className="italic text-xs">{helperText}</p>}
			{errors[name] && (
				<p className="text-danger text-sm">{`${errors[name]} *`}</p>
			)}
		</div>
	);
};

export default Input;
