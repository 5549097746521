import { logo, Icons } from "assets";
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { APP_ROOT } from "utils/routes";
interface SidebarProps {
	sidebarOpen: boolean;
	setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
	const dataMenu = [
		{ icon: Icons.IconDashBoard(), link: APP_ROOT.ROOT },
		{ icon: Icons.IconStatic(), link: APP_ROOT.SATATIC },
		{ icon: Icons.IconFeature(), link: APP_ROOT.FEATURE },
		{ icon: Icons.IconUser(), link: APP_ROOT.USER },
		{ icon: Icons.IconSettings(), link: APP_ROOT.SETTING },
	];
	const location = useLocation();
	const navigate = useNavigate();
	const [active, setActive] = useState("/");
	const sidebar = useRef<any>(null);

	const handleClick = (path: string) => {
		navigate(path);
	};
	useEffect(() => {
		setActive(location.pathname);
	}, [location.pathname]);

	return (
		<aside
			ref={sidebar}
			className={`absolute left-0 top-0 z-999 flex h-screen w-[150px] flex-col overflow-y-hidden bg-[#131313] duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
				sidebarOpen ? "translate-x-0" : "-translate-x-full"
			}`}>
			<div className="flex items-center justify-center mt-5 w-full">
				<NavLink to="/">
					<img src={logo} alt="Logo" className="h-[40px]" />
				</NavLink>
			</div>

			<div className=" w-full flex justify-center items-center flex-col pt-10">
				{dataMenu.map((item, index) => (
					<div
						key={index}
						className={`p-[8px] rounded-[12px] mt-5 cursor-pointer ${
							active === item?.link ? "bg-[#5B3E9B]" : "bg-[#303030]"
						}`}
						onClick={() => {
							handleClick(item?.link);
						}}>
						{item.icon}
					</div>
				))}
			</div>
		</aside>
	);
};

export default Sidebar;
