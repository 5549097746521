import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { EChartUser } from "types/chart";

const categories = [
	"2024-09-19T00:00:00.000Z",
	"2024-09-19T01:30:00.000Z",
	"2024-09-19T02:30:00.000Z",
	"2024-09-19T03:30:00.000Z",
	"2024-09-19T04:30:00.000Z",
	"2024-09-19T05:30:00.000Z",
	"2024-09-19T06:30:00.000Z",
];
interface LineChartState {
	series: {
		name: string;
		data: any[];
	}[];
}

interface ElineChart {
	data?: any;
}

const LineChartOnline: React.FC<ElineChart> = ({ data }) => {
	//////////////////////////////////////////////////////////////////
	const arrayCate: any = data;
	const dataCategories: any = [];
	if (arrayCate) {
		const items: any = arrayCate?.groupItems;
		for (const item of items) {
			dataCategories.push(item?.key);
		}
	}
	///////////////////////////////////////////////////////////////
	const [state, setState] = useState<LineChartState>({
		series: [
			{
				name: EChartUser.TOTAL_USER,
				data: [],
			},
		],
	});

	const [totalY, setTotalY] = useState(arrayCate?.maxDayTotal);
	useEffect(() => {
		setTotalY(arrayCate?.maxDayTotal);
	}, [data]);
	useEffect(() => {
		const totalUserOnline: any = [];

		if (arrayCate) {
			if (arrayCate?.title === EChartUser.USER_ONLINE) {
				for (const e of arrayCate.groupItems) {
					totalUserOnline.push(e?.value);
				}
			}
			setState({
				series: [
					{
						name: EChartUser.USER_ONLINE,
						data: totalUserOnline,
					},
				],
			});
		}
	}, [data]);
	const options: ApexOptions = {
		legend: {
			show: false,
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#575af4"],

		responsive: [
			{
				breakpoint: 1024,
				options: {
					chart: {
						height: 300,
					},
				},
			},
			{
				breakpoint: 1366,
				options: {
					chart: {
						height: 300,
					},
				},
			},
		],
		stroke: {
			curve: "smooth",
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			// colors: "#fff",
			strokeColors: ["#575af4"],
			// strokeWidth: 3,
			// strokeOpacity: 0.9,
			// strokeDashArray: 0,
			// fillOpacity: 1,
			discrete: [],
			hover: {
				size: undefined,
				sizeOffset: 5,
			},
		},
		xaxis: {
			type: "datetime",
			categories: dataCategories ?? categories,
		},
		yaxis: {
			min: 0,
			max: totalY ?? 500,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	};
	return (
		<div className="w-[69%] h-[600px] col-span-12 rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<p className="font-semibold text-[#FFFFFF] py-3">Online Chart</p>
			<div>
				<div id="lineChart" className="-ml-5">
					<ReactApexChart
						options={options}
						series={state.series}
						type="area"
						height={350}
					/>
				</div>
			</div>

			<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
				<div className="flex w-full flex-wrap gap-3 sm:gap-5">
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#575af4]" />
						<div className="w-full">
							<p className="font-semibold text-[#575af4]">User Online</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default LineChartOnline;
