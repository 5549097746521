import React, { FC, useEffect } from "react";
// import { LoadingPage } from "components";
import { useUser } from "hooks";
import i18next from "i18next";
import { MainLayout } from "components";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useNavigate,
} from "react-router-dom";
import {
	privateRoutes,
	privateRoutesMerchant,
	publicRoutes,
	RouterType,
} from "./routes";
import { NotFound } from "pages/admin";
import { PUBLIC_URL } from "utils/routes";

const RequireAuth: FC<{ children: React.ReactElement }> = ({ children }) => {
	const { isLogged } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		!isLogged && navigate(PUBLIC_URL.LOGIN);
	}, [isLogged, navigate]);

	if (!isLogged) {
		return null;
	}

	return <>{children}</>;
};

const App = () => {
	const { languages, uid, setUID, user } = useUser();

	const isMerchant = user?.merchantId ? true : false;

	useEffect(() => {
		if (languages.value) {
			i18next.changeLanguage(languages.value);
		}
	}, [languages.value]);

	useEffect(() => {
		if (user) {
			setUID(user.id);
			return;
		}
	}, [uid, user]);

	return (
		<Router basename="/">
			<React.Suspense fallback={<></>}>
				<Routes>
					{publicRoutes.map((route: RouterType, index: number) => {
						const Layout = route.layout;
						const Component = route.component;
						return (
							<Route
								key={index}
								path={route.path}
								element={
									<Layout>
										<Component />
									</Layout>
								}
							/>
						);
					})}

					{!isMerchant &&
						privateRoutes.map((route: RouterType, index: number) => {
							const Layout = route.layout || MainLayout;
							const Component = route.component;
							return (
								<Route
									key={`${index}-${route.path}`}
									path={route.path}
									element={
										<RequireAuth>
											<Layout>
												<Component />
											</Layout>
										</RequireAuth>
									}
								/>
							);
						})}

					{isMerchant &&
						privateRoutesMerchant.map((route: RouterType, index: number) => {
							const Layout = route.layout || MainLayout;
							const Component = route.component;
							return (
								<Route
									key={`${index}-${route.path}`}
									path={route.path}
									element={
										<RequireAuth>
											<Layout>
												<Component />
											</Layout>
										</RequireAuth>
									}
								/>
							);
						})}
					<Route path={"*"} element={<NotFound />} />
				</Routes>
			</React.Suspense>
		</Router>
	);
};

export default App;
