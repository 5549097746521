import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { useLayout } from "hooks";
import EditorDecs from "pages/admin/dashboard/component/EditorDecs";
import { Editor } from "pages/admin/settings/components";
import * as React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FormAddBlog } from "types";
import { BUTTON_TYPE, userSchema } from "utils";
import { ReactSummernoteLite } from "@easylogic/react-summernote-lite";
import EditorDecsVi from "pages/admin/dashboard/component/EditorDecsVi";
import EditorDecsKo from "pages/admin/dashboard/component/EditorDecsKo";

interface IModalBlog {
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading?: boolean;
}

const ModalAddBlog = ({ onClose, onConfirm, isLoading }: IModalBlog) => {
	const [image, setImage] = React.useState("");
	const [errors, setErrors] = React.useState({});
	const { openModalBlog, setOpenModalBlog } = useLayout();
	const editorRef = React.useRef<any>(null);
	const [formData, setFormData] = React.useState<FormAddBlog>({
		title: "",
		decsEn: "",
		decsKo: "",
		decsVi: "",
		summary: ""
	});
	const handleSubmit = React.useCallback(async () => {
		onConfirm(formData);
	}, [formData.title, formData.thumbnail, formData.decsEn, formData.decsKo, formData.decsVi, formData.summary]);

	React.useEffect(() => {
		setFormData({
			title: "",
			decsEn: "",
			decsKo: "",
			decsVi: "",
			summary: ""
		});
	}, [openModalBlog]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const f = event.target.files?.[0];
		if (f) {
			setFormData({ ...formData, thumbnail: f });
			const url = URL.createObjectURL(f);
			setImage(url);
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};
	return (
		<>
			{openModalBlog && (
				<div className="fixed left-0 top-0 z-999999 flex min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalBlog(false);
							setErrors({});
						}}>
						<div className="md:w-[1500px] sm:w-full h-[900px] overflow-y-auto text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Create new blogs
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Title"
									type="text"
									formData={formData}
									placeholder="Title"
									name="title"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Summary"
									type="text"
									formData={formData}
									placeholder="summary"
									name="summary"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Thumbnail
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="thumbnail-id"
											className="w-[50%] rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.thumbnail && (
											<LazyLoadImage
												src={image}
												className="h-[100px] w-[100px]"
											/>
										)}
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										English Description
									</label>
									<div className="w-[100%] h-[450px] flex items-center gap-2">
										<EditorDecs
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>
								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Korean Description
									</label>
									<div className="w-[100%] h-[450px] flex items-center gap-2">
										<EditorDecsKo
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>

								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black dark:text-white">
										Vietnamese Description
									</label>
									<div className="w-[100%] h-[450px] flex items-center gap-2">
										<EditorDecsVi
											disabled={false}
											editorRef={editorRef}
											formData={formData}
											setFormData={setFormData}
										/>
									</div>
								</div>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default ModalAddBlog;
