/** @format */

import * as yup from "yup";

const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const avatarSchema = yup.object().shape({
	name: yup
		.string()
		.min(3, "Name must be at least 3 characters")
		.required("Name is required"),
});

const loginSchema = yup.object().shape({
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),

	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	checked: yup.boolean(),
});

const registerSchema = yup.object().shape({
	userName: yup.string().required("Name is required"),
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),
	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), ""], "Passwords must match")
		.required("Confirm Password is required"),
	acceptPrivacy: yup
		.boolean()
		.oneOf([true], "You must accept the privacy policy"),
	subscribeEmail: yup.boolean(),
});

const dynamicSchema = (isOtpStep: boolean) =>
	yup.object().shape({
		email: isOtpStep
			? yup.string().matches(regexEmail, "Invalid email format")
			: yup
					.string()
					.matches(regexEmail, "Invalid email format")
					.required("Email is required"),
		otp: isOtpStep
			? yup
					.string()
					.length(6, "OTP must be exactly 6 digits")
					.required("OTP is required")
			: yup.string().notRequired(),
	});

const resetPassSchema = yup.object().shape({
	newPassword: yup
		.string()
		.min(8, "New password must be at least 8 characters")
		.required("New password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("newPassword"), ""], "Passwords must match")
		.required("Confirm Password is required"),
});

const updatePassSchema = yup.object().shape({
	oldPassword: yup.string().required("Old password is required"),
	newPassword: yup
		.string()
		.min(8, "New password must be at least 8 characters")
		.required("New Password is required"),
});

const profileSchema = yup.object().shape({
	userName: yup.string().required("User name must be not empty"),
	avatar: yup.string().notRequired(),
	email: yup.string().notRequired(),
});

const otpSchema = yup.object().shape({
	otp: yup
		.string()
		.required("Otp is required")
		.length(6, "OTP must be exactly 6 characters"),
});

const customPlanSchema = yup.object().shape({
	company: yup.string().required("Company is required"),
	firstName: yup.string().required("FirstName is required"),
	message: yup.string().required("Message is required"),
	email: yup
		.string()
		.email("Invalid email format")
		.required("Email is required"),
});

const createTemplateSchema = yup.object().shape({
	assistantName: yup.string().required("Assistant name must be not empty"),
});

const renameSchema = yup.object().shape({
	name: yup.string().required("Assistant name is required"),
});

const userSchema = yup.object({
	name: yup.string().required("Name is required"),
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),
	password: yup
		.string()
		.min(8, "Password must be at least 8 characters")
		.required("Password is required"),
	// confirmPassword: yup
	// 	.string()
	// 	.oneOf([yup.ref("password"), ""], "Passwords must match")
	// 	.required("Confirm Password is required"),
});

const referralSchema = yup.object({
	code: yup.string().required("Code is required"),
	type: yup.string().required("Type is required"),
	active: yup.string().required("Active is required"),
});

const notiSchema = yup.object({
	title: yup.string().required("Title is required"),
	content: yup.string().required("Content is required"),
	hoursToAdd: yup.string().required("Hours to add is required"),
});

const addCreditSchema = yup.object({
	email: yup
		.string()
		.matches(regexEmail, "Invalid email format")
		.required("Email is required"),
	credit: yup
		.number()
		.transform((value) => (isNaN(value) ? undefined : value))
		.required("Credits is required"),
});

const categorySchema = yup.object({
	name: yup.string().required("Name is required"),
	describe: yup.string().required("Describe is required"),
});

const clothesSchema = yup.object({
	name: yup.string().required("Name is required"),
	categoryId: yup.string().required("Category is required"),
});

const planSchema = yup.object({
	packageName: yup.string().required("Package name is required"),
	subTitle: yup.string().required("Sub Title is required"),
	price: yup.string().required("Price is required"),
	unit: yup.string().required("Unit is required"),
	priceSale: yup.string().required("Price sale is required"),
	description: yup.string().required("Description is required"),
	sortType: yup.string().required("Sort type is required"),
});

const hairStyleSchema = yup.object({
	name: yup.string().required("Name is required"),
});

const genMeSchema = yup.object({
	name: yup.string().required("Name is required"),
});

const modelBodySchema = yup.object({
	name: yup.string().required("Name is required"),
	typeModel: yup.string().required("Type model is required"),
});

const animationAnyoneSchema = yup.object({
	name: yup.string().required("Name is required"),
});

export {
	dynamicSchema,
	loginSchema,
	avatarSchema,
	otpSchema,
	profileSchema,
	registerSchema,
	resetPassSchema,
	updatePassSchema,
	customPlanSchema,
	createTemplateSchema,
	renameSchema,
	userSchema,
	referralSchema,
	notiSchema,
	addCreditSchema,
	categorySchema,
	clothesSchema,
	planSchema,
	hairStyleSchema,
	genMeSchema,
	modelBodySchema,
	animationAnyoneSchema,
};
