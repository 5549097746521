/** @format */

import { IColorDefault, IFontSize } from "types/theme";

export const defaultColorTheme: IColorDefault = {
	primary: "#1076BB",
	secondary: "#21303f",
	warning: "#FFCC00",
	success: "#3CD4A0",
	info: "#9013FE",
	error: "#750606",
	grey: "#ECECEC",
	mainBlack: "#000000",
	textMain: "#808080",
	textPurple: "#21065D",
	textD8: "#D8D8D8",
	bgCountdownTimer: "#33333380",
	iconBlue: "#5271FF",
	iconDarkBlue: "#21065D",
	iconGray: "#9A9A9E",
	iconBlack: "#121219",
	shadowColor: "#7340D84D",
	buttonBlueLight: "#48D8E4",
	bgSelected: "#7340D81A",
};

export const mainColor: IColorDefault = {
	primary: "#FFFFFF",
	secondary: "#7340D8",
	warning: "#FFCC00",
	success: "#3CD4A0",
	info: "#9013FE",
	error: "#750606",
	grey: "#F5F5F5",
	mainBlack: "#000000",
	textMain: "#3A3A3C",
	textGray: "#858688",
	borderColor: "#ADADAD",
	bgCountdownTimer: "#33333380",
	iconBlue: "#5271FF",
	iconDarkBlue: "#21065D",
	iconGray: "#9A9A9E",
	iconBlack: "#121219",
	shadowColor: "#7340D84D",
	buttonBlueLight: "#48D8E4",
	bgSelected: "#7340D81A",
};

export const fontSize: IFontSize = {
	ss: "12px",
	xs: "14px",
	sm: "16px",
	md: "20px",
	// xl: '25px',
	xl: "24px",
	xxl: "48px",
	"6xl": "144px",
};
