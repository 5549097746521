import React, { ReactNode } from "react";
import { BUTTON_TYPE } from "utils";

interface IButton {
	onClick: () => void;
	children?: ReactNode;
	variant?: string;
	className?: string;
	disabled?: boolean;
}

const Button = ({
	onClick,
	children,
	variant,
	className,
	disabled,
}: IButton) => {
	const buttonStyle = `${
		variant !== BUTTON_TYPE.OUTLINED
			? "inline-flex items-center justify-center h-[42px] rounded-[8px] p-3 text-center font-medium text-sm text-white hover:bg-opacity-90 lg:px-8 xl:px-5 disabled:bg-gray-400 disabled:border-none bg-[#5B3E9B]"
			: "inline-flex items-center justify-center h-[42px] rounded-[8px] p-3 text-center font-medium text-sm hover:opacity-[0.7] lg:px-8 xl:px-5 text-[#5B3E9B] disabled:bg-gray-400 disabled:border-none border-[1px] border-[#5B3E9B]"
	} ${className || ""}`;

	return (
		<button
			onClick={onClick}
			type="button"
			className={buttonStyle}
			disabled={disabled}>
			{children}
		</button>
	);
};

export default Button;
