import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { EChartUser } from "types/chart";

const categories = [
	"2024-09-19T00:00:00.000Z",
	"2024-09-19T01:30:00.000Z",
	"2024-09-19T02:30:00.000Z",
	"2024-09-19T03:30:00.000Z",
	"2024-09-19T04:30:00.000Z",
	"2024-09-19T05:30:00.000Z",
	"2024-09-19T06:30:00.000Z",
];

interface LineChartState {
	series: {
		name: string;
		data: number[];
	}[];
}

interface ElineChart {
	data?: any;
}

const LineChartPlan: React.FC<ElineChart> = ({ data }) => {
	//////////////////////////////////////////////////////////////////
	const arrayCate: any = data?.[0];
	const dataCategories: any = [];

	if (arrayCate) {
		const items: any = arrayCate?.groupItems;
		for (const item of items) {
			dataCategories.push(item?.key);
		}
	}
	///////////////////////////////////////////////////////////////

	const [state, setState] = useState<LineChartState>({
		series: [
			{
				name: arrayCate?.title,
				data: [],
			},
		],
	});
	const [totalY, setTotalY] = useState(arrayCate?.maxDayTotal);
	useEffect(() => {
		const free: any = [];

		if (data?.length > 0) {
			for (const item of data) {
				const items: any = item?.groupItems;
				for (const e of items) {
					free.push(e?.value);
				}
			}

			setState({
				series: [
					{
						name: `${arrayCate?.title}`,
						data: free,
					},
				],
			});
		}
	}, [data]);

	const options: ApexOptions = {
		legend: {
			show: false,
			position: "top",
			horizontalAlign: "left",
		},
		colors: ["#5271FF"],

		responsive: [
			{
				breakpoint: 1024,
				options: {
					chart: {
						height: 300,
					},
				},
			},
			{
				breakpoint: 1366,
				options: {
					chart: {
						height: 300,
					},
				},
			},
		],
		stroke: {
			curve: "smooth",
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			// colors: "#fff",
			strokeColors: ["#5271FF"],
			// strokeWidth: 3,
			// strokeOpacity: 0.9,
			// strokeDashArray: 0,
			// fillOpacity: 1,
			discrete: [],
			hover: {
				size: undefined,
				sizeOffset: 5,
			},
		},
		xaxis: {
			// type: 'datetime',
			type: "datetime",
			categories: dataCategories ?? categories,
		},
		yaxis: {
			min: 0,
			max: totalY ?? 500,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	};

	const handleReset = () => {
		setState((prevState) => ({
			...prevState,
		}));
	};
	handleReset;

	return (
		<div className="w-[69%] h-[550px] col-span-12 rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<p className="font-semibold text-[#ffffff] py-3">
				Chart Plan <span className="text-[#5074eb]">{arrayCate?.title}</span>{" "}
				User
			</p>
			<div>
				<div id="lineChart" className="-ml-5">
					<ReactApexChart
						options={options}
						series={state.series}
						type="area"
						height={350}
						colors={"#000000"}
					/>
				</div>
			</div>
			{/* <div className="w-full mt-5 flex flex-wrap">
				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#666]" />
							<div className="w-full">
								<p className="font-semibold text-[#666]">Free</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#7BE2FD]" />
							<div className="w-full">
								<p className="font-semibold text-[#7BE2FD]">Entry</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF52F0]" />
							<div className="w-full">
								<p className="font-semibold text-[#FF52F0]">Pro</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#5271FF]" />
							<div className="w-full">
								<p className="font-semibold text-[#5271FF]">Premium</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#98DD0D]" />
							<div className="w-full">
								<p className="font-semibold text-[#98DD0D]">Enterprise</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#F45772]" />
							<div className="w-full">
								<p className="font-semibold text-[#F45772]">Monthly</p>
							</div>
						</div>
					</div>
				</div>

				<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
					<div className="flex w-full flex-wrap gap-3 sm:gap-5">
						<div className="flex min-w-47.5 justify-center items-center">
							<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF9D00]" />
							<div className="w-full">
								<p className="font-semibold text-[#FF9D00]">Yearly</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</div>
	);
};
export default LineChartPlan;
