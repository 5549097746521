import { banner } from "assets";
import { useUser } from "hooks";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = () => {
	const { user } = useUser();

	return (
		<div className="w-full pb-10 relative bg-slate-50">
			<LazyLoadImage src={banner} className="object-cover w-full h-[232px]" />
			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
				<h1 className="font-bold text-whiter text-title-lg uppercase">
					Shop of {user ? user.email.split("@")[0] : "Name"}
				</h1>
			</div>
		</div>
	);
};

export default Banner;
