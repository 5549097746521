import React, { useCallback, useEffect, useState } from "react";
import { useTransaction } from "hooks";
import {
	EIllunimusEmail,
	ETypeSearch,
	ITransactionCredit,
	TParams,
} from "types";
import {
	BUTTON_TYPE,
	formatDMY,
	typeChargeOption,
	searchTransactionCreditOption,
	tableTransactionCreditHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Button } from "components/Button";
import { Search } from "components/Search";
import { Dropdown, Table, TableRow } from "components";
import { Icons } from "assets";
import { ExcelExport } from "components/ExcelExport";

const TransactionCredit = () => {
	const { onGetTransactionCredit, onExportTransactionCredit } =
		useTransaction();
	const [params, setParams] = useState<TParams>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});
	const { dataTransactionCredit, isLoading, refetch, totalPage } =
		onGetTransactionCredit({
			...params,
		});

	//Export Transaction Credit
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportTransactionCredit({
			...paramsExport,
		});

	const [filteredData, setFilteredData] = useState(dataTransactionCredit);
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataTransactionCredit);
	}, [searchValue, searchOption, params]);

	const handleResetSearch = () => {
		if (searchValue === "") return;
		setSearchValue("");
	};

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataTransactionCredit);
	}, [dataTransactionCredit]);

	// const onExportToCSV = () => {
	// 	return;
	// };

	return (
		<div>
			<h1 className="font-bold text-xl mb-4 ">Transaction credit management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2  items-center">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchTransactionCreditOption}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				{searchOption === ETypeSearch.ID ||
				searchOption === ETypeSearch.EMAIL ? (
					<Search
						value={searchValue}
						onChange={(e: any) => setSearchValue(e.target.value)}
						placeholder="Search by ID, Email"
					/>
				) : null}
				{searchOption === ETypeSearch.TYPE_CHARGE && (
					<Dropdown
						options={typeChargeOption}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				)}
				<Button onClick={handleSearch}>Search</Button>
				{searchOption === ETypeSearch.TYPE_CHARGE && (
					<div title="Reset Search">
						<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
							{Icons.Reload()}
						</Button>
					</div>
				)}
			</div>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="transaction-credit"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				dataHeader={tableTransactionCreditHeader}
				params={params}
				filteredData={filteredData}
				totalPage={totalPage}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((data: ITransactionCredit, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<TableRow>
							<h5
								title={data.id}
								className="w-[100px] truncate font-medium text-black ">
								{data.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5
								className={`font-medium text-black ${
									data?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{data.email}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black ">{data.typeCharge}</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black ">{data.totalRedisUsed}</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black ">
								{data.totalRedisCurrent}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black ">{formatDMY(data.createdAt)}</p>
						</TableRow>
						<TableRow>
							<p className="text-black ">{formatDMY(data.updatedAt)}</p>
						</TableRow>
					</tr>
				))}
			</Table>
		</div>
	);
};

export default TransactionCredit;
