import { Icons } from "assets";
import { Button } from "components";
import { useSetting } from "hooks/useSetting";
import React, { useEffect, useState } from "react";
import {
	ESettingKeys,
	ETypePlan,
	ETypeSearch,
	FormSettings,
	TParamsSearch,
} from "types";
import { BUTTON_TYPE } from "utils";

const PlanCredit = () => {
	const { onGetSetting, editSettingMutation } = useSetting();
	const params: TParamsSearch = {
		page: 1,
		limit: 10,
		search: ESettingKeys.PLANS_CREDIT,
		type: ETypeSearch.KEY,
	};
	const [formData, setFormData] = useState<FormSettings>({
		settingId: "",
		key: "",
		value: {
			FREE: 0,
			PRO: 0,
			ENTERPRISE: "",
			ENTRY: 0,
			PREMIUM: 0,
		},
	});

	const isLoadingEdit = editSettingMutation.isPending;

	const isButtonDisabled = Object.values(formData.value).some(
		(field) => field === "",
	);

	const { settingData, isLoading } = onGetSetting({
		...params,
	});

	useEffect(() => {
		settingData &&
			setFormData({
				settingId: settingData[0].id,
				key: settingData[0].key,
				value: settingData[0].value,
			});
	}, [settingData]);

	const handleReset = () => {
		setFormData({
			settingId: settingData[0].id,
			key: settingData[0].key,
			value: settingData[0].value,
		});
	};

	const handleSubmit = () => {
		editSettingMutation.mutate(formData);
	};

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Plans Credit Setting</h1>
			{isLoading ? (
				<p>Loading...</p>
			) : (
				<>
					{settingData && settingData.length > 0 ? (
						<div className="w-full md:max-w-2xl">
							{settingData &&
								Object.entries(settingData[0].value).map(([key]) => (
									<div key={key} className="md:flex items-center mb-6 ">
										<div className="md:w-1/3">
											<label className="block font-bold md:text-left mb-1 md:mb-0 pr-4">
												{key}
											</label>
										</div>
										<div className="group md:w-2/3 relative">
											<input
												type={key === ETypePlan.ENTERPRISE ? "text" : "number"}
												min={0}
												disabled={isLoadingEdit}
												className="border focus:outline-none rounded w-full py-2 px-4 focus:border-primary"
												value={formData.value[key]}
												onChange={(e: any) =>
													setFormData({
														...formData,
														value: {
															...formData.value,
															[key]:
																key !== ETypePlan.ENTERPRISE
																	? Number(e.target.value)
																	: e.target.value,
														},
													})
												}
											/>
										</div>
									</div>
								))}
							<div className="mb-4 flex justify-end gap-1">
								<div>
									<Button
										variant={BUTTON_TYPE.OUTLINED}
										disabled={isLoadingEdit}
										onClick={handleReset}
										className="w-[100px]">
										Reset
									</Button>
								</div>
								<div>
									<Button
										disabled={isLoadingEdit || isButtonDisabled}
										onClick={handleSubmit}
										className="w-[100px] flex items-center gap-2">
										Confirm {isLoadingEdit && <span>{Icons.Spinner()}</span>}
									</Button>
								</div>
							</div>
						</div>
					) : (
						<p>No data found</p>
					)}
				</>
			)}
		</React.Fragment>
	);
};

export default PlanCredit;
