import React, { useCallback, useEffect, useState } from "react";
import { useDashboard, useLayout } from "hooks";
import { Icons } from "assets";
import { ETypeSearch, IGallery, TParams } from "types";
import { ModalDelete } from "components/Modal";
import {
	BUTTON_TYPE,
	formatDMY,
	searchGalleryOption,
	serviceNameOption,
	tableGalleryHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Button } from "components/Button";
import { Search } from "components/Search";
import { Dropdown, Table, TableRow } from "components";
import { ExcelExport } from "components/ExcelExport";

const Gallery = () => {
	const { onGetGallery, deleteGalleryMutation, onExportGallery } =
		useDashboard();
	const { setOpenModalDelete } = useLayout();
	const [params, setParams] = useState<TParams>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});
	const { dataGallery, isLoading, refetch, totalPage } = onGetGallery({
		...params,
	});
	//Export Galerry
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportGallery({
			...paramsExport,
		});

	const [item, setItem] = useState<any>();
	const [filteredData, setFilteredData] = useState(dataGallery);
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataGallery);
	}, [searchValue, searchOption, params]);

	const handleResetSearch = () => {
		if (searchValue === "") return;
		setSearchValue("");
	};

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataGallery);
	}, [dataGallery]);

	const onDeleteGallery = useCallback(
		(id: string) => {
			deleteGalleryMutation.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	// const onExportToCSV = () => {
	// 	return;
	// };

	return (
		<div>
			<h1 className="font-bold text-xl mb-4 ">Gallery management</h1>
			{/* <Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => setOpenModalUser(true)}>
				<span>{Icons.PlusWhite()}</span>
				Add new gallery
			</Button> */}
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 ">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchGalleryOption}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				{searchOption === ETypeSearch.ID ||
				searchOption === ETypeSearch.NAME ||
				searchOption === ETypeSearch.STATUS ? (
					<Search
						value={searchValue}
						onChange={(e: any) => setSearchValue(e.target.value)}
						placeholder="Search by ID, Name, Status"
					/>
				) : null}
				{searchOption === ETypeSearch.SERVICE_NAME && (
					<Dropdown
						options={serviceNameOption}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				)}
				<Button onClick={handleSearch}>Search</Button>
				{searchOption === ETypeSearch.SERVICE_NAME && (
					<div title="Reset Search">
						<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
							{Icons.Reload()}
						</Button>
					</div>
				)}
			</div>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="gallery"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				// isAction={true}
				dataHeader={tableGalleryHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((gallery: IGallery, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						{/* <TableRow>
							<div className="flex items-center space-x-3.5">
								<button
												onClick={() => {
													setItem(gallery);
													setAction(FORM_ACTION.EDIT);
												}}
												className="hover:text-primary">
												{Icons.Edit()}
											</button>
								<button
									onClick={() => {
										setOpenModalDelete(true);
										setItem(gallery);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</TableRow> */}
						<TableRow>
							<h5 title={gallery.id} className="w-[100px] truncate text-black">
								{gallery.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="text-black">{gallery.email}</h5>
						</TableRow>
						<TableRow>
							<h5 className="text-black">{gallery.name}</h5>
						</TableRow>
						<TableRow>
							<LazyLoadImage className="w-[42px] h-auto" src={gallery.image} />
						</TableRow>
						<TableRow>
							<video src={gallery.video} className="w-[42px] h-[42px]" />
						</TableRow>
						<TableRow>
							<h5 className="text-black">{gallery.serviceName}</h5>
						</TableRow>
						<TableRow>
							<h5 title={gallery.id} className="w-[100px] truncate text-black">
								{gallery.modelId}
							</h5>
						</TableRow>
						<TableRow>
							<h5
								title={gallery.galleryModel}
								className="w-[100px] truncate text-black">
								{gallery.galleryModel}
							</h5>
						</TableRow>
						<TableRow>
							<h5
								title={gallery.serviceId}
								className="w-[100px] truncate text-black">
								{gallery.serviceId}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black">{gallery.status}</p>
						</TableRow>
						<TableRow>
							<LazyLoadImage
								className="w-[42px] h-auto"
								src={gallery.imageWaterMask}
							/>
						</TableRow>
						<TableRow>
							<h5 className="text-black">{gallery.categoryId}</h5>
						</TableRow>
						<TableRow>
							<p className="text-black">{formatDMY(gallery.createdAt)}</p>
						</TableRow>
						<TableRow>
							<p className="text-black">{formatDMY(gallery.updatedAt)}</p>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteGallery}
			/>
		</div>
	);
};

export default Gallery;
