import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { EChartUser } from "types/chart";

const categories = [
	"2024-09-19T00:00:00.000Z",
	"2024-09-19T01:30:00.000Z",
	"2024-09-19T02:30:00.000Z",
	"2024-09-19T03:30:00.000Z",
	"2024-09-19T04:30:00.000Z",
	"2024-09-19T05:30:00.000Z",
	"2024-09-19T06:30:00.000Z",
];
interface LineChartState {
	series: {
		name: string;
		data: any[];
	}[];
}

interface ElineChart {
	data?: any;
}

const LineChart: React.FC<ElineChart> = ({ data }) => {
	//////////////////////////////////////////////////////////////////
	const arrayCate: any = data?.[0];
	const dataCategories: any = [];

	if (arrayCate) {
		const items: any = arrayCate?.groupItems;
		for (const item of items) {
			dataCategories.push(item?.key);
		}
	}
	///////////////////////////////////////////////////////////////

	const [state, setState] = useState<LineChartState>({
		series: [
			{
				name: EChartUser.TOTAL_USER,
				data: [],
			},

			{
				name: EChartUser.VERIFIED_USER,
				data: [],
			},

			{
				name: EChartUser.FREE_USER,
				data: [],
			},
			{
				name: EChartUser.PAID_USER,
				data: [],
			},
			{
				name: EChartUser.FAILED_REGISTRATION,
				data: [],
			},
			{
				name: EChartUser.ACTIVE_USER,
				data: [],
			},
			{
				name: EChartUser.INACTIVE_USER,
				data: [],
			},
			{
				name: EChartUser.CHECKOUT,
				data: [],
			},
			{
				name: EChartUser.CANCEL_PLAN,
				data: [],
			},
		],
	});

	const [totalY, setTotalY] = useState(arrayCate?.maxDayTotal);
	useEffect(() => {
		const totalUser: any = [];
		const verifiedUser: any = [];
		const freeUser: any = [];
		const paidUser: any = [];
		const failedRegistration: any = [];
		const activeUser: any = [];
		const inactiveUser: any = [];
		const checkout: any = [];
		const cancelPlan: any = [];

		if (data?.length > 0) {
			for (const item of data) {
				const items: any = item?.groupItems;
				for (const e of items) {
					if (item?.title === EChartUser.TOTAL_USER) {
						totalUser.push(e?.value);
					}
					if (item?.title === EChartUser.VERIFIED_USER) {
						verifiedUser.push(e?.value);
					}
					if (item?.title === EChartUser.FREE_USER) {
						freeUser.push(e?.value);
					}
					if (item?.title === EChartUser.PAID_USER) {
						paidUser.push(e?.value);
					}
					if (item?.title === EChartUser.FAILED_REGISTRATION) {
						failedRegistration.push(e?.value);
					}
					if (item?.title === EChartUser.ACTIVE_USER) {
						activeUser.push(e?.value);
					}
					if (item?.title === EChartUser.INACTIVE_USER) {
						inactiveUser.push(e?.value);
					}
					if (item?.title === EChartUser.CHECKOUT) {
						checkout.push(e?.value);
					}
					if (item?.title === EChartUser.CANCEL_PLAN) {
						cancelPlan.push(e?.value);
					}
				}
			}
			setState({
				series: [
					{
						name: EChartUser.TOTAL_USER,
						data: totalUser,
					},
					{
						name: EChartUser.VERIFIED_USER,
						data: verifiedUser,
					},
					{
						name: EChartUser.FREE_USER,
						data: freeUser,
					},
					{
						name: EChartUser.PAID_USER,
						data: paidUser,
					},
					{
						name: EChartUser.FAILED_REGISTRATION,
						data: failedRegistration,
					},
					{
						name: EChartUser.ACTIVE_USER,
						data: activeUser,
					},
					{
						name: EChartUser.INACTIVE_USER,
						data: inactiveUser,
					},
					{
						name: EChartUser.CHECKOUT,
						data: checkout,
					},
					{
						name: EChartUser.CANCEL_PLAN,
						data: cancelPlan,
					},
				],
			});
		}
	}, [data]);

	const options: ApexOptions = {
		legend: {
			show: false,
			position: "top",
			horizontalAlign: "left",
		},
		colors: [
			"#98DD0D",
			"#FF52F0",
			"#FF9D00",
			"#575af4",
			"#F45772",
			"#57eaf4",
			"#a057f4",
			"#f1ef6f",
			"#f8d7b3",
		],

		responsive: [
			{
				breakpoint: 1024,
				options: {
					chart: {
						height: 300,
					},
				},
			},
			{
				breakpoint: 1366,
				options: {
					chart: {
						height: 300,
					},
				},
			},
		],
		stroke: {
			curve: "smooth",
		},
		grid: {
			xaxis: {
				lines: {
					show: false,
				},
			},
			yaxis: {
				lines: {
					show: true,
				},
			},
		},
		dataLabels: {
			enabled: false,
		},
		markers: {
			size: 0,
			// colors: "#fff",
			strokeColors: [
				"#98DD0D",
				"#FF52F0",
				"#FF9D00",
				"#575af4",
				"#F45772",
				"#57eaf4",
				"#a057f4",
				"#f1ef6f",
				"#f8d7b3",
			],
			// strokeWidth: 3,
			// strokeOpacity: 0.9,
			// strokeDashArray: 0,
			// fillOpacity: 1,
			discrete: [],
			hover: {
				size: undefined,
				sizeOffset: 5,
			},
		},
		xaxis: {
			type: "datetime",
			categories: dataCategories ?? categories,
		},
		yaxis: {
			min: 0,
			max: totalY ?? 500,
		},
		tooltip: {
			x: {
				format: "dd/MM/yy HH:mm",
			},
		},
	};
	return (
		<div className="w-[69%] h-[600px] col-span-12 rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:col-span-8">
			<p className="font-semibold text-[#FFFFFF] py-3">User Chart</p>
			<div>
				<div id="lineChart" className="-ml-5">
					<ReactApexChart
						options={options}
						series={state.series}
						type="area"
						height={350}
					/>
				</div>
			</div>

			<div className="flex flex-wrap items-start justify-between gap-3 sm:flex-nowrap">
				<div className="flex w-full flex-wrap gap-3 sm:gap-5">
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#98DD0D]" />
						<div className="w-full">
							<p className="font-semibold text-[#98DD0D]">Total User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF52F0]" />
						<div className="w-full">
							<p className="font-semibold text-[#FF52F0]">Verified User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#FF9D00]" />
						<div className="w-full">
							<p className="font-semibold text-[#FF9D00]">Free User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#575af4]" />
						<div className="w-full">
							<p className="font-semibold text-[#575af4]">Paid User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#F45772]" />
						<div className="w-full">
							<p className="font-semibold text-[#F45772]">
								Failed Registration
							</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#57eaf4]" />
						<div className="w-full">
							<p className="font-semibold text-[#57eaf4]">Active User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#a057f4]" />
						<div className="w-full">
							<p className="font-semibold text-[#a057f4]">Inactive User</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#f1ef6f]" />
						<div className="w-full">
							<p className="font-semibold text-[#f1ef6f]">Checkout</p>
						</div>
					</div>
					<div className="flex min-w-47.5 justify-center items-center">
						<span className="mt-1 mr-2 flex h-2 w-[30px] items-center justify-center rounded-full bg-[#f8d7b3]" />
						<div className="w-full">
							<p className="font-semibold text-[#f8d7b3]">Cancel Plan</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default LineChart;
