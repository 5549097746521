import { Icons } from "assets";
import ClickOutside from "components/Header/ClickOutside";
import { ModalCategory } from "components/Modal";
import { useLayout, useMerchant, useUser } from "hooks";
import React, { FC, memo, useCallback, useEffect, useState } from "react";
import { mainColor } from "theme";
import { FormCategory, FormClothes } from "types";
import { FORM_ACTION } from "utils";

interface ISelectCategoryProps {
	typeModalOptions: any[];
	typeOutfitOptions: any[];
	formData: FormClothes;
	icon?: React.ReactElement;
	className?: string;
	setFormData: (v: any) => void;
	isAdd?: boolean;
	errors: any;
	setErrors: (v: any) => void;
	name: string; //for validattion
	disabled?: boolean;
}

const SelectCategory: FC<ISelectCategoryProps> = ({
	typeModalOptions,
	typeOutfitOptions,
	formData,
	setFormData,
	errors,
	setErrors,
	icon,
	name,
	disabled,
}) => {
	const { onGetCategory, createCategoryMutation } = useMerchant();
	const { user } = useUser();
	const { setOpenModalCategory } = useLayout();
	const { categoryData, isLoading } = onGetCategory(user?.merchantId || "");

	const [filteredCategoryData, setFilteredCategoryData] = useState<any[]>();
	const [showSelectCategory, setShowSelectCategory] = useState(false);
	const [categoryName, setCategoryName] = useState<any>({});

	useEffect(() => {
		const typeModalItem = typeModalOptions.find(
			(option) => option.value === formData?.typeModel,
		);
		const typeOutfitItem = typeOutfitOptions.find(
			(option) => option.value === formData?.typeOutfit,
		);
		const categoryItem = categoryData?.find(
			(c: any) => c.name === formData?.typeModel,
		);

		setCategoryName({
			...categoryName,
			[formData.typeModel]: typeModalItem?.name,
			[formData.typeOutfit]: typeOutfitItem?.name,
			[formData.categoryId]: categoryItem?.categoryChild.find(
				(option: any) => option.categoryId === formData?.categoryId,
			)?.name,
		});

		handleFilterCategoryByTypeModal(typeModalItem);
	}, [
		formData.typeModel,
		formData.typeOutfit,
		formData.categoryId,
		categoryData,
	]);

	const handleFilterCategoryByTypeModal = useCallback(
		(item: any) => {
			const filteredData = categoryData?.filter(
				(category: any) => category.name === item.value,
			);
			setFilteredCategoryData(filteredData);
		},
		[categoryData, filteredCategoryData],
	);

	const onCreateCategory = (formCategory: FormCategory) => {
		createCategoryMutation.mutate(formCategory, {
			onSuccess: () => {
				setOpenModalCategory(false);
			},
		});
	};

	return (
		<React.Fragment>
			<ClickOutside onClick={() => setShowSelectCategory(false)}>
				<button
					type="button"
					disabled={disabled || isLoading}
					onClick={() => setShowSelectCategory((prev) => !prev)}
					className={`relative w-full rounded-lg border-[1.5px] bg-transparent py-3 pl-4 pr-4 text-black outline-none transition focus:border-primary active:border-primary select-none  disabled:cursor-default disabled:bg-whiter flex items-center justify-between cursor-pointer ${
						showSelectCategory ? "border border-primary" : "border-stroke"
					}`}>
					{isLoading ? (
						<div>
							<span className="w-4 h-4">
								{Icons.Spinner(mainColor.iconBlue)}
							</span>
							Loading...
						</div>
					) : (
						<>
							{formData.typeModel && formData.typeOutfit && formData.categoryId
								? `${categoryName[formData.typeModel] || ""} > ${
										categoryName[formData.typeOutfit] || ""
										// eslint-disable-next-line no-mixed-spaces-and-tabs
								  } > ${categoryName[formData.categoryId] || ""}`
								: "Select category"}
						</>
					)}
					<div
						className={`text-right transition duration-200 ${
							showSelectCategory ? "rotate-180" : "rotate-0"
						}`}>
						{Icons.ArrowDown()}
					</div>
				</button>

				{errors[name] && (
					<div className="text-danger text-sm">{`${errors[name]} *`}</div>
				)}
				{showSelectCategory && isLoading && (
					<div
						id="selectCategoryUsers"
						className="z-99 absolute bg-white rounded-lg shadow w-full">
						<div className="flex h-[260px] justify-center items-center">
							{Icons.CircleLoading(mainColor.iconBlue)}
						</div>
					</div>
				)}
				{showSelectCategory && !isLoading && (
					<div
						id="selectCategoryUsers"
						className="z-99 absolute bg-white rounded-lg shadow w-full">
						<div className="flex h-[260px]">
							<ul
								className="h-full w-full border-r border-slate-300 py-2 overflow-y-auto text-gray-700"
								aria-labelledby="selectCategoryUsersButton">
								{typeModalOptions.map((item, index: number) => (
									<li key={index}>
										<button
											type="button"
											disabled={item.disabled}
											className={`flex items-center gap-2 px-4 py-2 hover:bg-slate-100 w-full disabled:text-slate-300 select-none disabled:hover:bg-transparent ${
												item.value === formData.typeModel
													? "bg-slate-100 disabled:bg-transparent"
													: ""
											}`}
											onClick={() => {
												setFormData({ ...formData, typeModel: item.value });
												setCategoryName({
													...categoryName,
													[item.value]: item.name,
												});
												handleFilterCategoryByTypeModal(item);
											}}>
											{!item.disabled && icon && icon}
											<span>{item.name}</span>
										</button>
									</li>
								))}
							</ul>
							<ul
								className=" h-full w-full border-r border-slate-300 py-2 overflow-y-auto text-gray-700"
								aria-labelledby="selectCategoryUsersButton">
								{typeOutfitOptions.map((item, index: number) => (
									<li key={index}>
										<button
											type="button"
											disabled={item.disabled}
											className={`flex items-center gap-2 px-4 py-2 hover:bg-slate-100 w-full disabled:text-slate-300 select-none disabled:hover:bg-transparent ${
												item.value === formData.typeOutfit
													? "bg-slate-100 disabled:bg-transparent"
													: ""
											}`}
											onClick={() => {
												setFormData({ ...formData, typeOutfit: item.value });
												setCategoryName({
													...categoryName,
													[item.value]: item.name,
												});
											}}>
											{!item.disabled && icon && <>{icon}</>}
											<span>{item.name}</span>
										</button>
									</li>
								))}
							</ul>
							{formData.typeModel && formData.typeOutfit ? (
								<div className=" h-full w-full flex flex-col justify-between">
									<ul
										className=" h-full w-full py-2 overflow-y-auto text-gray-700 "
										aria-labelledby="childSelectCategoryUsersButton">
										<button
											type="button"
											disabled
											className="flex items-center gap-2 px-4 py-2 hover:bg-slate-100 w-full disabled:text-slate-300 select-none disabled:hover:bg-transparent">
											Select type of category
										</button>
										{filteredCategoryData &&
											filteredCategoryData?.map(
												(item: any, outerIndex: number) => (
													<ul key={`typeModal-${outerIndex}`}>
														{item.categoryChild.map(
															(category: any, innerIndex: number) => (
																<li
																	key={`category-${outerIndex}-${innerIndex}`}>
																	<button
																		type="button"
																		className={`flex items-center gap-2 px-4 py-2 hover:bg-slate-100 w-full disabled:text-slate-300 select-none disabled:hover:bg-transparent ${
																			category.categoryId ===
																			formData.categoryId
																				? "bg-slate-100 disabled:bg-transparent"
																				: ""
																		}`}
																		onClick={() => {
																			setFormData({
																				...formData,
																				categoryId: category.categoryId,
																			});
																			setCategoryName({
																				...categoryName,
																				[category.categoryId]: category.name,
																			});
																			setShowSelectCategory(false);
																			setErrors({ ...errors, [name]: false });
																		}}>
																		<span>{category.name}</span>
																	</button>
																</li>
															),
														)}
													</ul>
												),
											)}
									</ul>
									<button
										type="button"
										onClick={() => {
											setOpenModalCategory(true);
											setShowSelectCategory(false);
										}}
										className="flex items-center p-3 text-sm font-medium text-primary border-t border-gray-200 rounded-b-lg bg-gray-50 hover:underline">
										+ Add new category
									</button>
								</div>
							) : (
								<ul className=" h-full w-full py-2 overflow-y-auto text-gray-700 "></ul>
							)}
						</div>
					</div>
				)}
			</ClickOutside>
			<ModalCategory
				action={FORM_ACTION.CREATE}
				onClose={() => setOpenModalCategory(false)}
				onConfirm={onCreateCategory}
			/>
		</React.Fragment>
	);
};

export default memo(SelectCategory);
