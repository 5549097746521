import React, { useState } from "react";
import SelectSurvey from "../feature/component/SelectSurvey";
import TableSurvey from "./component/TableSurvey";

const Survey = () => {
	const [typeSurvey, setTypeSurvey] = useState("");
	const [typeFeature, setTypeFeature] = useState("");
	return (
		<React.Fragment>
			<div className=" flex items-center">
				<SelectSurvey
					typeSurvey={typeSurvey}
					setTypeSurvey={setTypeSurvey}
					typeFeature={typeFeature}
					setTypeFeature={setTypeFeature}
				/>
			</div>
			<TableSurvey feature={typeFeature} type={typeSurvey} />
		</React.Fragment>
	);
};

export default Survey;
