// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { dashboardService } from "services/dashboardService";
import {
	FormAddBlog,
	FormAddCredit,
	FormEditRefferal,
	FormPlan,
	FormUpdateBlog,
	FormUpdateUser,
	FormUser,
	TParamsBlogsGet,
	TParamsGetSaticPlan,
	TParamsGetSaticUser,
	TParamsGetSurvey,
	TParamsPlanSatic,
	TParamsSatic,
	TParamsSearch,
} from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useDashboard = () => {
	const queryClient = useQueryClient();
	//STATIC
	const onGetStaticUser = (params: TParamsSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_USER,
				params.duration,
				params.startDate,
				params.endDate,
			],
			queryFn: () => dashboardService.getStaticUser(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetStaticReferral = (params: TParamsSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_REFERRAL_USER,
				params.duration,
				params.startDate,
				params.endDate,
			],
			queryFn: () => dashboardService.getStaticReferralUser(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetStaticPlanUser = (params: TParamsPlanSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_PLAN_USER,
				params.duration,
				params.startDate,
				params.endDate,
				params.plan,
				params.type,
				params.status,
			],
			queryFn: () => dashboardService.getStaticPlanUser(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetStaticGeneration = (params: TParamsSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_GENERATION,
				params.duration,
				params.startDate,
				params.endDate,
				params.plan,
			],
			queryFn: () => dashboardService.getStaticGeneration(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetListStaticUser = (params: TParamsGetSaticUser) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.LIST_STATIC_USER,
				params.page,
				params.limit,
				params.duration,
				params.startDate,
				params.endDate,
				params.type,
			],
			queryFn: () => dashboardService.getListStaticUser(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetListStaticPlan = (params: TParamsGetSaticPlan) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.LIST_STATIC_PLAN,
				params.page,
				params.limit,
				params.duration,
				params.startDate,
				params.endDate,
				params.plan,
				params.type,
				params.status,
			],
			queryFn: () => dashboardService.getListStaticPlan(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetListStaticFeature = (params: TParamsGetSaticPlan) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.LIST_STATIC_FEATURE,
				params.page,
				params.limit,
				params.duration,
				params.startDate,
				params.endDate,
				params.plan,
				params.type,
			],
			queryFn: () => dashboardService.getListStaticFeature(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetStaticOnline = (params: TParamsSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_ONLINE,
				params.duration,
				params.startDate,
				params.endDate,
			],
			queryFn: () => dashboardService.getStaticOneline(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};
	const onGetStaticFeatureOfUse = (params: TParamsSatic) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.STATIC_FEATURE_OF_USE,
				params.duration,
				params.startDate,
				params.endDate,
			],
			queryFn: () => dashboardService.getStaticFeatureOfUse(params),
		});
		return {
			dataStatic: data?.data?.data,
			isLoading,
			refetch,
			// totalPage: data?.data.pagination.totalPage,
		};
	};

	//USER
	const onGetUser = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.USER,
				params.page,
				params.search,
				params.duration,
				params.plan,
				params.startDate,
				params.endDate,
			],
			queryFn: () => dashboardService.getUser(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportUser = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_USER, params.page, params.limit],
			queryFn: () => dashboardService.getUser(params),
		});
		return {
			dataExportUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const addUserMutation = useMutation({
		mutationFn: (formData: FormUser) => dashboardService.addUser(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Create successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.data.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const updateUserMutation = useMutation({
		mutationFn: (formData: FormUpdateUser) =>
			dashboardService.updateUser(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const deleteUserMutation = useMutation({
		mutationFn: (uid: string) => dashboardService.deleteUser(uid),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Delete successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	const addCreditMutation = useMutation({
		mutationFn: (formData: FormAddCredit) =>
			dashboardService.addCredit(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
				queryClient.invalidateQueries({ queryKey: [QueryKeys.HISTORY] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	//Plan
	const onGetPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.PLAN, params.page, params.search],
			queryFn: () => dashboardService.getPlan(params),
		});
		return {
			dataPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportPlan = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_PLAN, params.page, params.limit],
			queryFn: () => dashboardService.getPlan(params),
		});
		return {
			dataExportPlan: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const createPlanMutation = useMutation({
		mutationFn: (formData: FormPlan) => dashboardService.createPlan(formData),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Create successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editPlanMutation = useMutation({
		mutationFn: (formData: FormPlan) => dashboardService.editPlan(formData),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deletePlanMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deletePlan(id),
		onSuccess: (data: any) => {
			if (data?.data?.data?.statusCode === 200) {
				SnackbarUtils.success("Delete successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.PLAN] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//Gallery
	const onGetGallery = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.GALLERY, params.page, params.search],
			queryFn: () => dashboardService.getGallery(params),
		});

		return {
			dataGallery: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportGallery = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_GALLERY, params.page, params.limit],
			queryFn: () => dashboardService.getGallery(params),
		});

		return {
			dataExportGallery: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const deleteGalleryMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deleteGallery(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.GALLERY] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//Contact us
	const onGetContactUs = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.CONTACT_US, params.page, params.search],
			queryFn: () => dashboardService.getContactUs(params),
		});

		return {
			dataContactUs: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportContactUs = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_CONTACT_US, params.page, params.limit],
			queryFn: () => dashboardService.getContactUs(params),
		});

		return {
			dataExportContactUs: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	//Referral
	const onGetReferral = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.REFERRAL, params.page, params.search],
			queryFn: () => dashboardService.getReferral(params),
		});
		return {
			dataReferral: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportReferral = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_REFERRAL, params.page, params.limit],
			queryFn: () => dashboardService.getReferral(params),
		});
		return {
			dataExportReferral: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const onGetReference = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.REFERENCE,
				params.page,
				params.search,
				params.duration,
				params.startDate,
				params.endDate,
				params.referrerEmail,
			],
			queryFn: () => dashboardService.getReference(params),
		});
		return {
			dataReference: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportReference = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.EXPORT_REFERRENCE,
				params.page,
				params.limit,
				params.duration,
			],
			queryFn: () => dashboardService.getReference(params),
		});
		return {
			dataExportReference: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const createReferralMutation = useMutation({
		mutationFn: (formData: FormEditRefferal) =>
			dashboardService.createReferral(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const editReferralMutation = useMutation({
		mutationFn: (formData: FormEditRefferal) =>
			dashboardService.editReferral(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const deleteReferralMutation = useMutation({
		mutationFn: (id: string) => dashboardService.deleteReferral(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.REFERRAL] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//History
	const onGetHistory = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.HISTORY, params.page, params.search],
			queryFn: () => dashboardService.getHistory(params),
		});
		return {
			dataHistory: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const onExportHistory = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.EXPORT_HISTORY, params.page, params.limit],
			queryFn: () => dashboardService.getHistory(params),
		});
		return {
			dataExportHistory: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	//Survey
	const onGetSurvey = (params: TParamsGetSurvey) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [
				QueryKeys.LIST_SURVEY,
				params.page,
				params.limit,
				params.type,
				params.feature,
			],
			queryFn: () => dashboardService.getSurver(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	//Blogs
	const onGetBlogs = (params: TParamsBlogsGet) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.LIST_SURVEY, params.page, params.limit],
			queryFn: () => dashboardService.getBlogs(params),
		});
		return {
			dataUser: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const createBlog = useMutation({
		mutationFn: (formData: FormAddBlog) =>
			dashboardService.createBlog(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Create successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.BLOG] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const updateBlog = useMutation({
		mutationFn: (formData: FormUpdateBlog) =>
			dashboardService.updateBlog(formData),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Update successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.UPDATE_BLOG] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	const deleteBlog = useMutation({
		mutationFn: (id: string) => dashboardService.deleteBlog(id),
		onSuccess: (data: any) => {
			if (data?.data?.statusCode === 200) {
				SnackbarUtils.success("Delete successfully!");
				queryClient.invalidateQueries({ queryKey: [QueryKeys.USER] });
			} else {
				SnackbarUtils.warning(data?.message);
			}
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});

	return {
		deleteBlog,
		updateBlog,
		createBlog,
		onGetBlogs,
		onGetSurvey,
		onGetStaticFeatureOfUse,
		onGetStaticOnline,
		onGetListStaticFeature,
		onGetListStaticPlan,
		onGetListStaticUser,
		onGetStaticGeneration,
		onGetStaticPlanUser,
		onGetStaticReferral,
		onGetStaticUser,
		onGetUser,
		addUserMutation,
		updateUserMutation,
		deleteUserMutation,
		addCreditMutation,
		onGetPlan,
		createPlanMutation,
		editPlanMutation,
		deletePlanMutation,
		onGetGallery,
		deleteGalleryMutation,
		onGetContactUs,
		onGetReferral,
		onGetReference,
		createReferralMutation,
		editReferralMutation,
		deleteReferralMutation,
		onGetHistory,
		onExportUser,
		onExportContactUs,
		onExportGallery,
		onExportHistory,
		onExportPlan,
		onExportReference,
		onExportReferral,
	};
};
