import React from "react";
import { EChartUser } from "types/chart";

interface ProcessBarProps {
	data?: any;
	title?: string;
	unit?: string;
}

const ProcessBar: React.FC<ProcessBarProps> = ({ data, title, unit }) => {
	const totalUser = data?.[0]?.totalStatic ?? 100;
	return (
		<div className="w-[29%] h-auto rounded-[12px] border border-[#131313] bg-[#66666623] px-5 pt-7.5 pb-5 ">
			<div className=" flex justify-between items-center mb-5">
				<p className="font-semibold text-[#FFFFFF] py-3">
					{" "}
					{title ?? "Total users"}{" "}
				</p>
				<p className="font-semibold text-[#FFFFFF] py-3">
					{totalUser} {unit ?? "user"}
				</p>
			</div>

			{data?.map((item: any, index: number) => {
				if (item?.title !== EChartUser.TOTAL_USER)
					return (
						<div key={index} className="my-7">
							<div className=" flex justify-between items-center">
								<div className="mb-1 text-base font-medium dark:text-white">
									{item?.title}
								</div>
								<p className="font-semibold text-[#FFFFFF] py-3">
									{item?.totalItems} {unit ?? "user"}
								</p>
							</div>
							<div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700">
								<div
									className="bg-gradient-to-r from-[#6DDBFE] to-[#FE55F4] h-2.5 rounded-full "
									style={{
										width: `${
											totalUser > 0 ? (item?.totalItems / totalUser) * 100 : 0
										}%`,
									}}></div>
							</div>
						</div>
					);
			})}
		</div>
	);
};

export default ProcessBar;
