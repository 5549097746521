import ExportChart from "components/ExcelExport/ExportChart";
import { useDashboard } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import {
	EIllunimusEmail,
	ETypeSearch,
	IDashboardReference,
	TParams,
	TParamsSatic,
	TParamsSearch,
} from "types";
import {
	CardDataStats,
	LineChartReferral,
	ProcessBar,
	SearchUser,
} from "./component";
import { Button, LoadingBase, Table, TableRow } from "components";
import { BUTTON_TYPE, formatDMY, tableReferenceDashboardHeader } from "utils";
import Search from "components/Search/Search_";
import { Icons } from "assets";
import { ExcelExport } from "components/ExcelExport";

const Referral = () => {
	const {
		onExportUser,
		onGetStaticReferral,
		onGetReference,
		onExportReference,
	} = useDashboard();
	const [params, setParams] = useState<TParamsSatic>({
		duration: 30,
		startDate: "",
		endDate: "",
	});
	const [searchTableValue, setSearchTableValue] = useState("");

	const {
		dataStatic,
		refetch,
		isLoading: isLoadingStatic,
	} = onGetStaticReferral({
		...params,
	});

	//////////////////////////////////////////////////////////////////////////
	//Export Referral
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10000,
		duration: 180,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportReference({
			...paramsExport,
		});
	///////////////////////////////////////////////////////////////////////////
	//Get reference table
	const [tableParams, setTableParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
		duration: params.duration,
		referrerEmail: "",
		startDate: params.startDate,
		endDate: params.endDate,
	});
	const {
		dataReference,
		isLoading,
		refetch: refetchReference,
		totalPage,
	} = onGetReference({
		...tableParams,
	});
	const [filteredData, setFilteredData] = useState(dataReference);

	useEffect(() => {
		setFilteredData(dataReference);
	}, [dataReference]);

	useEffect(() => {
		setTableParams({
			...tableParams,
			startDate: params.startDate,
			endDate: params.endDate,
			duration: params.duration,
		});
	}, [params.duration, params.startDate, params.endDate]);

	const handleSearch = useCallback(() => {
		setTableParams({
			...tableParams,
			referrerEmail: searchTableValue,
		});
		refetchReference();
		setFilteredData(dataReference);
	}, [searchTableValue]);

	const handleResetSearch = () => {
		setTableParams({
			page: 1,
			limit: 10,
			search: undefined,
			type: undefined,
			plan: "",
			duration: params.duration,
			startDate: params.startDate,
			endDate: params.endDate,
			referrerEmail: "",
		});
		setSearchTableValue("");
	};

	return (
		<React.Fragment>
			<div className="flex justify-end">
				<ExportChart
					fileName="referral_users"
					getData={dataStatic}
					params={paramsExport}
					setParams={setParamsExport}
					isLoading={isLoadingExport}
					// totalPage={totalPage}
				/>
			</div>
			<SearchUser params={params} setParams={setParams} />
			{isLoadingExport || isLoadingStatic ? (
				<LoadingBase />
			) : (
				<>
					<div className="pt-5 flex ">
						{dataStatic?.map((item: any, index: number) => (
							<div key={index}>
								<CardDataStats
									title={item?.title}
									total={item?.totalItems}
									rate={
										params.duration !== 0
											? `${params.duration?.toString()} days ago`
											: params.startDate
											? "Range day"
											: "Today"
									}
								/>
							</div>
						))}
					</div>
					<div className="mt-10 flex justify-between ">
						<LineChartReferral data={dataStatic} />
						<ProcessBar data={dataStatic} />
					</div>
					<div className="mt-[40px] ">
						<div className="flex items-center justify-end mb-6 gap-5 pr-[40px]">
							<div className="cursor-pointer">
								<ExcelExport
									fileName="referrences"
									getData={() => refetchExport()}
									params={paramsExport}
									setParams={setParamsExport}
									isLoading={isLoadingExport}
								/>
							</div>
						</div>
						<div className="w-full flex gap-4 justify-end pr-[40px]">
							<Search
								searchValue={searchTableValue}
								setSearchValue={setSearchTableValue}
								placeholder="Search by Reference Email"
							/>
							<Button onClick={handleSearch}>Search</Button>
							<div title="Reset Search">
								<Button
									onClick={handleResetSearch}
									variant={BUTTON_TYPE.OUTLINED}>
									{Icons.Reload()}
								</Button>
							</div>
						</div>
						<Table
							isLoading={isLoading}
							dataHeader={tableReferenceDashboardHeader}
							params={tableParams}
							totalPage={totalPage}
							filteredData={filteredData}
							onPreviousClick={() => {
								setTableParams({ ...tableParams, page: tableParams.page - 1 });
								refetchReference();
							}}
							onNextClick={() => {
								setTableParams({ ...tableParams, page: tableParams.page + 1 });
								refetch();
							}}
							setFilteredData={setFilteredData}>
							{filteredData?.map((data: IDashboardReference, key: number) => (
								<tr key={key} className="bg-[#131313]">
									<TableRow active={true}>
										<p className="text-[#ffffff]">{data?.name}</p>
									</TableRow>
									<TableRow active={true}>
										<h5
											className={`font-medium text-[#ffffff] ${
												data?.email?.includes(EIllunimusEmail.EMAIL_END)
													? "text-primary"
													: ""
											}`}>
											{data?.email}
										</h5>
									</TableRow>
									<TableRow active={true}>
										<h5
											className={`font-medium text-[#ffffff] ${
												data?.referrerEmail?.includes(EIllunimusEmail.EMAIL_END)
													? "text-primary"
													: ""
											}`}>
											{data?.referrerEmail}
										</h5>
									</TableRow>
									<TableRow active={true}>
										<p className="text-[#ffffff]">
											{formatDMY(data?.createdAt)}
										</p>
									</TableRow>
								</tr>
							))}
						</Table>
					</div>
				</>
			)}
		</React.Fragment>
	);
};

export default Referral;
