import React, { useEffect, useState } from "react";
import { Icons } from "assets";
import { Button, Input } from "components";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import {
	BUTTON_TYPE,
	clotheSize,
	clothesSchema,
	clothesTypeModelOption,
	clothesTypeOutfitOption,
	FORM_ACTION,
	initialClotheSize,
} from "utils";
import { TSizeField, FormClothes } from "types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { SelectCategory } from "pages/merchant/clothes/components";

interface IModalClothes {
	item: any;
	action: string;
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading?: boolean;
}

const modalClothes = ({
	onClose,
	onConfirm,
	item,
	action,
	isLoading,
}: IModalClothes) => {
	const { openModalClothes, setOpenModalClothes } = useLayout();
	const [image, setImage] = useState("");
	const [formData, setFormData] = useState<FormClothes>({
		id: "",
		name: "",
		typeOutfit: "",
		typeModel: "",
		categoryId: "",
		link: "",
		size: initialClotheSize,
		image: null,
	});

	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (action === FORM_ACTION.EDIT && item) {
			setImage(item.image);
			setFormData({
				id: item.id,
				name: item.name,
				typeModel: item.typeModel,
				typeOutfit: item.typeOutfit,
				categoryId: item.categoryId,
				link: item.link,
				size: item.size,
				image: item.image,
			});
		} else {
			setFormData({
				id: "",
				name: "",
				typeModel: "",
				typeOutfit: "",
				categoryId: "",
				link: "",
				size: initialClotheSize,
				image: null,
			});
		}
	}, [item, openModalClothes]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const f = event.target.files?.[0];
		if (f) {
			setFormData({ ...formData, image: f });
			const url = URL.createObjectURL(f);
			setImage(url);
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	// const handleInputChange = (size: string, field: string, value: number) => {
	// 	setFormData((prevData: any) => ({
	// 		...prevData,
	// 		size: prevData.size.map(
	// 			(sizeItem: any) =>
	// 				sizeItem.size === size
	// 					? { ...sizeItem, [field]: value } // Update only the specified field
	// 					: sizeItem, // Keep other sizes unchanged
	// 		),
	// 	}));
	// };

	// const getSizeFieldValue = (size: string, field: TSizeField) => {
	// 	const sizeItem = formData.size?.find((v) => v.size === size);
	// 	return sizeItem ? sizeItem[field] : 0; // Return 0 if the size item is not found
	// };

	const handleSubmit = async () => {
		try {
			await clothesSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<React.Fragment>
			{openModalClothes && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalClothes(false);
							setErrors({});
						}}>
						<div className="md:w-[800px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Clothes"
									: "Edit Clothes"}
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Name"
									type="text"
									formData={formData}
									placeholder="Enter name"
									name="name"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="w-full mb-4">
									<label className="mb-2 block font-bold text-black">
										Category
									</label>
									<div className="flex gap-2">
										<div className="relative inline-block w-full">
											<SelectCategory
												disabled={isLoading}
												formData={formData}
												typeModalOptions={clothesTypeModelOption}
												typeOutfitOptions={clothesTypeOutfitOption}
												setFormData={setFormData}
												errors={errors}
												setErrors={setErrors}
												name="categoryId"
											/>
										</div>
									</div>
								</div>
								{/* <div className="mb-4">
									<label className="mb-2.5 block font-bold text-black">
										Size
									</label>
									<div className="w-full block">
										<div className="grid gap-0">
											{clotheSize.map((sizeItem) => (
												<div
													key={sizeItem.value}
													className="flex items-center border p-2 rounded-md">
													<h3 className="font-bold w-20">{sizeItem.name}</h3>
													{sizeItem.feild.map((field: any, i) => (
														<div key={i} className="flex-1">
															{sizeItem.value === "Field" ? (
																<span className="block font-bold text-center">
																	{field}
																</span>
															) : (
																<input
																	type="number"
																	min={0}
																	value={getSizeFieldValue(
																		sizeItem.value,
																		field.value,
																	)}
																	onChange={(e) =>
																		handleInputChange(
																			sizeItem.value,
																			field.value,
																			Number(e.target.value),
																		)
																	}
																	className="block w-full rounded-md shadow-sm focus:border-primary focus:ring-primary px-2 py-1 focus:outline-none sm:text-sm border-[1.5px] border-stroke"
																/>
															)}
														</div>
													))}
												</div>
											))}
										</div>
									</div>
								</div> */}
								<Input
									disabled={isLoading}
									label="Link"
									type="text"
									formData={formData}
									placeholder="Enter link of your product"
									name="link"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black">
										Image
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="credits"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.image && (
											<LazyLoadImage src={image} className="h-[52px]" />
										)}
									</div>
								</div>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</React.Fragment>
	);
};

export default modalClothes;
