/** @format */

import React, { createContext, useContext, useEffect } from "react";

import { useKey, useLayout, useUser } from "hooks";
import { AuthContextType, EModelAuth } from "types";
import { KEY_CONTEXT } from "utils";

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { getKey, removeKey, removeKeySite } = useKey();
	const { isLogged, setIsLogged } = useUser();
	// const { setOpenModalAuth } = useLayout();

	const login = () => setIsLogged(true);

	useEffect(() => {
		const access_token = getKey(KEY_CONTEXT.AUTH_TOKEN);
		if (access_token) {
			setIsLogged(true);
		}
	}, []);

	const logout = async () => {
		try {
			removeKey("deviceId");
			setIsLogged(false);
			// setOpenModalAuth(EModelAuth.LOGIN);
			// await userService.logout();
			removeKeySite();
		} catch (error) {
			console.log("🚀 ~ logout ~ error:", error);
		}
	};

	return (
		<AuthContext.Provider value={{ isLogged, login, logout }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
