import { Icons, frameCard } from "assets";
import React from "react";

interface CardDataStatsProps {
	title?: string;
	total?: string;
	date?: string;
	rate?: string;
	levelUp?: boolean;
	levelDown?: boolean;
	type?: string;
}

const CardDataStats: React.FC<CardDataStatsProps> = ({
	title,
	total,
	rate,
	date,
	levelUp,
	levelDown,
	type,
}) => {
	return (
		<div className="mr-[24px] flex rounded-[12px] py-[16px] relative cursor-pointer">
			<div
				className={` absolute z-10 w-full ${
					type === title ? "border-fuchsia-600 border-[3px] rounded-[12px]" : ""
				}`}>
				<img src={frameCard} alt="frame" />
			</div>

			<div className="mt-4 flex ml-4 flex-col justify-between z-50 w-[277px]">
				<p>{title ?? "title"}</p>
				<div className=" py-2 flex justify-between w-full">
					<p className="text-[20px] font-bold">{total ?? "111.000"}</p>
					<p className="text-[12px] font-light pr-5">{rate ?? "This month"}</p>
				</div>
			</div>
		</div>
	);
};

export default CardDataStats;
