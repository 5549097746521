/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
// import { FormUpdatePassword } from "types";

const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);
const getRequest = (url: string) => axiosClient.get(url);

export const userService = {
	getProfile: async () => {
		return await getRequest(END_POINT.USER.GET_PROFILE);
	},
	// updateProfile: async (params: any) => {
	// 	const config = {
	// 		headers: {
	// 			"content-type": "multipart/form-data",
	// 		},
	// 	};

	// 	return await postRequest(END_POINT.USER.UPDATE_PROFILE, params, config);
	// },

	// updatePassword: async (dataForm: FormUpdatePassword) => {
	// 	return await postRequest(END_POINT.USER.UPDATE_PASS, dataForm);
	// },

	logout: async () => {
		return await postRequest(END_POINT.USER.LOGOUT, {});
	},
};
