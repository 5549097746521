import { useRef } from "react";

const useDragScroll = () => {
	const scrollRef = useRef<HTMLDivElement | null>(null);
	let isDragging = false;
	let startX: number;
	let scrollLeft: number;

	const onMouseDown = (e: React.MouseEvent) => {
		isDragging = true;
		startX = e.pageX - (scrollRef.current?.offsetLeft || 0);
		scrollLeft = scrollRef.current?.scrollLeft || 0;

		if (scrollRef.current) {
			scrollRef.current.style.cursor = "default";
		}
	};

	const onMouseLeave = () => {
		isDragging = false;
		if (scrollRef.current) {
			scrollRef.current.style.cursor = "default";
		}
	};

	const onMouseUp = () => {
		isDragging = false;
		if (scrollRef.current) {
			scrollRef.current.style.cursor = "default";
			scrollRef.current.classList.remove("select-none");
		}
	};

	const onMouseMove = (e: React.MouseEvent) => {
		if (!isDragging || !scrollRef.current) return;
		e.preventDefault();
		scrollRef.current.style.cursor = "grabbing";
		const x = e.pageX - scrollRef.current.offsetLeft;
		const walk = (x - startX) * 1.5;
		scrollRef.current.scrollLeft = scrollLeft - walk;
		scrollRef.current.classList.add("select-none");
	};

	return { scrollRef, onMouseDown, onMouseLeave, onMouseUp, onMouseMove };
};

export default useDragScroll;
