import React, { useState } from "react";
import SelectSurvey from "../feature/component/SelectSurvey";
import TableSurvey from "./component/TableSurvey";
import TableBlogs from "./component/TableBlogs";

const Blogs = () => {
	const [typeSurvey, setTypeSurvey] = useState("");
	const [typeFeature, setTypeFeature] = useState("");
	return (
		<React.Fragment>
			<div className=" flex items-center">
				{/* <SelectSurvey
					typeSurvey={typeSurvey}
					setTypeSurvey={setTypeSurvey}
					typeFeature={typeFeature}
					setTypeFeature={setTypeFeature}
				/> */}
			</div>
			<TableBlogs feature={typeFeature} type={typeSurvey} />
		</React.Fragment>
	);
};

export default Blogs;
