import { Table, TableRow } from "components";
import ExcelExportPlan from "components/ExcelExport/ExcelExportPlan";
import { useDashboard, useLayout } from "hooks";
import { ActiveSwitcher, FormatPlan } from "pages/admin/user/component";
import React, { useEffect, useState } from "react";
import { EIllunimusEmail, IUserTable, TParamsGetSaticPlan } from "types";
import { formatDMY, tableStaticFeatureHeader } from "utils";

interface ITableItemsPlans {
	duration?: number;
	startDate?: string;
	endDate?: string;
	type?: string;
	plan?: string;
	rate?: string;
}

const TableItemsFeature: React.FC<ITableItemsPlans> = ({
	duration = 30,
	startDate,
	endDate,
	type,
	plan = "FREE",
	rate,
}) => {
	//////////// USESTATE //////////////
	const [params, setParams] = useState<TParamsGetSaticPlan>({
		page: 1,
		limit: 10,
		duration,
		startDate,
		endDate,
		type,
		plan,
	});

	const { onGetListStaticFeature } = useDashboard();

	const { setOpenModalActive } = useLayout();

	const { dataUser, isLoading, refetch, totalPage } =
		onGetListStaticFeature(params);

	useEffect(() => {
		setParams({ ...params, type, plan, duration, startDate, endDate });
		refetch;
		setFilteredData(dataUser);
	}, [duration, startDate, endDate, type, dataUser]);

	const [filteredData, setFilteredData] = useState(dataUser);
	//Export User

	return (
		<React.Fragment>
			<div className="w-full flex justify-between mt-5">
				<h1 className="font-bold text-xl">{type}</h1>
				<div className="flex items-center justify-center gap-5">
					<div className="cursor-pointer">
						<ExcelExportPlan
							fileName={`List_Feature_${rate}`}
							getData={dataUser}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>

			<Table
				isLoading={isLoading}
				isAction={false}
				dataHeader={tableStaticFeatureHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params?.page - 1 });
					refetch();
				}}
				onNextClick={() => {
					setParams({ ...params, page: params?.page + 1 });
					refetch();
				}}
				setFilteredData={setFilteredData}>
				{filteredData?.map((user: IUserTable, key: number) => (
					<tr key={key} className="bg-[#131313]">
						<TableRow>
							<h5
								className={`font-medium text-[#ffffff] ${
									user?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{user?.email}
							</h5>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">{user?.userName}</h5>
						</TableRow>

						<TableRow>
							<FormatPlan data={user?.plan} />
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">
								<ActiveSwitcher
									active={user?.isVerify}
									handleClick={() => {
										setOpenModalActive(true);
									}}
								/>
							</p>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">{formatDMY(user?.createdAt)}</p>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">
								<p className="text-[#ffffff]">{user?.serviceName}</p>
							</p>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">
								<p className="text-[#ffffff]">{user?.status}</p>
							</p>
						</TableRow>
					</tr>
				))}
			</Table>
		</React.Fragment>
	);
};

export default TableItemsFeature;
