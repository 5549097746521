import { Icons } from "assets";
import { Button } from "components";
import Dropdown from "components/Dropdown/Dropdown_";
import Search from "components/Search/Search_";
import React, { FC } from "react";
import { ETypeSearch } from "types";
import { BUTTON_TYPE, typePlanOptions } from "utils";
import Duration from "./Duration";

interface ISearchUserProps {
	params: any;
	setParams: any;
	searchOption: ETypeSearch;
	setSearchOption: (option: ETypeSearch) => void;
	searchValue: string;
	setSearchValue: (value: string) => void;
	handleSearch: () => void;
	handleResetSearch: () => void;
}

const SearchUser: FC<ISearchUserProps> = ({
	params,
	setParams,
	searchOption,
	setSearchOption,
	searchValue,
	setSearchValue,
	handleSearch,
	handleResetSearch,
}) => {
	return (
		<>
			<div className="flex flex-row justify-start px-4 mb-4 gap-4">
				<div className="w-fit h-[42px]">
					<p className="pl-[6px] text-[12px]">Date</p>
					<Duration
						params={params}
						value={params.duration}
						onChange={(v: any) =>
							setParams({
								...params,
								[ETypeSearch.DURATION]: v,
								[ETypeSearch.START_DATE]: "",
								[ETypeSearch.END_DATE]: "",
							})
						}
						onSave={(startDate: any, endDate: any) =>
							setParams({
								...params,
								[ETypeSearch.DURATION]: 0,
								[ETypeSearch.START_DATE]: startDate,
								[ETypeSearch.END_DATE]: endDate,
							})
						}
					/>
				</div>
				<div>
					<p className="pl-[6px] text-[12px]">Plan</p>
					<Dropdown
						options={typePlanOptions}
						value={params.plan}
						onChange={(v: any) =>
							setParams({
								...params,
								[ETypeSearch.PLAN]: v,
							})
						}
					/>
				</div>
			</div>
			<div className="w-full flex gap-4 items-center justify-end">
				<div className="relative">
					<Search
						searchOption={searchOption}
						setSearchOption={setSearchOption}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
						placeholder="Search by Email, Name"
					/>
				</div>

				<Button onClick={handleSearch}>Search</Button>
				<div title="Reset Search">
					<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
						{Icons.Reload()}
					</Button>
				</div>
			</div>
		</>
	);
};

export default SearchUser;
