import { LoadingBase } from "components";
import { useDashboard } from "hooks";
import React, { useState } from "react";
import { TParams, TParamsSatic } from "types";
import { SearchUser } from "./component";
import LineChartFeatureOfUse from "./component/LineChartFeatureOfUse";
import ProcessBarObject from "./component/ProcessBarObject";

const FeatureOfUse = () => {
	const { onExportUser, onGetStaticFeatureOfUse } = useDashboard();
	const [params, setParams] = useState<TParamsSatic>({
		duration: 30,
		startDate: "",
		endDate: "",
	});

	const { dataStatic, isLoading } = onGetStaticFeatureOfUse({
		...params,
	});
	console.log("🚀 ~ Online ~ dataStatic:", dataStatic);

	const [type, setType] = useState("");

	const handleClick = (title: string) => {
		setType(title);
	};

	//////////////////////////////////////////////////////////////////////////
	//Export User
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportUser({
		...paramsExport,
	});
	return (
		<React.Fragment>
			{/* <div className="flex justify-end">
				<ExportChart
					fileName="users"
					getData={dataStatic}
					params={paramsExport}
					setParams={setParamsExport}
					isLoading={isLoadingExport}
				// totalPage={totalPage}
				/>
			</div> */}
			<SearchUser params={params} setParams={setParams} />
			{isLoadingExport || isLoading ? (
				<LoadingBase />
			) : (
				<>
					{/* <div className="pt-5 flex flex-wrap">
						{dataStatic?.map((item: any, index: number) => (
							<div
								key={index}
								onClick={() => {
									handleClick(item?.title);
								}}>
								<CardDataStats
									title={item?.title}
									total={item?.totalItems}
									type={type}
									rate={
										params.duration !== 0
											? `${params.duration?.toString()} days ago`
											: params.startDate
												? "Range day"
												: "Today"
									}
								/>
							</div>
						))}
					</div> */}
					{/* {type && (
						<TableResult
							duration={params?.duration}
							startDate={params?.startDate}
							endDate={params?.endDate}
							type={type}
							rate={
								params.duration !== 0
									? `${params.duration?.toString()} days ago`
									: params.startDate
										? "Range day"
										: "Today"
							}
						/>
					)} */}
					<div className="mt-10 flex justify-between ">
						<LineChartFeatureOfUse data={dataStatic} />
						<ProcessBarObject
							data={dataStatic}
							title="Total User Online"
							unit="Generate"
						/>
					</div>
				</>
			)}
		</React.Fragment>
	);
};

export default FeatureOfUse;
