export enum EnumChatType {
	AI = "AI",
	USER = "USER",
	AI_ANSWER = "AI_ANSWER",
	USER_ASK = "USER_ASK",
	BOT = "BOT",
}

export enum EnumChatBotType {
	BOT = "CHAT",
	SAY_SOMETHING = "SAY_SOMETHING",
	GET_BEHAVIOR_ANALYST = "GET_BEHAVIOR_ANALYST",
	LISTIMAGE = "LIST_IMAGE",
	INTRODUCTION = "QUESTIONS",
	QUESTION = "QUESTION",
	ANSWER = "ANSWER",
	STOP_SPEAK = "STOP_SPEAK",
}

export interface btnItem {
	id: number;
	active: string;
	title: string;
	image: any;
}

export interface IMessage {
	idx?: boolean;
	isTime?: boolean;
	timestamp?: Date;
	input_type: EnumChatType;
	message: string;
	session?: string;
}

export enum EnumUploadType {
	NONE = "none",
	FILE = "file_txt",
	MP3 = "file_mp3",
	VOICE_TO_TEXT = "voice_to_text",
}
export interface IPlayGame {
	type: EnumChatBotType;
	message?: IMessage;
	introduction?: IIntroductionItem;
	listimages?: IImagesItem;
	question?: IQuestionItem;
	answer?: IAnswerItem;
	stopStream?: boolean;
}

interface IImagesItem {
	image_urls?: string[];
	images?: string[] | null;
}

interface IIntroductionItem {
	text: string;
	image_urls?: string[];
	images?: string[];
}

interface IQuestionItem {
	title?: string;
	question?: string | any;
}

interface IAnswerItem {
	title?: string;
	image?: string | any;
	type: EnumAnswerType;
}

// export enum EnumPlayGameType {
//   LISTIMAGE = "listimage",
//   INTRODUCTION = "introduction",
//   QUESTION = "question",
//   ANSWER = "answer",
// }

export enum EnumAnswerType {
	INCORRECT = "incorrect",
	CORRECT = "correct",
}

export enum EnumLanguage {
	EN = "en",
	KO = "ko",
}

export enum AudioSource {
	URL = "URL",
	FILE = "FILE",
	RECORDING = "RECORDING",
}

export enum ModelType {
	VIDEO = "video",
	IMAGE = "image",
}

export enum EVirtualModelType {
	NATURAL = "natural",
	UN_NATURAL = "un-natural",
}

export type TPayloadCreateCharacter = {
	file: File;
	uid: string;
	background_color: string;
};

export type TPayloadStartStream = {
	stream_id: string;
	session_id: string;
	answer: RTCSessionDescriptionInit;
	type: string;
};

export type TPayloadSubmitNetwork = {
	stream_id: string;
	candidate: string;
	sdp_mline_index: number;
	sdp_mid: string;
	session_id: string;
	type: string;
};

export type TPayloadCreateClipStream = {
	stream_id: string;
	uid: string;
	text: string;
	session_id: string;
	background_color: string;
	file?: string;
	type?: string;
	lang_id?: string;
	is_did_requested?: boolean;
	ignore?: boolean;
};

export type TResponseStartStream = {
	code: string;
	id: string;
	session_id: string;
	offer: RTCSessionDescriptionInit;
	ice_servers: RTCIceServer[];
};

export type THistory = {
	histories: IMessage[];
	uid: string;
	user_id: number;
};

export type TResponseHistories = {
	code: string;
	histories: THistory[];
};

export interface IAudioUpload {
	name: string;
	uuid: string;
	file: File;
}
export interface IAvatarTrain extends IAudioUpload {
	text_instructions: string;
}

export enum EOfferType {
	CLONED = "CLONED",
	PREMADE = "PREMADE",
}

export interface IOffer {
	type: string;
	sdp: string;
	avatar_uuid: string;
	voice_id: string;
}

export interface IChatBot extends IStreamBot {
	text: string;
}

export interface ITemplateBot {
	name: string;
	voice_id: string;
	avatar_uuid: string;
}

export interface ITemplateBot {
	name: string;
	voice_id: string;
	avatar_uuid: string;
}

export interface IStreamBot {
	uuid: string;
	avatar_uuid: string;
}

export type TWebRTCRef = {
	stream_id: string;
	session_id: string;
	type: string;
	videoFile: string;
	intervalId: NodeJS.Timeout | null;
	lastBytesReceived?: any;
	videoIsPlaying3rd?: any;
	mediaStream?: MediaStream | null;
};

export type TBotRef = {
	stream_id: string;
	session_id: string;
	timeout: NodeJS.Timeout | null;
	interval: NodeJS.Timeout | null;
};

export interface IVoice {
	voice_id: string;
	audio_url: string;
	accent: string;
	age: string;
	created_at: string;
	gender: string;
	name: string;
	type: string;
}

export interface IAvatar {
	avatar_uuid: string;
	avatar_url: string;
	created_at: string;
	name: string;
	status: string;
	type: string;
}

export enum ETemplate {
	avatar_uuid = "avatar_uuid",
	voice_id = "voice_id",
	name = "name",
	instructions = "instructions",
}

export interface ITemplate {
	avatar_uuid: string;
	voice_id: string;
	assistant_name: string;
	instructions: string;
}

export type TParamsTemplate = {
	avatar_uuid: string;
	voice_id: string;
	name: string;
	instructions?: string;
};
