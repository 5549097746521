/** @format */

import moment from "moment";

export const getTimeAfter = (
	mCount: number,
	unit?: moment.unitOfTime.DurationConstructor,
) => moment().add(mCount, unit);

export const formatDateTime = (dateString: string) => {
	if (dateString === "") return;
	const date = new Date(dateString);
	const offsetDate = new Date(
		date.getTime() - date.getTimezoneOffset() * 60000,
	); // Adjust for local time
	return offsetDate.toISOString().slice(0, 16); // Cut off the seconds and milliseconds
};
