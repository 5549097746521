// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { notificationService } from "services";
import { FormCreateNoti, FormEditNoti, TParamsSearch } from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useNotification = () => {
	const queryClient = useQueryClient();

	const onGetNotification = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.NOTIFICATION, params.page, params.search],
			queryFn: () => notificationService.getNotification({ ...params }),
		});

		return {
			notificationData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const createNotiMutation = useMutation({
		mutationFn: (formData: FormCreateNoti) =>
			notificationService.createNoti(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.NOTIFICATION] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editNotiMutation = useMutation({
		mutationFn: (formData: FormEditNoti) =>
			notificationService.editNoti(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.NOTIFICATION] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteNotiMutation = useMutation({
		mutationFn: (id: string) => notificationService.deleteNoti(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.NOTIFICATION] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});

	return {
		onGetNotification,
		createNotiMutation,
		editNotiMutation,
		deleteNotiMutation,
	};
};
