import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { Swicher } from "components/Swicher";
import { useLayout, useNotification } from "hooks";
import React, { useEffect, useState } from "react";
import { ETypeNotiOption, FormEditNoti } from "types";
import { BUTTON_TYPE, FORM_ACTION, notiTypeOption } from "utils";
import { notiSchema } from "utils";

interface IModalNotificationProps {
	item: any;
	onClose: () => void;
	action: string;
	onConfirm: (formData: FormEditNoti) => void;
}

const ModalNotification = ({
	item,
	onClose,
	action,
	onConfirm,
}: IModalNotificationProps) => {
	const { editNotiMutation, createNotiMutation } = useNotification();
	const { openModalNoti, setOpenModalNoti } = useLayout();
	const [formData, setFormData] = useState<FormEditNoti>({
		notiId: "",
		type: ETypeNotiOption.MAINTAIN,
		title: "",
		content: "",
		hoursToAdd: 0,
		isMaintain: false,
	});
	const [errors, setErrors] = useState({});

	const isLoading = editNotiMutation.isPending || createNotiMutation.isPending;

	useEffect(() => {
		if (action === FORM_ACTION.EDIT && item) {
			setFormData({
				notiId: item.id,
				type: item.type,
				title: item.title,
				content: item.content,
				hoursToAdd: item.hoursToAdd,
				isMaintain: item.isMaintain,
			});
		} else {
			setFormData({
				notiId: "",
				type: ETypeNotiOption.MAINTAIN,
				title: "",
				content: "",
				hoursToAdd: 0,
				isMaintain: false,
			});
		}
	}, [item, openModalNoti]);

	const handleSubmit = async () => {
		try {
			await notiSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<>
			{openModalNoti && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside onClick={() => setOpenModalNoti(false)}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Notification"
									: "Edit Notification"}
							</h3>
							<form>
								<div className="w-full mb-4">
									<label className="mb-2 block font-bold text-black">
										Type
									</label>
									<select
										value={formData.type}
										onChange={(e: any) =>
											setFormData({ ...formData, type: e.target.value })
										}
										className="w-full rounded-lg border border-stroke bg-transparent py-3 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary">
										{notiTypeOption.map((option) => (
											<option key={option.value} value={option.value}>
												{option.name}
											</option>
										))}
									</select>
								</div>
								<Input
									disabled={isLoading}
									label="Title"
									type="text"
									formData={formData}
									placeholder="Enter title"
									name="title"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Content"
									type="text"
									formData={formData}
									placeholder="Enter content"
									name="content"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Hours to add"
									type="number"
									formData={formData}
									placeholder="Enter hours to add"
									name="hoursToAdd"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								{action === FORM_ACTION.EDIT && (
									<div className="mb-4">
										<label className="mb-2.5 block font-bold text-black dark:text-white">
											Is maintain?
										</label>
										<div className="w-full flex items-center gap-2">
											<span className="font-thin text-[11px]">False </span>
											<Swicher
												active={formData.isMaintain}
												onChange={() =>
													setFormData({
														...formData,
														isMaintain: !formData.isMaintain,
													})
												}
											/>
											<span className="font-thin text-[11px]"> True</span>
										</div>
									</div>
								)}
								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</>
	);
};

export default ModalNotification;
