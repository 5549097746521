import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
	const navigate = useNavigate();
	return (
		<div className="w-screen h-screen flex flex-col items-center justify-center">
			<p className="lg:text-2xl text-black pb-4">404 Not Found</p>
			<button
				className="p-2 bg-slate-400"
				onClick={() => {
					navigate("/");
				}}>
				Back to home
			</button>
		</div>
	);
};

export default NotFound;
