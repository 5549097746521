// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { merchantService } from "services";
import { TParamsSearch } from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useMerchant = () => {
	const queryClient = useQueryClient();

	const onGetCategory = (merchantId: string) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.CATEGORY, merchantId],
			queryFn: () => merchantService.getCategory(merchantId),
		});
		return {
			categoryData: data?.data?.data,
			isLoading,
			refetch,
		};
	};

	const createCategoryMutation = useMutation({
		mutationFn: (formData: any) => merchantService.createCategory(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORY] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editCategoryMutation = useMutation({
		mutationFn: (formData: any) => merchantService.editCategory(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORY] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteCategoryMutation = useMutation({
		mutationFn: (id: string) => merchantService.deleteCategory(id),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CATEGORY] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});
	//CLOTHES
	const onGetClothes = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.CLOTHES, params.page, params.search],
			queryFn: () => merchantService.getClothes({ ...params }),
		});

		return {
			clothesData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};

	const createClothesMutation = useMutation({
		mutationFn: (formData: any) => merchantService.createClothes(formData),
		onSuccess: () => {
			SnackbarUtils.success("Create successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CLOTHES] });
		},
		onError: () => {
			SnackbarUtils.warning("Create failed");
		},
	});

	const editClothesMutation = useMutation({
		mutationFn: (formData: any) => merchantService.editClothes(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CLOTHES] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});

	const deleteClothesMutation = useMutation({
		mutationFn: (formData: any) => merchantService.deleteClothes(formData),
		onSuccess: () => {
			SnackbarUtils.success("Delete successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.CLOTHES] });
		},
		onError: () => {
			SnackbarUtils.warning("Delete failed");
		},
	});

	return {
		onGetCategory,
		createCategoryMutation,
		editCategoryMutation,
		deleteCategoryMutation,
		onGetClothes,
		createClothesMutation,
		editClothesMutation,
		deleteClothesMutation,
	};
};
