import React, { FC } from "react";
import { ETypePlan } from "types";

interface IFormatPlanProps {
	data: string;
}

const FormatPlan: FC<IFormatPlanProps> = ({ data }) => {
	switch (data) {
		case ETypePlan.FREE:
			return (
				<span className="rounded-[12px] px-3 py-1 bg-[#666666] text-white text-[12px]">
					Free
				</span>
			);

		case ETypePlan.PRO:
			return (
				<span className="rounded-[12px] px-3 py-1 bg-[#FF52F0] text-white text-[12px]">
					Pro
				</span>
			);

		case ETypePlan.PREMIUM:
			return (
				<span className="rounded-[12px] px-3 py-1 bg-[#5271FF] text-white text-[12px]">
					Premium
				</span>
			);
		case ETypePlan.ENTERPRISE:
			return (
				<span className="rounded-[12px] px-3 py-1 bg-[#98DD0D] text-white text-[12px]">
					Enterprise
				</span>
			);
		default:
			break;
	}
	return <></>;
};

export default FormatPlan;
