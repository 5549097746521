/** @format */

export enum EChartUser {
	TOTAL_USER = "Total User",
	VERIFIED_USER = "Verified User",
	FREE_USER = "Free User",
	PAID_USER = "Paid User",
	FAILED_REGISTRATION = "Failed Registration",
	ACTIVE_USER = "Active User",
	INACTIVE_USER = "Inactive User",
	CHECKOUT = "Checkout",
	CANCEL_PLAN = "Cancel Plan",

	REFERRAL = "Referral User",
	FREE = "Free",
	ENTY = "Entry",
	PRO = "Pro",
	PREMIUM = "Premium",
	ENTERPRISE = "Enterprise",
	MONTHLY = "Monthly",
	YEARLY = "Yearly",

	STYLE_FIT = "Style Fit",
	GEN_ME = "Gen Me",
	HAIR_STYLE = "Hairstyle",
	ANIMATE = "Animate",
	BODY_SHAPE = "Body Shape",
	SUCCEED_GENERATION = "Succeed generation",
	FAIL = "Fail",
	DOWNLOADED = "Downloaded",

	USER_ONLINE = "User Online",

	FEATURE_OF_USE = "Feature of use",
}
