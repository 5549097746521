/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
import { FormSettings, TParamsSearch } from "types";

const getRequest = (url: string, params?: object) =>
	axiosClient.get(url, { params });
const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);

export const settingService = {
	//GET SETTINGS
	getSetting: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.SETTING.GET_SETTING, {
			...params,
		});
	},
	editSetting: async (params: FormSettings) => {
		return await postRequest(END_POINT.SETTING.EDIT_SETTING, {
			...params,
		});
	},
};
