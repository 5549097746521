import { Icons } from "assets";
import React from "react";
import { ETypeSearch } from "types";

interface ISearch {
	searchValue: string;
	setSearchOption?: (option: ETypeSearch) => void;
	searchOption?: ETypeSearch;
	setSearchValue: (value: string) => void;
	placeholder: string;
	[key: string]: any;
}

const Search = ({
	searchOption,
	setSearchOption,
	searchValue,
	setSearchValue,
	placeholder,
	...props
}: ISearch) => {
	return (
		<div className="relative max-w-lg">
			<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
				{Icons.Search()}
			</div>
			<input
				title={placeholder}
				type="text"
				value={searchValue}
				onChange={(e) => setSearchValue(e.target.value)}
				className="text-white bg-transparent outline-none block w-full h-[42px] p-4 pr-20 ps-10 text-sm border border-[#666666] rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-primary truncate"
				placeholder={placeholder}
				{...props}
			/>
			{searchOption && (
				<select
					className="text-[14px] text-white absolute top-1/2 -translate-y-1/2 right-2 border-l pl-2 bg-transparent"
					value={searchOption}
					onChange={(e) => setSearchOption?.(e.target.value as ETypeSearch)}>
					<option value={ETypeSearch.NAME} className="text-black">
						Name
					</option>
					<option value={ETypeSearch.EMAIL} className="text-black">
						Email
					</option>
				</select>
			)}
		</div>
	);
};

export default Search;
