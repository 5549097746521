import React, { useCallback, useState } from "react";
import { Icons } from "assets";
import { Button } from "components/Button";
import ClickOutside from "components/Header/ClickOutside";
import { Input } from "components/Input";
import { useLayout } from "hooks";
import { BUTTON_TYPE, addCreditSchema } from "utils";
import { FormAddCredit } from "types";

interface IModalAddCreditProps {
	onClose: () => void;
	onConfirm: (data: any) => void;
	item: any;
	isLoading?: boolean;
}

const modalAddCredit = ({
	onClose,
	onConfirm,
	item,
	isLoading,
}: IModalAddCreditProps) => {
	const { openModalAddCredit, setOpenModalAddCredit } = useLayout();
	const [formData, setFormData] = useState<FormAddCredit>({
		email: "",
		credit: NaN,
	});
	const [errors, setErrors] = useState({});

	React.useEffect(() => {
		setFormData({
			email: "",
			credit: NaN,
		});
	}, [openModalAddCredit]);

	const handleSubmit = useCallback(async () => {
		try {
			await addCreditSchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	}, [item, formData.credit, formData.email]);

	return (
		<React.Fragment>
			{openModalAddCredit && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalAddCredit(false);
							setErrors({});
						}}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								Add credits
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Email"
									type="text"
									formData={formData}
									placeholder="Enter email"
									name="email"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<Input
									disabled={isLoading}
									label="Credits"
									type="number"
									formData={formData}
									placeholder="Enter credits"
									name="credit"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</React.Fragment>
	);
};

export default modalAddCredit;
