import React, { useEffect, useState } from "react";
import { useDashboard } from "hooks";
import { ETypeSearch, IHistory, TParams } from "types";
import { formatDMY, tableHistory } from "utils";
import { Table, TableRow } from "components";
import { Icons } from "assets";
import { ExcelExport } from "components/ExcelExport";

const AddCredit = () => {
	const { onGetHistory, onExportHistory } = useDashboard();
	const [params, setParams] = useState<TParams>({
		page: 1,
		limit: 10,
		search: undefined,
		type: ETypeSearch.ADD_CREDIT,
	});
	const { dataHistory, isLoading, refetch, totalPage } = onGetHistory({
		...params,
	});

	//Export AddCredit
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
		type: ETypeSearch.ADD_CREDIT,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportHistory({
			...paramsExport,
		});

	const [filteredData, setFilteredData] = useState(dataHistory);
	// const [searchValue, setSearchValue] = useState("");

	// const handleSearch = useCallback(() => {
	// 	if (searchValue === "") return;
	// 	setParams({
	// 		...params,
	// 		search: searchValue,
	// 		type: ETypeSearch.TYPE,
	// 	});
	// 	refetch();
	// 	setFilteredData(dataHistory);
	// }, [searchValue, params]);

	// const handleResetSearch = () => {
	// 	if (searchValue === "") return;
	// 	setSearchValue("");
	// };

	// useEffect(() => {
	// 	if (searchValue === "") {
	// 		setParams({
	// 			...params,
	// 			search: undefined,
	// 			type: undefined,
	// 		});
	// 		refetch();
	// 	}
	// }, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataHistory);
	}, [dataHistory]);

	return (
		<div>
			<h1 className="font-bold text-xl mb-4 ">Add Credit History</h1>
			{/* <div className="flex flex-row justify-end px-4 mb-4 gap-2 items-center">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={historyTypeOption}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				</div>
				<Button onClick={handleSearch}>Search</Button>
				<div title="Reset Search">
					<Button onClick={handleResetSearch} variant={BUTTON_TYPE.OUTLINED}>
						{Icons.Reload()}
					</Button>
				</div>
			</div> */}
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="histories"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				dataHeader={tableHistory}
				params={params}
				filteredData={filteredData}
				totalPage={totalPage}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((data: IHistory, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.metaData.email}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.metaData.action}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.metaData.credit}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.createdAt)}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.updatedAt)}
							</p>
						</TableRow>
					</tr>
				))}
			</Table>
		</div>
	);
};

export default AddCredit;
