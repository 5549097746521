import React, { FC, useState } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import "./Datepicker.css";

import { Button } from "components";
import { BUTTON_TYPE } from "utils";

interface IDateRangeSelectorProps {
	onClose: () => void;
	onSave: (startDate: any, endDate: any) => void;
}

const DateRangeSelector: FC<IDateRangeSelectorProps> = ({
	onClose,
	onSave,
}) => {
	const [selectedDateRange, setSelectedDateRange] = useState({
		startDate: new Date(),
		endDate: new Date(),
		key: "selection",
	});

	const handleSelect = (ranges: any) => {
		setSelectedDateRange(ranges.selection);
	};

	const onClickClear = () => {
		setSelectedDateRange({
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		});
	};

	return (
		<React.Fragment>
			<div className="shadow d-inline-block bg-[#666666] backdrop-blur-lg w-fit p-2 rounded-[12px]">
				<DateRangePicker
					onChange={handleSelect}
					moveRangeOnFirstSelection={false}
					color="#A9319F"
					rangeColors={["A9319F", "rgba(0, 0, 0, 0.2)", "A9319F"]}
					months={2}
					dateDisplayFormat="dd/MM/yyyy"
					ranges={[selectedDateRange]}
					direction="vertical"
				/>
				<div className="text-right position-relative rdr-buttons-position mt-2 mr-3 gap-2 flex justify-end">
					<Button
						variant={BUTTON_TYPE.OUTLINED}
						onClick={() => {
							onClickClear();
							onClose();
						}}
						className="text-[#ffffff] border-none">
						Cancel
					</Button>
					<Button
						onClick={() => {
							onSave(selectedDateRange.startDate, selectedDateRange.endDate);
							onClickClear();
							onClose();
						}}>
						Save
					</Button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default DateRangeSelector;
