import React, { useCallback, useEffect, useState } from "react";
import { useDashboard } from "hooks";
import { ETypeSearch, IContactUs, TParams } from "types";
import {
	formatDMY,
	searchUserOption as searchContactUsOption,
	tableContactUsHeader,
} from "utils";
import { Search } from "components/Search";
import { Button } from "components/Button";
import SnackbarUtils from "utils/SnackbarUtils";
import { Dropdown, Table, TableRow } from "components";
import { ExcelExport } from "components/ExcelExport";
import { Icons } from "assets";

const ContactUs = () => {
	const { onGetContactUs, onExportContactUs } = useDashboard();
	const [params, setParams] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { dataContactUs, isLoading, refetch, totalPage } = onGetContactUs({
		...params,
	});

	//Export Contact Us
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportContactUs({
			...paramsExport,
		});

	const [filteredData, setFilteredData] = useState(dataContactUs);
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(dataContactUs);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(dataContactUs);
	}, [dataContactUs]);

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Contact management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 ">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchContactUsOption}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Name, Email"
				/>
				<Button onClick={handleSearch}>Search</Button>
			</div>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="contact-us"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				dataHeader={tableContactUsHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((item: IContactUs, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<TableRow>
							<h5
								title={item.id}
								className="w-[100px] truncate font-medium text-black dark:text-white">
								{item.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{item.firstName}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{item.lastName}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{item.company}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.website}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.email}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.yourRole}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.message}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.phone}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{item.describe}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white min-w-[160px] truncate">
								{item.options.join(", ")}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(item.createdAt)}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(item.updatedAt)}
							</p>
						</TableRow>
					</tr>
				))}
			</Table>
		</React.Fragment>
	);
};

export default ContactUs;
