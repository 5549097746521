import { Icons } from "assets";
import ClickOutside from "components/Header/ClickOutside";
import React, { FC, useEffect, useState } from "react";
import { formatDMY } from "utils";

interface IDropdownWithCustomDateProps {
	options: any[];
	value: string;
	className?: string;
	onChange: (value: string) => void;
	isAdd?: boolean;
	params: any;
}

const DropdownWithCustomDate: FC<IDropdownWithCustomDateProps> = ({
	options,
	value,
	onChange,
	params,
	// isAdd,
}) => {
	const [showDropdownWithCustomDate, setShowDropdownWithCustomDate] =
		useState(false);
	const [name, setName] = useState<any>({});

	useEffect(() => {
		setName({
			...name,
			[value]: options.find((option) => option.value === value)?.name,
		});
	}, [value]);

	return (
		<ClickOutside
			className="w-fit min-w-[250px] relative"
			onClick={() => setShowDropdownWithCustomDate(false)}>
			<div
				onClick={() => setShowDropdownWithCustomDate((prev) => !prev)}
				className={`relative w-full rounded-lg border-[1px] bg-transparent py-2 pl-4 pr-4 text-[#8F8F8F] outline-none transition focus:border-[#ffffff] active:border-[#ffffff] select-none  disabled:cursor-default disabled:bg-whiter flex items-center justify-between cursor-pointer gap-4 ${
					showDropdownWithCustomDate ? "border border-primary" : "border-stroke"
				}`}>
				{params.startDate
					? formatDMY(params.startDate) + "-" + formatDMY(params.endDate)
					: name?.[value]}
				<div
					className={`text-right transition duration-200 ${
						showDropdownWithCustomDate ? "rotate-180" : "rotate-0"
					}`}>
					{Icons.ArrowDown("#8F8F8F")}
				</div>
			</div>
			{showDropdownWithCustomDate && (
				<div
					id="dropdownUsers"
					className="z-99 w-full absolute bg-[#ffffff] bg-opacity-20 backdrop-blur-lg shadow mt-1 truncate right-0 px-4 py-3 rounded-[12px]">
					<ul
						className=" w-ful min-w-[100px] max-h-48 py-2 overflow-y-auto text-[#ffffff]"
						aria-labelledby="dropdownUsersButton">
						{options.map((item, index: number) => (
							<li key={index}>
								<button
									disabled={item.disabled}
									className="flex items-center justify-between gap-2 px-4 py-2 w-full disabled:text-slate-300 select-none disabled:hover:bg-transparent hover:bg-[#9577D980] rounded-[12px]"
									onClick={() => {
										onChange(item.value);
										setName({ ...name, [item.value]: item.name });
										setShowDropdownWithCustomDate(false);
									}}>
									<span>{item.name}</span>
									{!item.disabled && item.icon && <>{item.icon}</>}
								</button>
							</li>
						))}
					</ul>
					{/* {isAdd && (
						<a
							href="#"
							className="flex items-center p-3 text-sm font-medium text-primary border-t border-gray-200 rounded-b-lg bg-gray-50 hover:underline">
							+ Add new category
						</a>
					)} */}
				</div>
			)}
		</ClickOutside>
	);
};

export default DropdownWithCustomDate;
