import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/style/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { AuthProvider, UserProvider } from "contexts";
import { SnackbarUtilsConfigurator } from "utils";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<AuthProvider>
					<UserProvider>
						<SnackbarProvider
							maxSnack={3}
							anchorOrigin={{ horizontal: "center", vertical: "bottom" }}>
							<App />
							<SnackbarUtilsConfigurator />
						</SnackbarProvider>
					</UserProvider>
				</AuthProvider>
			</I18nextProvider>
		</QueryClientProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
