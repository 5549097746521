/** @format */

import { IUser, ModelType } from "types";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface UserState {
	user: IUser | null;
	setUser: (user: IUser) => void;
	setDefaultUser: (user: any) => void;
	setLanguages: (lang: { value: string; name: string }) => void;
	setStream: (str: {
		streamId: string;
		sessionId: string;
		selectedType: ModelType;
	}) => void;
	clearUser: () => void;
	languages: {
		value: string;
		name: string;
	};
	stream: {
		streamId: string;
		sessionId: string;
		selectedType: ModelType | string;
	};
	uid: string;
	avatar_uuid: string;
	setUID: (u: string) => void;
	setAvatarUID: (u: string) => void;
	isLogged: boolean;
	defaultUser: any;
	setIsLogged: (is: boolean) => void;
	emailRegister?: string;
	setEmailRegister: (email: string) => void;
	resetUserStored: () => void;
	isMerchant: boolean;
	setIsMerchant: (is: boolean) => void;
}

const initialState = {
	user: null,
	languages: {
		value: "en",
		name: "English",
	},
	uid: "",
	socket: null,
	stream: {
		streamId: "",
		sessionId: "",
		selectedType: ModelType.IMAGE,
	},
	isLogged: false,
	emailRegister: "",
};

export const useUser = create<UserState>()(
	persist(
		(set) => ({
			user: null,
			defaultUser: null,
			uid: "",
			avatar_uuid: "",
			languages: {
				value: "en",
				name: "English",
			},
			stream: {
				streamId: "",
				sessionId: "",
				selectedType: ModelType.IMAGE,
			},
			setDefaultUser: (user) => {
				set({ defaultUser: user });
			},
			setUser: (user) => {
				set({ user });
			},
			clearUser: () => set({ user: null }),
			setStream: (str: {
				streamId: string;
				sessionId: string;
				selectedType: ModelType | string;
			}) => set({ stream: str }),
			setLanguages: (language: { value: string; name: string }) =>
				set({
					languages: {
						value: language?.value,
						name: language?.name,
					},
				}),
			setAvatarUID: (u) => {
				set({
					avatar_uuid: u,
				});
			},
			setUID: (u) => {
				set({
					uid: u,
				});
			},
			isLogged: false,
			socket: null,
			setIsLogged: (isLogged: boolean) => set({ isLogged: isLogged }),
			emailRegister: "",
			setEmailRegister: (email) => set({ emailRegister: email }),
			resetUserStored: () => set(initialState),
			isMerchant: false,
			setIsMerchant: (isMerchant: boolean) => set({ isMerchant: isMerchant }),
		}),
		{
			name: "user-storage",
			storage: createJSONStorage(() => localStorage),
			partialize: (state) => ({
				user: state.user,
				languages: state.languages,
				isLogged: state.isLogged,
				stream: state.stream,
			}),
		},
	),
);
