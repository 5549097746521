/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
import { FormCreateNoti, FormEditNoti, TParamsSearch } from "types";

const getRequest = (url: string, params?: object) =>
	axiosClient.get(url, { params });

const postRequest = (url: string, param: any, conf: any = null) =>
	axiosClient.post(url, param, conf && conf);

export const notificationService = {
	//GET NOTIFICATIONS
	getNotification: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.NOTIFICATION.GET_NOTIFICATION, {
			...params,
		});
	},
	createNoti: async (formData: FormCreateNoti) => {
		return await postRequest(
			END_POINT.NOTIFICATION.CREATE_NOTIFICATION,
			formData,
		);
	},
	editNoti: async (formData: FormEditNoti) => {
		return await postRequest(
			END_POINT.NOTIFICATION.EDIT_NOTIFICATION,
			formData,
		);
	},
	deleteNoti: async (notiId: string) => {
		return await postRequest(END_POINT.NOTIFICATION.DELETE_NOTIFICATION, {
			notiId,
		});
	},
};
