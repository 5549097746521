/** @format */

import { FormLogin } from "types";
import { END_POINT } from "utils";
import axiosClient from "./axios";

export const authService = {
	refreshToken: async (rfToken: string) => {
		return await postRequest(END_POINT.AUTH.REFRESH_TOKEN, {
			refresh: rfToken,
		});
	},
	login: async (formData: FormLogin) => {
		console.log("🚀 ~ login: ~ formData:", formData);
		return await postRequest(END_POINT.AUTH.LOGIN, formData);
	},
	changePassword: async (formData: { uid: string; newPassword: string }) => {
		console.log("🚀 ~ changePassword: ~ formData:", formData);
		return await postRequest(END_POINT.AUTH.CHANGE_PASSWORD, {
			uid: formData.uid,
			newPassword: formData.newPassword,
		});
	},
	logout: async () => {
		return await postRequest(END_POINT.USER.LOGOUT, {});
	},
};

const postRequest = async (url: string, param: any, conf: any = null) => {
	const data = await axiosClient.post(`${url}`, param, conf && conf);
	console.log("🚀 ~ postRequest ~ data:", data);
	return data;
};
const getRequest = async (url: string, param?: any) => {
	const { data } = await axiosClient.get(`${url}`, param);
	return data;
};
