import ExportChart from "components/ExcelExport/ExportChart";
import { useDashboard } from "hooks";
import React, { useEffect, useState } from "react";
import { TParams, TParamsPlanSatic, TParamsSatic } from "types";
import {
	CardDataStats,
	LineChartPlan,
	ProcessBar,
	SelectDuration,
	TableItemsPlans,
} from "./component";
import { Button, LoadingBase } from "components";

const Plan = () => {
	const [type, setType] = useState("");
	const [plan, setPlan] = useState("FREE");
	const [typePlan, setTypePlan] = useState("MONTHLY");
	const [statusPlan, setStatusPlan] = useState("ACTIVE");
	const [duration, setDuration] = useState({
		duration: 30,
		startDate: "",
		endDate: "",
	});
	const [params, setParams] = useState<TParamsPlanSatic>({
		duration: 30,
		startDate: "",
		endDate: "",
		plan,
		type: typePlan,
		status: statusPlan,
	});

	const { onExportUser, onGetStaticPlanUser } = useDashboard();
	const { dataStatic, isLoading } = onGetStaticPlanUser(params);

	const handleSearch = (input: any) => {
		const { endDate, startDate, duration, plan, type, status } = input;
		setParams({ ...params, endDate, startDate, duration, plan, type, status });
		setType("");
	};

	const handleClick = (title: string) => {
		setType(title);
	};

	//////////////////////////////////////////////////////////////////////////
	//Export User
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } = onExportUser({
		...paramsExport,
	});
	return (
		<React.Fragment>
			{/* <div className="flex justify-end">
				<ExportChart
					fileName="Static_Plans"
					getData={dataStatic}
					params={paramsExport}
					setParams={setParamsExport}
					isLoading={isLoadingExport}
				// totalPage={totalPage}
				/>
			</div> */}
			<div className=" flex items-center">
				<SelectDuration
					params={duration}
					setParams={setDuration}
					plan={plan}
					setPlan={setPlan}
					typePlan={typePlan}
					setTypePlan={setTypePlan}
					statusPlan={statusPlan}
					setStatusPlan={setStatusPlan}
				/>
				<Button
					className="mt-5 w-[150px]"
					onClick={() => {
						handleSearch({
							duration: duration?.duration,
							startDate: duration?.startDate,
							endDate: duration?.endDate,
							plan,
							type: typePlan,
							status: statusPlan,
						});
					}}
					disabled={isLoading}>
					Search
				</Button>
			</div>

			{isLoadingExport || isLoading ? (
				<LoadingBase />
			) : (
				<>
					<div className="pt-5 flex flex-wrap">
						{dataStatic?.map((item: any, index: number) => (
							<div
								key={index}
								onClick={() => {
									handleClick(item?.title);
								}}>
								<CardDataStats
									title={item?.title}
									total={item?.totalItems}
									type={type}
									rate={
										params.duration !== 0
											? `${params.duration?.toString()} days ago`
											: params.startDate
											? "Range day"
											: "Today"
									}
								/>
							</div>
						))}
					</div>
					{type && (
						<TableItemsPlans
							duration={params?.duration}
							startDate={params?.startDate}
							endDate={params?.endDate}
							type={typePlan}
							status={statusPlan}
							plan={plan}
							rate={
								params.duration !== 0
									? `${params.duration?.toString()} days ago`
									: params.startDate
									? "Range day"
									: "Today"
							}
						/>
					)}
					<div className="mt-10 flex justify-between ">
						<LineChartPlan data={dataStatic} />
						<ProcessBar
							data={dataStatic}
							title="Total"
							unit="Transaction Plan"
						/>
					</div>
				</>
			)}
		</React.Fragment>
	);
};

export default Plan;
