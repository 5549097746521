import { Table, TableRow } from "components";
import ExcelExportPlan from "components/ExcelExport/ExcelExportPlan";
import ExcelExportSurvey from "components/ExcelExport/ExcelExportSurvey";
import { useDashboard, useLayout } from "hooks";
import React, { useEffect, useState } from "react";
import { EIllunimusEmail, ISurveyTable, TParamsGetSurvey } from "types";
import { formatDMY, tableSurveyHeader } from "utils";

interface ITableResult {
	feature?: string;
	type?: string;
	rate?: string;
}

const TableSurvey: React.FC<ITableResult> = ({ type = "", feature = "" }) => {
	//////////// USESTATE //////////////
	const [paramsExport, setParamsExport] = useState({
		page: 1,
		limit: 100,
		feature,
		type,
	});
	const { onGetSurvey } = useDashboard();
	const [params, setParams] = useState<TParamsGetSurvey>({
		page: 1,
		limit: 10,
		type,
		feature,
	});
	const { dataUser, isLoading, refetch, totalPage } = onGetSurvey({
		...params,
	});
	const [filteredData, setFilteredData] = useState(dataUser);

	useEffect(() => {
		setParams({ ...params, type, feature });
		refetch();
		setFilteredData(dataUser);
	}, [type, feature, dataUser]);

	return (
		<React.Fragment>
			<div className="w-full flex justify-between mt-5">
				<h1 className="font-bold text-xl">{type}</h1>
				<div className="flex items-center justify-center gap-5">
					<div className="cursor-pointer">
						<ExcelExportSurvey type={type} feature={feature} />
					</div>
				</div>
			</div>

			<Table
				isLoading={isLoading}
				isAction={false}
				dataHeader={tableSurveyHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params?.page - 1 });
					refetch();
				}}
				onNextClick={() => {
					setParams({ ...params, page: params?.page + 1 });
					refetch();
				}}
				setFilteredData={setFilteredData}>
				{filteredData?.map((user: ISurveyTable, key: number) => (
					<tr key={key} className="bg-[#131313]">
						<TableRow>
							<h5
								className={`font-medium text-[#ffffff] ${
									user?.email?.includes(EIllunimusEmail.EMAIL_END)
										? "text-primary"
										: ""
								}`}>
								{user?.email}
							</h5>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">{user?.name}</h5>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">{user?.type}</h5>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">
								{user?.serviceName ? user?.serviceName : "----------"}
							</h5>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">{formatDMY(user?.createdAt)}</p>
						</TableRow>

						<TableRow>
							<tr>
								{user?.surver.map((el: any) => (
									<>
										{el && (
											<td className="px-2 flex flex-col">
												<h5 className="font-medium text-[#ffffff]">
													- {el?.question}{" "}
												</h5>
												<h5 className="font-medium text-[#74ec64] pl-1">
													- {el?.answer}
												</h5>
												<div className="h-[1px] my-1 bg-[#ab9ab4dd]"></div>
											</td>
										)}
									</>
								))}
							</tr>
						</TableRow>
					</tr>
				))}
			</Table>
		</React.Fragment>
	);
};

export default TableSurvey;
