import React, { useEffect, useState } from "react";
import { Icons } from "assets";
import { Button, Input } from "components";
import ClickOutside from "components/Header/ClickOutside";
import { useLayout } from "hooks";
import {
	BUTTON_TYPE,
	FORM_ACTION,
	modelBodySchema,
	modelBodyTypeModelOption,
} from "utils";
import { FormModelBody } from "types";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface IModalClothes {
	item: any;
	action: string;
	onClose: () => void;
	onConfirm: (data: any) => void;
	isLoading?: boolean;
}

const ModalModelBody = ({
	onClose,
	onConfirm,
	item,
	action,
	isLoading,
}: IModalClothes) => {
	const { openModalModelBody, setOpenModalModelBody } = useLayout();
	const [image, setImage] = useState("");
	const [formData, setFormData] = useState<FormModelBody>({
		modelBodyId: "",
		image: null,
		name: "",
		typeModel: "",
		type: "",
		uid: "",
		bodyShapeId: "",
		weight: 0,
		height: 0,
		bmi: 0,
		imageWaterMask: "",
	});

	const [errors, setErrors] = useState<any>({});

	useEffect(() => {
		if (action === FORM_ACTION.EDIT && item) {
			setImage(item.image);
			setFormData({
				modelBodyId: item.id,
				name: item.name,
				typeModel: item.typeModel,
				type: item.type,
				uid: item.uid,
				bodyShapeId: item.bodyShapeId,
				weight: item.weight,
				height: item.height,
				bmi: item.bmi,
				image: item.image,
				imageWaterMask: item.imageWaterMask,
			});
		} else {
			setFormData({
				modelBodyId: "",
				name: "",
				typeModel: "",
				type: "",
				uid: "",
				bodyShapeId: "",
				weight: 0,
				height: 0,
				bmi: 0,
				image: null,
				imageWaterMask: "",
			});
		}
	}, [item, openModalModelBody]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const f = event.target.files?.[0];
		if (f) {
			setFormData({ ...formData, image: f });
			const url = URL.createObjectURL(f);
			setImage(url);
			// setPreviewUrl(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	};

	const handleSubmit = async () => {
		try {
			await modelBodySchema.validate(formData, { abortEarly: false });
			onConfirm(formData);
		} catch (error: any) {
			const validationErrors: any = {};
			if (error?.inner) {
				error?.inner.forEach((err: any) => {
					validationErrors[err.path] = err.message;
				});
			}
			setErrors(validationErrors);
		}
	};

	return (
		<React.Fragment>
			{openModalModelBody && (
				<div className="fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/60 px-4 py-5">
					<ClickOutside
						onClick={() => {
							setOpenModalModelBody(false);
							setErrors({});
						}}>
						<div className="md:w-[600px] sm:w-full overflow-hidden text-sm bg-white px-12 py-3 rounded">
							<h3 className="text-black text-center mb-4 text-xl font-bold">
								{action === FORM_ACTION.CREATE
									? "Create Model body"
									: "Edit Model body"}
							</h3>
							<form>
								<Input
									disabled={isLoading}
									label="Name"
									type="text"
									formData={formData}
									placeholder="Enter name"
									name="name"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								<div className="mb-4">
									<label className="mb-2.5 block font-bold text-black">
										Image
									</label>
									<div className="w-full flex items-center gap-2">
										<input
											disabled={isLoading}
											type="file"
											accept="image/*"
											onChange={handleFileChange}
											id="credits"
											className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 pl-6 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter"
										/>
										{formData.image && (
											<LazyLoadImage src={image} className="h-[52px]" />
										)}
									</div>
								</div>
								<Input
									disabled={isLoading}
									label="Type model"
									type="select"
									name="typeModel"
									formData={formData}
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
									options={modelBodyTypeModelOption}
								/>
								<Input
									disabled={isLoading}
									label="Type"
									type="text"
									formData={formData}
									placeholder="Enter type"
									name="type"
									setFormData={setFormData}
									errors={errors}
									setErrors={setErrors}
								/>
								{action === FORM_ACTION.EDIT && (
									<>
										<Input
											disabled={isLoading}
											label="UID"
											type="text"
											formData={formData}
											placeholder="Enter UID"
											name="uid"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
										<Input
											disabled={isLoading}
											label="Body shape ID"
											type="text"
											formData={formData}
											placeholder="Enter body shape id"
											name="bodyShapeId"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
										<Input
											disabled={isLoading}
											label="Weight"
											type="number"
											formData={formData}
											placeholder="Enter weight"
											name="weight"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
										<Input
											disabled={isLoading}
											label="Height"
											type="number"
											formData={formData}
											placeholder="Enter height"
											name="height"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
										<Input
											disabled={isLoading}
											label="Bmi"
											type="number"
											formData={formData}
											placeholder="Enter bmi"
											name="bmi"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
										<Input
											disabled={isLoading}
											label="Image water mask"
											type="text"
											formData={formData}
											placeholder="Enter image water mask"
											name="imageWaterMask"
											setFormData={setFormData}
											errors={errors}
											setErrors={setErrors}
										/>
									</>
								)}

								<div className="mb-4 flex justify-end gap-1">
									<div>
										<Button
											variant={BUTTON_TYPE.OUTLINED}
											disabled={isLoading}
											onClick={() => {
												onClose();
												setErrors({});
											}}
											className="w-[140px]">
											Cancel
										</Button>
									</div>
									<div>
										<Button
											disabled={isLoading}
											onClick={handleSubmit}
											className="w-[140px] max-w-[140px] flex items-center gap-2">
											Confirm {isLoading && <span>{Icons.Spinner()}</span>}
										</Button>
									</div>
								</div>
							</form>
						</div>
					</ClickOutside>
				</div>
			)}
		</React.Fragment>
	);
};

export default ModalModelBody;
