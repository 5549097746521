import React, { FC } from "react";
import Duration from "pages/admin/user/component/Duration";
import { ETypeSearch } from "types";

interface ISearchUserProps {
	params: any;
	setParams: any;
}

const SearchUser: FC<ISearchUserProps> = ({ params, setParams }) => {
	return (
		<div className="flex flex-row px-2 mb-4 gap-2">
			<div className="w-fit h-[42px]">
				<p className="pl-[6px] text-[12px]">Date</p>
				<Duration
					params={params}
					value={params.duration}
					onChange={(v: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: v,
							[ETypeSearch.START_DATE]: "",
							[ETypeSearch.END_DATE]: "",
						})
					}
					onSave={(startDate: any, endDate: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: 0,
							[ETypeSearch.START_DATE]: startDate,
							[ETypeSearch.END_DATE]: endDate,
						})
					}
				/>
			</div>
		</div>
	);
};

export default SearchUser;
