// /** @format */

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { settingService } from "services";
import { FormSettings, TParamsSearch } from "types";
import { QueryKeys } from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

export const useSetting = () => {
	const queryClient = useQueryClient();
	//SETTING
	const onGetSetting = (params: TParamsSearch) => {
		const { data, isLoading, refetch } = useQuery({
			queryKey: [QueryKeys.SETTING, params.page, params.search],
			queryFn: () => settingService.getSetting(params),
		});
		return {
			settingData: data?.data?.data,
			isLoading,
			refetch,
			totalPage: data?.data.pagination.totalPage,
		};
	};
	const editSettingMutation = useMutation({
		mutationFn: (formData: FormSettings) =>
			settingService.editSetting(formData),
		onSuccess: () => {
			SnackbarUtils.success("Update successfully!");
			queryClient.invalidateQueries({ queryKey: [QueryKeys.SETTING] });
		},
		onError: () => {
			SnackbarUtils.warning("Update failed");
		},
	});
	return {
		onGetSetting,
		editSettingMutation,
	};
};
