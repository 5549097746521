import { Icons } from "assets";
import { saveAs } from "file-saver";
import React, { FC, memo } from "react";
import * as XLSX from "xlsx";

interface IExportExcelProps {
	fileName: string;
	totalPage?: number;
	getData?: any;
	params?: any;
	setParams?: any;
	isLoading: boolean;
}

const ExportChart: FC<IExportExcelProps> = ({
	fileName,
	getData,
	isLoading,
}) => {
	const exportToExcel = async () => {
		try {
			const exportData = await getData;
			const worksheet = XLSX.utils.json_to_sheet(exportData);
			const workbook = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
			const excelBuffer = XLSX.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			const blob = new Blob([excelBuffer], {
				type: "application/octet-stream",
			});
			saveAs(blob, `${fileName}.xlsx`);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="relative cursor-pointer">
			<button
				type="button"
				disabled={isLoading}
				className=" flex items-center justify-center rounded-[12px] bg-[#303030] px-[16px] py-[12px] text-center font-medium text-sm text-white hover:bg-opacity-90 lg:px-8 xl:px-5 disabled:bg-gray-400 "
				onClick={(e: any) => {
					e.preventDefault();
					exportToExcel();
				}}>
				<span className="px-2">{Icons.Download()}</span> Export
			</button>
		</div>
	);
};

export default memo(ExportChart);
