import { Editor } from "@tinymce/tinymce-react";
import React, { FC, memo } from "react";

interface IEditorTermsAndConditionsProps {
	editorRef?: any;
	formData?: any;
	setFormData: (data: any) => void;
	disabled?: boolean;
}

const EditorTermsAndConditions: FC<IEditorTermsAndConditionsProps> = ({
	editorRef,
	formData,
	setFormData,
	disabled,
}) => {
	return (
		<div className="w-full h-full mb-4 flex-1 ">
			<Editor
				disabled={disabled}
				ref={editorRef ?? null}
				onInit={(_evt: any, editor: any) => (editorRef.current = editor)}
				apiKey={process.env.REACT_APP_TINYMCE_KEY}
				init={{
					plugins: [
						"anchor",
						"autolink",
						"charmap",
						"codesample",
						"emoticons",
						"image",
						"link",
						"lists",
						"media",
						"searchreplace",
						"table",
						"visualblocks",
						"wordcount",
						"checklist",
						"casechange",
						"export",
						"powerpaste",
						"advtable",
						"tableofcontents",
						"autocorrect",
						"typography",
						"inlinecss",
						"importword",
						"exportword",
						"exportpdf",
						"insertdatetime",
					],
					toolbar:
						"undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
					content_style:
						"body { font-family:Helvetica,Arial,sans-serif; font-size:14px}",
					height: "100%",
					width: "100%",
				}}
				value={formData.value}
				onEditorChange={(newValue: any) =>
					setFormData({ ...formData, value: newValue })
				}
			/>
		</div>
	);
};

export default memo(EditorTermsAndConditions);
