import { Icons } from "assets";
import { Button } from "components";
import { useSetting } from "hooks/useSetting";
import React, { useEffect, useState } from "react";
import { ESettingKeys, ETypeSearch, FormSettings, TParamsSearch } from "types";
import { BUTTON_TYPE } from "utils";
import { Editor } from "./components";

const TermAndCondition = () => {
	const editorRef = React.useRef<any>(null);
	const { onGetSetting, editSettingMutation } = useSetting();
	const params: TParamsSearch = {
		page: 1,
		limit: 10,
		search: ESettingKeys.TERMS_AND_CONDITIONS,
		type: ETypeSearch.KEY,
	};
	const [formData, setFormData] = useState<FormSettings>({
		settingId: "",
		key: "",
		value: "",
	});

	const isLoadingEdit = editSettingMutation.isPending;

	const { settingData, isLoading } = onGetSetting({
		...params,
	});

	useEffect(() => {
		settingData &&
			setFormData({
				settingId: settingData[0].id,
				key: settingData[0].key,
				value: settingData[0].value,
			});
	}, [settingData]);

	const handleReset = () => {
		setFormData({
			settingId: settingData[0].id,
			key: settingData[0].key,
			value: settingData[0].value,
		});
	};

	const handleSubmit = () => {
		editSettingMutation.mutate(formData);
	};

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Term And Condition Setting</h1>
			{isLoading ? (
				<p>Loading...</p>
			) : (
				<>
					{settingData && settingData.length > 0 ? (
						<div className="w-full h-[90%]">
							<Editor
								disabled={isLoadingEdit}
								editorRef={editorRef}
								formData={formData}
								setFormData={setFormData}
							/>
							<div className="mb-4 flex justify-end gap-1">
								<div>
									<Button
										variant={BUTTON_TYPE.OUTLINED}
										disabled={isLoadingEdit}
										onClick={handleReset}
										className="lg:w-[180px] md:w-[100px]">
										Reset
									</Button>
								</div>
								<div>
									<Button
										disabled={isLoadingEdit}
										onClick={handleSubmit}
										className="lg:w-[180px] md:w-[100px] flex items-center gap-2">
										Confirm {isLoadingEdit && <span>{Icons.Spinner()}</span>}
									</Button>
								</div>
							</div>
						</div>
					) : (
						<p>No data found</p>
					)}
				</>
			)}
		</React.Fragment>
	);
};

export default TermAndCondition;
