import React, { FC } from "react";
import Duration from "pages/admin/user/component/Duration";
import { ETypeSearch } from "types";
import { Dropdown } from "components";
import { planOptions, statusPlanOption, typeOfUseOption } from "utils";

interface ISelectDurationProps {
	params: any;
	setParams: any;
	plan: any;
	setPlan: any;
	typePlan: any;
	setTypePlan: any;
	statusPlan: any;
	setStatusPlan: any;
}

const SelectDuration: FC<ISelectDurationProps> = ({
	params,
	setParams,
	plan,
	setPlan,
	typePlan,
	setTypePlan,
	statusPlan,
	setStatusPlan,
}) => {
	return (
		<div className="flex flex-row px-2 mb-4 gap-2">
			<div className="w-fit h-[42px]">
				<p className="pl-[6px] text-[12px]">Date</p>
				<Duration
					params={params}
					value={params.duration}
					onChange={(v: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: v,
							[ETypeSearch.START_DATE]: "",
							[ETypeSearch.END_DATE]: "",
						})
					}
					onSave={(startDate: any, endDate: any) =>
						setParams({
							...params,
							[ETypeSearch.DURATION]: 0,
							[ETypeSearch.START_DATE]: startDate,
							[ETypeSearch.END_DATE]: endDate,
						})
					}
				/>
			</div>
			<div className="w-fit h-[42px]">
				<div>
					<p className="pl-[6px] text-[12px]">Plan</p>
					<Dropdown
						options={planOptions}
						value={plan}
						onChange={(v: any) => {
							setPlan(v);
						}}
					/>
				</div>
			</div>
			<div className="w-fit h-[42px]">
				<div>
					<p className="pl-[6px] text-[12px]">Type</p>
					<Dropdown
						options={typeOfUseOption}
						value={typePlan}
						onChange={(v: any) => {
							setTypePlan(v);
						}}
					/>
				</div>
			</div>
			<div className="w-fit h-[42px]">
				<div>
					<p className="pl-[6px] text-[12px]">Status</p>
					<Dropdown
						options={statusPlanOption}
						value={statusPlan}
						onChange={(v: any) => {
							setStatusPlan(v);
						}}
					/>
				</div>
			</div>
			<div></div>
		</div>
	);
};

export default SelectDuration;
