/** @format */

import React, { useState } from "react";
import { Icons, logoLogin } from "assets";
import { Link } from "react-router-dom";
import { useAuthentication } from "hooks";
import SnackbarUtils from "utils/SnackbarUtils";

const Login = () => {
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [showPassword, setShowPassword] = useState<boolean>(true);
	const { loginMutation } = useAuthentication();

	const handleSubmit = (e: any) => {
		e.preventDefault();
		if (!email || !password) {
			SnackbarUtils.warning("Please fill in email and password");
			return;
		}

		loginMutation.mutate(
			{ email, password },
			{
				onSettled: () => {
					setEmail("");
					setPassword("");
				},
			},
		);
	};

	return (
		<section className="bg-gray-50 pt-[10%]">
			<div className="flex flex-col items-center px-6 py-8 mx-auto md:h-screen lg:py-0">
				<div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
					<Link
						to="#"
						className="flex items-center text-2xl font-semibold text-gray-900 dark:text-white mt-4">
						<img className=" h-[60px] m-auto " src={logoLogin} alt="logo" />
					</Link>
					<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
						<form className="space-y-4 md:space-y-6" action="#">
							<div>
								<label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
									Your email
								</label>
								<input
									type="email"
									name="email"
									id="email"
									value={email}
									onChange={(e: any) => setEmail(e.target.value)}
									autoComplete="username"
									className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 outline-none py-4 px-4 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
									placeholder="Enter your email"
									required
								/>
							</div>
							<div className="relative">
								<label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
									Password
								</label>
								<div className="relative">
									<input
										type={showPassword ? "password" : "text"}
										name="password"
										id="password"
										value={password}
										onChange={(e: any) => setPassword(e.target.value)}
										placeholder="Password"
										autoComplete="current-password"
										className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 outline-none py-4 px-4 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
										required
									/>
									<button
										onClick={(e: any) => {
											e.preventDefault();
											setShowPassword(!showPassword);
										}}
										type="button"
										className="absolute right-3 top-[50%] translate-y-[-50%]">
										{showPassword ? Icons.Eye() : Icons.EyeHide()}
									</button>
								</div>
							</div>
							<button
								type="submit"
								className="inline-flex items-center justify-center gap-2.5 bg-primary py-3 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10 rounded-2xl mb-4 w-full"
								onClick={handleSubmit}>
								Sign in
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Login;
