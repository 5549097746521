import { Icons, avtDf } from "assets";
import { Button, Table, TableRow } from "components";
import ExcelExportPlan from "components/ExcelExport/ExcelExportPlan";
import ExcelExportSurvey from "components/ExcelExport/ExcelExportSurvey";
import { ModalDelete } from "components/Modal";
import ModalAddBlog from "components/Modal/ModalAddBlog";
import ModalUpdateBlog from "components/Modal/ModalUpdateBlog";
import { useDashboard, useLayout } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	EIllunimusEmail,
	FormAddBlog,
	FormUpdateBlog,
	FormUser,
	IBlogsTable,
	ISurveyTable,
	TParamsBlogsGet,
	TParamsGetSurvey,
} from "types";
import {
	FORM_ACTION,
	formatDMY,
	tableBlogsHeader,
	tableSurveyHeader,
} from "utils";

interface ITableResult {
	feature?: string;
	type?: string;
	rate?: string;
}

const TableBlogs: React.FC<ITableResult> = ({ type = "", feature = "" }) => {
	//////////// USESTATE //////////////
	const { setOpenModalBlog, setOpenModalUpdateBlog, setOpenModalDelete } =
		useLayout();
	const [item, setItem] = useState<any>();
	const [action, setAction] = useState<string>("");
	const { onGetBlogs, createBlog, updateBlog, deleteBlog } = useDashboard();
	const [params, setParams] = useState<TParamsBlogsGet>({
		page: 1,
		limit: 10,
	});
	const { dataUser, isLoading, refetch, totalPage } = onGetBlogs({
		...params,
	});
	const [filteredData, setFilteredData] = useState(dataUser);

	useEffect(() => {
		setParams({ ...params });
		refetch();
		setFilteredData(dataUser);
	}, [type, feature, dataUser]);

	const onCreateBlog = useCallback((data: FormAddBlog) => {
		//call API update user here
		createBlog.mutate(data, {
			onSettled: () => setOpenModalBlog(false),
		});
		setTimeout(() => {
			refetch();
		}, 1000);
	}, []);

	const onUpdateBlog = useCallback((data: FormUpdateBlog) => {
		//call API update user here
		updateBlog.mutate(data, {
			onSettled: () => setOpenModalUpdateBlog(false),
		});
		setTimeout(() => {
			refetch();
		}, 1000);
	}, []);

	const onDeleteBlog = useCallback(
		(id: string) => {
			deleteBlog.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
			setTimeout(() => {
				refetch();
			}, 1000);
		},
		[item],
	);

	return (
		<React.Fragment>
			<div className="w-full flex justify-between mt-5">
				<h1 className="font-bold text-xl">{type}</h1>
				<div className="flex items-center justify-center gap-5">
					<Button
						className="inline-flex items-center justify-center gap-2"
						onClick={() => setOpenModalBlog(true)}>
						<span>{Icons.PlusWhite()}</span>
						Add Blog
					</Button>
				</div>
			</div>

			<Table
				isLoading={isLoading}
				isAction={false}
				dataHeader={tableBlogsHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params?.page - 1 });
					refetch();
				}}
				onNextClick={() => {
					setParams({ ...params, page: params?.page + 1 });
					refetch();
				}}
				setFilteredData={setFilteredData}>
				{filteredData?.map((blog: IBlogsTable, key: number) => (
					<tr key={key} className="bg-[#131313]">
						<TableRow>
							<div className="flex items-center space-x-3.5">
								<button
									title="Edit Blog"
									onClick={() => {
										setOpenModalUpdateBlog(true);
										setItem(blog);
										setAction(FORM_ACTION.EDIT);
									}}
									className="hover:text-primary">
									{Icons.Edit()}
								</button>
								<button
									title="Delete Blog"
									onClick={() => {
										setOpenModalDelete(true);
										setItem(blog);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</TableRow>

						<TableRow>
							<h5 className="font-medium text-[#ffffff]">{blog?.title}</h5>
						</TableRow>

						<TableRow>
							<LazyLoadImage
								src={blog.thumbnail ? blog.thumbnail : avtDf}
								className="w-[40px] h-[40px]"
							/>
						</TableRow>

						<TableRow>
							<p className="text-[#ffffff]">{formatDMY(blog?.createdAt)}</p>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalAddBlog
				onClose={() => setOpenModalBlog(false)}
				onConfirm={onCreateBlog}
				isLoading={createBlog.isPending}
			/>
			<ModalUpdateBlog
				item={item}
				action={action}
				onClose={() => setOpenModalUpdateBlog(false)}
				onConfirm={onUpdateBlog}
				isLoading={updateBlog.isPending}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteBlog}
			/>
		</React.Fragment>
	);
};

export default TableBlogs;
