import React, { useCallback, useEffect, useState } from "react";
import { useLayout, useNotification } from "hooks";
import { Icons } from "assets";
import {
	ETypeSearch,
	FormCreateNoti,
	FormEditNoti,
	INotification,
	TParamsSearch,
} from "types";
import {
	FORM_ACTION,
	formatDMY,
	searchUserOption,
	tableNotificationHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";
import { Search } from "components/Search";
import { Button } from "components/Button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Table, TableRow } from "components/Table";
import { ModalDelete, ModalNotification } from "components/Modal";

const Notification = () => {
	const {
		onGetNotification,
		createNotiMutation,
		editNotiMutation,
		deleteNotiMutation,
	} = useNotification();
	const { setOpenModalNoti, setOpenModalDelete } = useLayout();
	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const { notificationData, isLoading, refetch, totalPage } = onGetNotification(
		{
			...params,
		},
	);
	const [filteredData, setFilteredData] = useState<any[]>(notificationData);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(notificationData);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(notificationData);
	}, [notificationData]);

	const onCreateNoti = useCallback(
		(formData: FormCreateNoti) => {
			createNotiMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalNoti(false);
				},
			});
		},
		[item],
	);

	const onEditNoti = useCallback(
		(formData: FormEditNoti) => {
			editNotiMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalNoti(false);
				},
			});
		},
		[item],
	);

	const onDeleteNoti = useCallback(
		(id: string) => {
			deleteNotiMutation.mutate(id, {
				onSuccess: () => {
					setOpenModalDelete(false);
				},
			});
		},
		[item],
	);

	return (
		<React.Fragment>
			{/* Temporary comment not active function*/}
			<h1 className="font-bold text-xl mb-4 ">Notifications</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 items-center">
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Name, Email"
				/>
				<select
					value={searchOption}
					onChange={(e: any) => setSearchOption(e.target.value)}
					className="h-[42px] rounded-lg border border-stroke bg-transparent py-2 pl-2 pr-2 text-black outline-none focus:border-primary focus-visible:shadow-none">
					{searchUserOption.map((type) => (
						<option key={type.value} value={type.value}>
							{type.name}
						</option>
					))}
				</select>
				<Button onClick={handleSearch}>Search</Button>
			</div>
			{/* Temporary comment not active function*/}
			{/* <div className="flex justify-end">
				<button
					className="inline-flex items-center gap-2.5 rounded bg-primary px-4 py-[7px] font-medium text-white hover:bg-opacity-90"
					onClick={onExportToCSV}>
					Export as Excel
				</button>
			</div> */}
			<Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => {
					setAction(FORM_ACTION.CREATE);
					setOpenModalNoti(true);
				}}>
				<span>{Icons.PlusWhite()}</span>
				Add new notification
			</Button>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tableNotificationHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData?.map((data: INotification, key: number) => (
					<tr
						key={key}
						className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
						<td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
							<div className="flex items-center space-x-3.5">
								<button
									onClick={() => {
										setItem(data);
										setOpenModalNoti(true);
										setAction(FORM_ACTION.EDIT);
									}}
									className="hover:text-primary">
									{Icons.Edit()}
								</button>
								<button
									onClick={() => {
										setOpenModalDelete(true);
										setItem(data);
									}}
									className="hover:text-primary">
									{Icons.Delete()}
								</button>
							</div>
						</td>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.id}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.title}
							</h5>
						</TableRow>
						<TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.content}
							</h5>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{data.type}</p>
						</TableRow>
						<TableRow>
							<LazyLoadImage src={data.image} />
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">{data.hoursToAdd}</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{data.isMaintain ? "True" : "False"}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.createdAt)}
							</p>
						</TableRow>
						<TableRow>
							<p className="text-black dark:text-white">
								{formatDMY(data.updatedAt)}
							</p>
						</TableRow>
					</tr>
				))}
			</Table>
			<ModalNotification
				item={item}
				action={action}
				onConfirm={(formData: any) => {
					if (action === FORM_ACTION.EDIT) {
						onEditNoti(formData);
					} else {
						onCreateNoti(formData);
					}
				}}
				onClose={() => setOpenModalNoti(false)}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteNoti}
			/>
		</React.Fragment>
	);
};

export default Notification;
