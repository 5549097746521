import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { Icons, logo } from "assets";
import { ROUTES } from "utils";
import { mainColor } from "theme";
import { APP_MERCHANT } from "utils/routes";
interface SidebarProps {
	sidebarOpen: boolean;
	setSidebarOpen: (arg: boolean) => void;
}

const SidebarMerchant = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
	const location = useLocation();
	const { pathname } = location;

	const trigger = useRef<any>(null);
	const sidebar = useRef<any>(null);

	const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
	const [sidebarExpanded, setSidebarExpanded] = useState(
		storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
	);

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }: MouseEvent) => {
			if (!sidebar.current || !trigger.current) return;
			if (
				!sidebarOpen ||
				sidebar.current.contains(target) ||
				trigger.current.contains(target)
			)
				return;
			setSidebarOpen(false);
		};
		document.addEventListener("click", clickHandler);
		return () => document.removeEventListener("click", clickHandler);
	});

	// close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }: KeyboardEvent) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener("keydown", keyHandler);
		return () => document.removeEventListener("keydown", keyHandler);
	});

	useEffect(() => {
		localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
		if (sidebarExpanded) {
			document.querySelector("body")?.classList.add("sidebar-expanded");
		} else {
			document.querySelector("body")?.classList.remove("sidebar-expanded");
		}
	}, [sidebarExpanded]);

	return (
		<aside
			ref={sidebar}
			className={`absolute left-0 top-0 z-99 flex h-screen w-72.5 flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
				sidebarOpen ? "translate-x-0" : "-translate-x-full"
			}`}>
			{/* <!-- SIDEBAR_MERCHANT HEADER --> */}
			<div className="flex items-center justify-between gap-2 px-6 mt-5">
				<NavLink to="/">
					<img src={logo} alt="Logo" className="h-[60px]" />
				</NavLink>

				<button
					ref={trigger}
					onClick={() => setSidebarOpen(!sidebarOpen)}
					aria-controls="sidebar"
					aria-expanded={sidebarOpen}
					className="block lg:hidden">
					{Icons.Menu()}
				</button>
			</div>
			{/* <!-- SIDEBAR_MERCHANT HEADER --> */}

			<div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
				{/* <!-- SidebarMerchant Menu --> */}
				<nav className="mt-5 py-3 px-4 lg:mt-9 lg:px-6">
					{/* <!-- Categories --> */}
					<div>
						<ul className="mb-6 flex flex-col gap-1.5">
							{/* <li>
								<NavLink
									to={APP_MERCHANT.ROOT}
									className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname === "/" && "bg-graydark dark:bg-meta-4"
									}`}>
									{Icons.List()}
									Categories
								</NavLink>
							</li> */}
							<li>
								<NavLink
									to={APP_MERCHANT.ROOT}
									className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
										pathname === "/" && "bg-graydark dark:bg-meta-4"
									}`}>
									{Icons.Pricing(mainColor.primary)}
									Clothes
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
				{/* <!-- SidebarMerchant Menu --> */}
			</div>
		</aside>
	);
};

export default SidebarMerchant;
