/** @format */

import { IKeyAuth, IThemeColor } from "types";
import { KEY_CONTEXT } from "utils";

export function useKey() {
	const setKey = (key: string, value: string) => {
		localStorage.setItem(key, JSON.stringify(value));
	};

	const setKeyLang = (value: string) => {
		setKey(KEY_CONTEXT.LANGUAGE, value);
	};

	const setKeySC = (value: string) => {
		setKey(KEY_CONTEXT.SC_CONNECTION_TOKEN, value);
	};

	const setKeyTheme = (value: string) => {
		setKey(KEY_CONTEXT.THEME_MODE, value);
	};
	const setKeyUser = (user: any) => {
		setKey(KEY_CONTEXT.USER, JSON.stringify(user));
	};

	const setKeySite = ({ authToken, refreshToken }: IKeyAuth) => {
		setKey(KEY_CONTEXT.AUTH_TOKEN, authToken);
		setKey(KEY_CONTEXT.REFRESH_TOKEN, refreshToken);
	};

	const removeKeySite = () => {
		localStorage.removeItem(KEY_CONTEXT.AUTH_TOKEN);
		localStorage.removeItem(KEY_CONTEXT.REFRESH_TOKEN);
		setKey(KEY_CONTEXT.USER_ID, "");
	};

	// custom get value
	const getKey = (key: string) => {
		try {
			const val = localStorage.getItem(key);
			if (val) {
				return JSON.parse(val);
			}
			return null;
		} catch (error) {
			return null;
		}
	};

	// custom remove value
	const removeKey = (key: string) => {
		return localStorage.removeItem(key);
	};

	const removeAllKeys = (notKey?: string) => {
		for (let i = localStorage.length - 1; i >= 0; i--) {
			const key = localStorage.key(i);
			if (key && key !== notKey) {
				removeKey(key);
			}
		}
	};

	const themeMode = (getKey(KEY_CONTEXT.THEME_MODE) || "main") as IThemeColor;

	return {
		themeMode,
		setKeyLang,
		setKeyTheme,
		setKeyUser,
		setKeySite,
		getKey,
		removeKey,
		removeKeySite,
		setKeySC,
		setKey,
		removeAllKeys,
	};
}
