import { Icons } from "assets";
import { Button, Dropdown, Search, Table, TableRow } from "components";
import { ExcelExport } from "components/ExcelExport";
import { ModalHairStyle, ModalDelete } from "components/Modal";
import { useLayout, useGeneration } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	ETypeSearch,
	FormHairStyle,
	IHairStyle,
	TParams,
	TParamsSearch,
} from "types";
import {
	FORM_ACTION,
	formatDMY,
	searchHairStyleOptions,
	tableHairStyleHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

const HairStyle = () => {
	const {
		onGetHairStyle,
		createHairStyleMutation,
		editHairStyleMutation,
		deleteHairStyleMutation,
		onExportHairStyle,
	} = useGeneration();
	const { setOpenModalHairStyle, setOpenModalDelete } = useLayout();

	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const { hairStyleData, isLoading, refetch, totalPage } = onGetHairStyle({
		...params,
	});
	//Export Contact Us
	const [paramsExport, setParamsExport] = useState<TParams>({
		page: 1,
		limit: 10,
	});
	const { isLoading: isLoadingExport, refetch: refetchExport } =
		onExportHairStyle({
			...paramsExport,
		});

	const [filteredData, setFilteredData] = useState<any[]>(hairStyleData);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(hairStyleData);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(hairStyleData);
	}, [hairStyleData]);

	// const onExportToCSV = () => {
	// 	return;
	// };
	const onCreateHairStyle = useCallback(
		(formData: FormHairStyle) => {
			createHairStyleMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalHairStyle(false);
				},
			});
		},
		[item],
	);

	const onEditHairStyle = useCallback(
		(formData: FormHairStyle) => {
			editHairStyleMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalHairStyle(false);
				},
			});
		},
		[item],
	);

	const onDeleteHairStyle = useCallback(
		(id: string) => {
			deleteHairStyleMutation.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	return (
		<React.Fragment>
			<h1 className="font-bold text-xl mb-4 ">Hair style management</h1>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 items-center">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchHairStyleOptions}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				<Search
					value={searchValue}
					onChange={(e: any) => setSearchValue(e.target.value)}
					placeholder="Search by ID, Name"
				/>
				<Button onClick={handleSearch}>Search</Button>
			</div>
			{/* Temporary comment not active function*/}
			{/* <div className="flex justify-end">
				<button
					className="inline-flex items-center gap-2.5 rounded bg-primary px-4 py-[7px] font-medium text-white hover:bg-opacity-90"
					onClick={onExportToCSV}>
					Export as Excel
				</button>
			</div> */}
			<Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => {
					setAction(FORM_ACTION.CREATE);
					setOpenModalHairStyle(true);
				}}>
				<span>{Icons.PlusWhite()}</span>
				Add new generation
			</Button>
			<div className="flex justify-end">
				<div className="cursor-pointer">
					<span>{Icons.Download()}</span>
					<ExcelExport
						fileName="hair-style"
						getData={() => refetchExport()}
						params={paramsExport}
						setParams={setParamsExport}
						isLoading={isLoadingExport}
						totalPage={totalPage}
					/>
				</div>
			</div>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tableHairStyleHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData &&
					filteredData?.map((data: IHairStyle, key: number) => (
						<tr
							key={key}
							className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
							<TableRow>
								<div className="flex items-center space-x-3.5">
									<button
										onClick={() => {
											setAction(FORM_ACTION.EDIT);
											setItem(data);
											setOpenModalHairStyle(true);
										}}
										className="hover:text-primary">
										{Icons.Edit()}
									</button>
									<button
										onClick={() => {
											setOpenModalDelete(true);
											setItem(data);
										}}
										className="hover:text-primary">
										{Icons.Delete()}
									</button>
								</div>
							</TableRow>
							<TableRow>
								<h5
									title={data.id}
									className="w-[100px] truncate font-medium text-black">
									{data.id}
								</h5>
							</TableRow>
							<TableRow>
								<h5
									title={data.name}
									className="w-[200px] truncate font-medium text-black">
									{data.name}
								</h5>
							</TableRow>
							<TableRow>
								<LazyLoadImage src={data.image} className="h-[52px]" />
							</TableRow>
							<TableRow>
								<p className="text-black">{data.uid}</p>
							</TableRow>
							<TableRow>
								<LazyLoadImage src={data.imageWaterMask} className="h-[52px]" />
							</TableRow>
							<TableRow>
								<p className="text-black">{formatDMY(data.createdAt)}</p>
							</TableRow>
							<TableRow>
								<p className="text-black">{formatDMY(data.updatedAt)}</p>
							</TableRow>
						</tr>
					))}
			</Table>
			<ModalHairStyle
				item={item}
				action={action}
				onConfirm={(formData: any) => {
					if (action === FORM_ACTION.EDIT) {
						onEditHairStyle(formData);
					} else {
						onCreateHairStyle(formData);
					}
				}}
				onClose={() => setOpenModalHairStyle(false)}
				isLoading={
					createHairStyleMutation.isPending || editHairStyleMutation.isPending
				}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteHairStyle}
				isLoading={deleteHairStyleMutation.isPending}
			/>
		</React.Fragment>
	);
};

export default HairStyle;
