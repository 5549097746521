import React, { useCallback, useEffect, useState } from "react";
import { Icons } from "assets";
import { Button, Dropdown, Search, Table, TableRow } from "components";
import { ModalClothes, ModalDelete } from "components/Modal";
import { useLayout } from "hooks";
import { useMerchant } from "hooks/useMerchant";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ETypeSearch, FormClothes, IClothes, TParamsSearch } from "types";
import {
	clothesTypeModelOption,
	clothesTypeOutfitOption,
	FORM_ACTION,
	formatDMY,
	searchClotheOptions,
	tableClothesHeader,
} from "utils";
import SnackbarUtils from "utils/SnackbarUtils";

const Clothes = () => {
	const {
		onGetClothes,
		createClothesMutation,
		editClothesMutation,
		deleteClothesMutation,
	} = useMerchant();
	const { setOpenModalClothes, setOpenModalDelete } = useLayout();

	const [params, setParams] = useState<TParamsSearch>({
		page: 1,
		limit: 10,
		search: undefined,
		type: undefined,
	});

	const [action, setAction] = useState<string>("");
	const [item, setItem] = useState<any>();
	const [searchValue, setSearchValue] = useState("");
	const [searchOption, setSearchOption] = useState(ETypeSearch.ID);

	const { clothesData, isLoading, refetch, totalPage } = onGetClothes({
		...params,
	});
	const [filteredData, setFilteredData] = useState<any[]>(clothesData);

	const handleSearch = useCallback(() => {
		if (searchOption === ETypeSearch.ID) {
			if (searchValue.length !== 24) {
				SnackbarUtils.warning("Id must have 24 characters");
				return;
			}
		}
		if (searchValue === "") return;
		setParams({
			...params,
			search: searchValue,
			type: searchOption,
		});
		refetch();
		setFilteredData(clothesData);
	}, [searchValue, searchOption, params]);

	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);
	useEffect(() => {
		if (searchValue === "") {
			setParams({
				...params,
				search: undefined,
				type: undefined,
			});
			refetch();
		}
	}, [searchValue, refetch]);

	useEffect(() => {
		setFilteredData(clothesData);
	}, [clothesData]);

	// const onExportToCSV = () => {
	// 	return;
	// };
	const onCreateClothes = useCallback(
		(formData: FormClothes) => {
			createClothesMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalClothes(false);
				},
			});
		},
		[item],
	);

	const onEditClothes = useCallback(
		(formData: FormClothes) => {
			editClothesMutation.mutate(formData, {
				onSuccess: () => {
					setOpenModalClothes(false);
				},
			});
		},
		[item],
	);

	const onDeleteClothes = useCallback(
		(id: string) => {
			deleteClothesMutation.mutate(id, {
				onSuccess: () => setOpenModalDelete(false),
			});
		},
		[item],
	);

	return (
		<React.Fragment>
			<div className="flex flex-row justify-end px-4 mb-4 gap-2 items-center">
				<div className="w-fit h-[42px]">
					<Dropdown
						options={searchClotheOptions}
						value={searchOption}
						onChange={(v: any) => {
							setSearchOption(v);
							setSearchValue("");
						}}
					/>
				</div>
				{searchOption === ETypeSearch.ID ||
				searchOption === ETypeSearch.NAME ? (
					<Search
						value={searchValue}
						onChange={(e: any) => setSearchValue(e.target.value)}
						placeholder="Search by ID, Name"
					/>
				) : null}
				{searchOption === ETypeSearch.TYPE_MODEL && (
					<Dropdown
						options={clothesTypeModelOption}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				)}
				{searchOption === ETypeSearch.TYPE_OUTFIT && (
					<Dropdown
						options={clothesTypeOutfitOption}
						value={searchValue}
						onChange={(v: any) => setSearchValue(v)}
					/>
				)}
				<Button onClick={handleSearch}>Search</Button>
			</div>
			{/* Temporary comment not active function*/}
			{/* <div className="flex justify-end">
				<button
					className="inline-flex items-center gap-2.5 rounded bg-primary px-4 py-[7px] font-medium text-white hover:bg-opacity-90"
					onClick={onExportToCSV}>
					Export as Excel
				</button>
			</div> */}
			<Button
				className="inline-flex items-center justify-center gap-2 mb-4"
				onClick={() => {
					setAction(FORM_ACTION.CREATE);
					setOpenModalClothes(true);
				}}>
				<span>{Icons.PlusWhite()}</span>
				Add new clothes
			</Button>
			<Table
				isLoading={isLoading}
				isAction={true}
				dataHeader={tableClothesHeader}
				params={params}
				totalPage={totalPage}
				filteredData={filteredData}
				onPreviousClick={() => {
					setParams({ ...params, page: params.page - 1 });
					refetch();
				}}
				onNextClick={() => setParams({ ...params, page: params.page + 1 })}
				setFilteredData={setFilteredData}>
				{filteredData &&
					filteredData?.map((data: IClothes, key: number) => (
						<tr
							key={key}
							className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
							<TableRow>
								<div className="flex items-center space-x-3.5">
									<button
										onClick={() => {
											setAction(FORM_ACTION.EDIT);
											setItem(data);
											setOpenModalClothes(true);
										}}
										className="hover:text-primary">
										{Icons.Edit()}
									</button>
									<button
										onClick={() => {
											setOpenModalDelete(true);
											setItem(data);
										}}
										className="hover:text-primary">
										{Icons.Delete()}
									</button>
								</div>
							</TableRow>
							<TableRow>
								<h5
									title={data.id}
									className="w-[100px] truncate font-medium text-black dark:text-white">
									{data.id}
								</h5>
							</TableRow>
							<TableRow>
								<h5
									title={data.name}
									className="w-[300px] truncate font-medium text-black dark:text-white">
									{data.name}
								</h5>
							</TableRow>
							<TableRow>
								<h5 className="font-medium text-black dark:text-white">
									{data.typeModel}
								</h5>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">{data.typeOutfit}</p>
							</TableRow>
							<TableRow>
								<LazyLoadImage src={data.image} className="h-[52px]" />
							</TableRow>
							{/* <TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.size}
							</h5>
						</TableRow> */}
							<TableRow>
								<h5
									title={data.link}
									className="w-[200px] font-medium text-black dark:text-white truncate">
									{data.link}
								</h5>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.createdAt)}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.updatedAt)}
								</p>
							</TableRow>
						</tr>
					))}
				{filteredData &&
					filteredData?.map((data: IClothes, key: number) => (
						<tr
							key={key}
							className={`${key % 2 == 0 ? "bg-white" : "bg-[#222E3A]/[6%]"}`}>
							<TableRow>
								<div className="flex items-center space-x-3.5">
									<button
										onClick={() => {
											setAction(FORM_ACTION.EDIT);
											setItem(data);
											setOpenModalClothes(true);
										}}
										className="hover:text-primary">
										{Icons.Edit()}
									</button>
									<button
										onClick={() => {
											setOpenModalDelete(true);
											setItem(data);
										}}
										className="hover:text-primary">
										{Icons.Delete()}
									</button>
								</div>
							</TableRow>
							<TableRow>
								<h5
									title={data.id}
									className="w-[100px] truncate font-medium text-black dark:text-white">
									{data.id}
								</h5>
							</TableRow>
							<TableRow>
								<h5
									title={data.name}
									className="w-[300px] truncate font-medium text-black dark:text-white">
									{data.name}
								</h5>
							</TableRow>
							<TableRow>
								<h5 className="font-medium text-black dark:text-white">
									{data.typeModel}
								</h5>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">{data.typeOutfit}</p>
							</TableRow>
							<TableRow>
								<LazyLoadImage src={data.image} className="h-[52px]" />
							</TableRow>
							{/* <TableRow>
							<h5 className="font-medium text-black dark:text-white">
								{data.size}
							</h5>
						</TableRow> */}
							<TableRow>
								<h5
									title={data.link}
									className="w-[200px] font-medium text-black dark:text-white truncate">
									{data.link}
								</h5>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.createdAt)}
								</p>
							</TableRow>
							<TableRow>
								<p className="text-black dark:text-white">
									{formatDMY(data.updatedAt)}
								</p>
							</TableRow>
						</tr>
					))}
			</Table>
			<ModalClothes
				item={item}
				action={action}
				onConfirm={(formData: any) => {
					if (action === FORM_ACTION.EDIT) {
						onEditClothes(formData);
						onEditClothes(formData);
					} else {
						onCreateClothes(formData);
						onCreateClothes(formData);
					}
				}}
				onClose={() => setOpenModalClothes(false)}
				isLoading={
					createClothesMutation.isPending || editClothesMutation.isPending
				}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteClothes}
				isLoading={deleteClothesMutation.isPending}
			/>
			<ModalDelete
				item={item}
				onClose={() => setOpenModalDelete(false)}
				onConfirm={onDeleteClothes}
				isLoading={deleteClothesMutation.isPending}
			/>
		</React.Fragment>
	);
};

export default Clothes;
