/** @format */

import { END_POINT } from "utils";
import axiosClient from "./axios";
import { TParamsSearch } from "types";

const getRequest = (url: string, params?: object) =>
	axiosClient.get(url, { params });

export const transactionService = {
	//PLAN
	getPlan: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.TRANSACTION.GET_TRANSACTION_PLAN, {
			...params,
		});
	},
	//CREDIT
	getCredit: async (params: TParamsSearch) => {
		return await getRequest(END_POINT.TRANSACTION.GET_TRANSACTION_CREDIT, {
			...params,
		});
	},
};
